import { Link } from "react-router-dom";
import "../css/menu.css";
import ReactDOM from "react-dom";
import { useParams, useHistory } from "react-router-dom";
import React, { createContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import firebase, { auth, firestore } from "../utils/firebase";
import Footer from "../components/footer";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../css/meal.css";
import { Spinner } from "react-bootstrap";
import { useCartWithLocalStorage } from "./paketCart";
import defaultPhoto from "../img/default.png";
import instaBadge from "../img/insta-badge.png";

const PaketMenu = () => {
  const { state } = useLocation();

  const [meals, setMeals] = useState();
  const [category, setCategory] = useState();
  const [menu, setMenu] = useState(null);
  const { cafe, table } = useParams();
  const [loading, done] = useState(false);
  const [info, setInfo] = useState();
  const [limit, setLimit] = useState({});
  const [filter, setFilter] = useState("");

  const lang = localStorage.getItem("alka-menu-lang") || "tr";
  const [cart, setCart] = useCartWithLocalStorage(cafe);

  useEffect(() => {
    firestore
      .collection(`Restaurants/${cafe}/info`)
      .doc("info")
      .get()
      .then((doc) => {
        setInfo(doc.data());
        let info = doc.data();
      });
  }, []);

  useEffect(() => {
    if (state?.loc == null) return;
    setTimeout(() => {
      window.scrollTo(0, state.loc + 500);
    }, 1000);
  }, [menu, state]);

  useEffect(() => {
    if (info?.logo && menu) {
      done(true);
    }
  }, [menu]);
  const history = useHistory();

  useEffect(() => {
    firestore
      .collection("Restaurants")
      .doc(cafe.toString())
      .get()
      .then((doc) => {
        if (doc.exists) {
          let menu = doc.data().Menu;
          menu.sort((a, b) => {
            if (parseFloat(a.sortOrder) < parseFloat(b.sortOrder)) return -1;
            else return 1;
          });
          setMenu(menu.filter((m) => m.active == 1));
          let items = menu[0].items;
          items.sort((a, b) => {
            if (parseFloat(a.sortOrder) < parseFloat(b.sortOrder)) return -1;
            else return 1;
          });

          if (state?.category != null) {
            setCategory(state.category);
            setMeals(menu.filter((x) => x.category == state.category)[0].items);
          } else {
            setMeals(items.filter((i) => i.active == 1));
            setCategory(
              lang == "tr"
                ? menu[0].category
                : lang == "en"
                ? menu[0].categoryEn
                : menu[0].categoryAr
            );
          }
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      });
  }, [state]);

  useEffect(() => {
    if (menu == null) {
      return;
    }
    var list = menu.map((m) => m.items).flat();
    setMeals(
      list.filter((x) => x.name.toLowerCase().includes(filter.toLowerCase()))
    );
  }, [filter]);

  const [autoplay, setAutoPlay] = useState(true);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    rows: 2,
  };
  const goToMeal = (meal) => {
    history.push({
      pathname: "/meal",
      state: {
        cafe,
        meal,
        info,
        paket: true,
        location: window.scrollY,
        category,
      },
    });
  };
  const goBack = () => {
    history.push(
      `/paketsiparis/${
        info.mainRestaurant != null ? info.mainRestaurant : cafe
      }`
    );
  };
  const MainContext = createContext();
  return loading ? (
    <div
      style={{
        overflowX: "hidden",
        backgroundColor: info?.backgroundColor,
        color: info?.textColor,
        minHeight: "100vh",
        marginTop: 20,
      }}
      className="mate"
    >
      <div
        className="back-icon"
        onClick={goBack}
        style={{
          position: "absolute",
          left: 20,
          fontSize: 30,
          padding: 2,
          borderRadius: "20%",
          boxShadow: "rgb(0 0 0 / 15%) 2px 2px 10px",
        }}
      >
        <i className="bi bi-chevron-left"></i>
      </div>

      <Link
        to={{
          pathname: `/paket-cart`,
          state: {
            cafe,
            info,
            menu,
            limit,
          },
        }}
        className="btn"
        style={{
          position: "fixed",
          right: 19,
          bottom: 10,
          zIndex: 10000,
          backgroundColor: "white",
          boxShadow: "rgb(0 0 0 / 20%) 2px 2px 10px",
          borderRadius: "100%",
        }}
      >
        <span
          class="count"
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            zIndex: 2,
            fontSize: "11px",
            borderRadius: "50%",
            background: "rgb(230,3,75)",
            width: "20px",
            height: "20px",
            lineHeight: "16px",
            display: "block",
            textAlign: "center",
            color: "white",
            fontFamily: "Roboto, sans-serif",
            fontWeight: "bold",
          }}
        >
          {cart.length}
        </span>

        <i
          className="bi bi-bag-fill nav__icon"
          style={{
            position: "relative",
            top: "4px",
            zIndex: 1,
            fontSize: "24px",
            color: "black",
          }}
        ></i>
      </Link>
      <div className="logo">
        <img src={info?.logo} alt="logo" />
      </div>
      <p
        className="text-center fs-2 fw-bold animate__animated animate__fadeInDown"
        style={{ color: "rgb(230, 3, 75)" }}
      >
        {info?.name}
      </p>

      <div
        style={{
          height: "auto",
          width: "90vw",
          margin: "0 auto",
        }}
        className="slider animate__animated animate__slideInRight"
      >
        <Slider {...settings}>
          {menu?.map(function (slide) {
            return (
              <div
                onClick={() => {
                  setCategory(
                    lang == "tr"
                      ? slide.category
                      : lang == "en"
                      ? slide.categoryEn
                      : slide.categoryAr
                  );
                  setMeals(slide.items.filter((i) => i.active == 1));
                }}
                onMouseDown={() => {
                  setAutoPlay(false);
                }}
                className="category"
              >
                <img
                  src={slide.photo.trim() == "" ? defaultPhoto : slide.photo}
                  style={{
                    width: "95%",
                    height: slide.photo.trim() == "" ? "auto" : 130,
                    borderRadius: 10,
                  }}
                />
                <h3
                  style={{
                    color: slide.category == category ? "rgb(230,3,75)" : "",
                  }}
                >
                  {lang == "tr"
                    ? slide.category
                    : lang == "en"
                    ? slide.categoryEn
                    : slide.categoryAr}
                </h3>
              </div>
            );
          })}
        </Slider>
      </div>

      <div class="search__container text-center mt-3">
        <input
          class="search__input"
          type="text"
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
          placeholder="Ürün Ara"
        />
      </div>

      {info?.instagram != null && (
        <div className="primary-announcement-container mt-5 mb-4">
          <a className="row" href={info?.instagram} target="_blank">
            <img src={instaBadge} style={{ width: 150 }} />
            <div
              style={{ width: 90, marginRight: 20, textAlign: "center" }}
              className="btn-action"
            >
              TAKİP ET
            </div>
          </a>
        </div>
      )}

      <div className="text-center" style={{ marginTop: 35 }}>
        <p style={{ fontSize: 20 }}>{category}</p>
      </div>

      <div style={{ width: "90vw", margin: "0 auto" }}>
        {meals &&
          meals.map((m) => (
            <div
              className="row meal animate__animated animate__slideInUp"
              onClick={() => goToMeal(m)}
              style={{
                backgroundColor: info?.backgroundColor,
                boxShadow: info?.boxShadow,
              }}
            >
              <div style={{ width: "50%", marginLeft: 0, padding: 0 }}>
                <div className="image ">
                  <img
                    alt="Meal"
                    src={m.photo.trim() == "" ? defaultPhoto : m.photo}
                  />
                </div>
              </div>
              <div style={{ width: "50%" }}>
                <div className="header">
                  <p>
                    {lang == "en" ? m.nameEn : lang == "ar" ? m.nameAr : m.name}
                  </p>
                </div>
                {/* <div className="content">
                  {lang == "tr"
                    ? m.description
                    : lang == "en"
                    ? m.descriptionEn
                    : m.descriptionAr}
                </div> */}

                <div className="price" style={{ color: info?.priceColor }}>
                  <span>{m.paketPrice ?? m.price}</span>
                  <span>₺</span>
                </div>
                <div className="plus">
                  <i className="bi bi-plus-square-fill"></i>
                </div>
              </div>
            </div>
          ))}
      </div>
      <div style={{ height: "10vh" }}></div>
    </div>
  ) : (
    <div className="spinner-container">
      <Spinner animation="border" role="status" className="spinner">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  );
};

export default PaketMenu;
