import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

const PrintGarson = () => {
  const { order, table } = useLocation().state;
  const history = useHistory();

  useEffect(() => {
    if (order) window.print();
    history.push("/admin/siparisler");
  });

  return (
    <div
      className="ticket"
      style={{ fontFamily: "Helvetica" }}
      onFocus={() => window.close()}
    >
      <p className="centered" style={{ fontweight: "bold", fontSize: 20 }}>
        {table.name}
        <br />
      </p>
      <div>
        <h5 className="card-title" style={{ textAlign: "center" }}>
          İstek:
        </h5>
      </div>
      <p className="card-text">{order?.request} </p>
    </div>
  );
};

export default PrintGarson;
