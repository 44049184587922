import React, { useState, useEffect } from "react";
import { auth, firestore, storage } from "../utils/firebase";
import { useHistory } from "react-router-dom";
import { useRef } from "react";

const AdminPanel = () => {
  const history = useHistory();

  const puanSistem = useRef();
  const hasOnlyMenu = useRef();

  useEffect(() => {
    let name;
    auth.onAuthStateChanged((user) => {
      if (!user) {
        history.push("/login");
      }
    });
  }, []);
  const create = () => {
    firestore
      .collection("Restaurants")
      .doc(shortName)
      .set({ Menu: [] }, { merge: true })
      .then(() => {
        storage
          .ref(`${image.name}`)
          .put(image)
          .then((task) => {
            return task.ref.getDownloadURL();
          })
          .then((logoUrl) => {
            firestore
              .collection(`Restaurants/${shortName}/info`)
              .doc("info")
              .set({})
              .then(() => {
                firestore
                  .collection(`Restaurants/${shortName}/info`)
                  .doc("info")
                  .set({
                    color,
                    name,
                    logo: logoUrl,
                    welcomeText,
                    hasUserSystem: puanSistem.current.checked,
                    hasOnlyMenu: hasOnlyMenu.current.checked,
                  });
              });
          });

        firestore
          .collection(`Restaurants/${shortName}/info`)
          .doc("tables")
          .set({});
        firestore
          .collection(`Restaurants/${shortName}/orders`)
          .doc("order")
          .set({});
        firestore
          .collection(`Restaurants/${shortName}/orders`)
          .doc("waiter")
          .set({});
        firestore
          .collection(`Restaurants/${shortName}/users`)
          .doc("demo")
          .set({});

        firestore
          .collection("Users")
          .doc(shortName)
          .set({ email: shortName + "@dba.com" });
      })
      .then(() => {
        setWelcomeText("");
        setShortName("");
        setName("");
        setLogo("");
        setColor("");
        setPassword("");
        alert("Başarılı");

        auth.createUserWithEmailAndPassword(shortName + "@dba.com", password);
        firestore
          .collection("Admin")
          .doc(shortName + "@dba.com")
          .set({ restaurant: shortName })
          .then(() => {
            auth.signInWithEmailAndPassword(shortName + "@dba.com", password);
            history.push("/admin");
          });
      });
  };
  const [welcomeText, setWelcomeText] = useState("Hoş Geldiniz");
  const [shortName, setShortName] = useState("");
  const [name, setName] = useState("");
  const [logo, setLogo] = useState("");
  const [color, setColor] = useState("");
  const [password, setPassword] = useState("");
  const [image, setImage] = useState("");
  return (
    <div className="row">
      <div className="col-3">
        <div
          className="btn btn-info m-5"
          onClick={() => {
            auth.signOut();
          }}
        >
          Çıkış Yap
        </div>
      </div>

      <div className="col-6" style={{ fontSize: "0.8vw" }}>
        <form>
          <p style={{ marginTop: 50, textAlign: "center", fontWeight: "bold" }}>
            Yeni Restoran oluştur
          </p>
          <div className="form-group w-50 mx-auto">
            <label for="exampleFormControlInput1">Kısa Adı</label>
            <input
              type="text"
              className="form-control"
              value={shortName}
              placeholder="Linklerde gözükecek ve kullanıcı adı olacak."
              onChange={(e) => {
                setShortName(e.target.value);
              }}
            />
          </div>
          <div className="form-group w-50 mx-auto">
            <label for="exampleFormControlInput1">Restoran Adı</label>
            <input
              type="text"
              className="form-control"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </div>
          <div className="form-group w-50 mx-auto">
            <label for="exampleFormControlInput1">Logo</label>
            <input
              type="file"
              onChange={(e) => {
                setImage(e.target.files[0]);
              }}
            />
          </div>
          <div className="form-group w-50 mx-auto">
            <label for="exampleFormControlInput1">Renk</label>
            <input
              type="text"
              className="form-control"
              value={color}
              placeholder="renk hex değeri"
              onChange={(e) => {
                setColor(e.target.value);
              }}
            />
          </div>
          <div className="form-group w-50 mx-auto">
            <label for="exampleFormControlInput1">Menu girişteki text</label>
            <input
              type="text"
              className="form-control"
              value={welcomeText}
              onChange={(e) => {
                setWelcomeText(e.target.value);
              }}
            />
          </div>
          <div className="form-group w-50 mx-auto">
            <label for="exampleFormControlInput1">
              Panele Girişte Kullanılacak Şifre
            </label>
            <input
              type="password"
              className="form-control"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </div>
          <div className="form-group w-50 mx-auto">
            <input id="mPuan" type="checkbox" ref={puanSistem} />
            <label for="mPuan">Kullanıcı Puan Sistemi Olsun mu</label>
          </div>
          <div className="form-group w-50 mx-auto">
            <input id="cMenu" type="checkbox" ref={hasOnlyMenu} />
            <label for="cMenu">Sadece Menü</label>
          </div>

          <div className="row">
            <div
              className="btn btn-primary w-25 mx-auto mt-5"
              onClick={() => {
                create();
              }}
            >
              Kaydet
            </div>
          </div>
        </form>
      </div>
      <div className="col-3"></div>
    </div>
  );
};

export default AdminPanel;
