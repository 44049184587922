import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { auth, firestore, storage } from "../../utils/firebase";
import { v4 as uuid } from "uuid";
import { useHistory } from "react-router-dom";
import { Dropdown } from "react-bootstrap";

const Menu = () => {
  const [menu, setMenu] = useState([]);
  const cafe = localStorage.getItem("name");
  const [category, setCategory] = useState();
  const [meal, setMeal] = useState();
  const [items, setItems] = useState();
  const info = JSON.parse(localStorage.getItem("info"));
  const history = useHistory();
  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        firestore
          .collection("Restaurants")
          .doc(cafe)
          .onSnapshot((doc) => {
            if (doc.exists) {
              let _menu = doc.data().Menu;
              _menu.sort((a, b) => {
                if (parseFloat(a.sortOrder) < parseFloat(b.sortOrder))
                  return -1;
                else return 1;
              });
              setMenu(_menu);
            } else {
              // doc.data() will be undefined in this case
              console.log("No such document!");
            }
          });
      }
    });
  }, []);

  const [cat, setCat] = useState();
  const [catEn, setCatEn] = useState("");
  const [catAr, setCatAr] = useState("");
  const [photo, setPhoto] = useState();
  const [sortOrder, setOrder] = useState();
  const [newMeal, setNewMeal] = useState(false);
  const [newCategory, setNewCategory] = useState(false);
  const [activeCategory, setActiveCategory] = useState();

  const updateOptions = () => {
    firestore
      .collection("Restaurants")
      .doc("pilavhani")
      .get()
      .then((doc) => {
        let menu = doc.data().Menu;
        let items = menu[0].items[0].addOptions;

        menu.forEach((item, i, menu) => {
          if (
            [
              "Pilav",
              "Pilav Üstü Tavalar",
              "Sporcu Menü Izgara",
              "Sporcu Menü Haşlama",
              "Izgaralar",
              "Eko Menüler",
            ].includes(item.category)
          ) {
            item.items.forEach((meal, j, menu) => {
              meal.addOptions = items;
            });
          } else if (item.category == "Tava") {
            item.items.forEach((meal, j, menu) => {
              if (["Nugget Menü", "Şinitzel Menü"].includes(meal.name))
                meal.addOptions = items;
            });
          }
        });

        firestore
          .collection("Restaurants")
          .doc("pilavhani")
          .update({ Menu: menu });
      });
  };

  const update = (id) => {
    let ref = firestore.collection("Restaurants").doc(cafe);
    firestore.runTransaction((transaction) => {
      return transaction
        .get(ref)
        .then((doc) => {
          let new_menu = doc.data().Menu;

          new_menu.forEach((item, i, new_menu) => {
            if (item.id == id) {
              new_menu[i] = {
                items: item.items,
                category: cat,
                categoryEn: catEn,
                categoryAr: catAr,
                photo,
                id,
                sortOrder,
                active: activeCategory,
              };
            }
          });

          transaction.update(ref, {
            Menu: new_menu,
          });
        })
        .then(() => {
          toast.success("Güncellendi", {
            position: "bottom-center",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          setCategory(false);
          cleanValues();
        });
    });
  };
  const updateProduct = (id) => {
    let Menu = [...menu];
    Menu.forEach((item, i, Menu) => {
      item.items.map((it, ind) => {
        if (it.id == id) {
          Menu[i].items[ind] = {
            id,
            description,
            descriptionEn,
            descriptionAr,
            price: price.toString().replace(",", "."),
            paketPrice:
              paketPrice != null ? paketPrice.toString().replace(",", ".") : "",
            name,
            nameEn,
            nameAr,
            sortOrder: mealSortOrder,
            photo: mealPhoto,
            addOptions: addOptions || [],
            extraOptions: extraOptions || [],
            noPrint,
            removeOptions: removeOptions || [],
            portions: portions || [],
            prepTime,
            active: activeMeal,
          };
          console.log(Menu[i].items[ind]);
        }
      });
    });
    firestore
      .collection("Restaurants")
      .doc(cafe)
      .update({
        Menu,
      })
      .then(() => {
        toast.success("Ürün Güncellendi", {
          position: "bottom-center",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        setCategory();
        cleanValues();
      });
  };

  const deleteCategory = (id) => {
    firestore
      .collection("Restaurants")
      .doc(cafe)
      .update({
        Menu: menu.filter((m) => m.id != id),
      })
      .then(() => {
        toast.success("Kategori Silindi", {
          position: "bottom-center",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setCategory(false);
        cleanValues();
      });
  };
  const deleteProduct = (id) => {
    let Menu = [...menu];
    Menu.forEach((item, i, Menu) => {
      Menu[i].items = item.items.filter((i) => i.id != id);
    });
    firestore
      .collection("Restaurants")
      .doc(cafe)
      .update({
        Menu,
      })
      .then(() => {
        toast.success("Ürün Silindi", {
          position: "bottom-center",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setCategory(false);
        cleanValues();
      });
  };

  const saveCategory = () => {
    firestore
      .collection("Restaurants")
      .doc(cafe)
      .update({
        Menu: [
          ...menu,
          {
            id: uuid(),
            category: cat,
            categoryEn: catEn,
            categoryAr: catAr,
            items: [],
            photo,
            sortOrder,
            active: 1,
          },
        ],
      })
      .then(() => {
        toast.success("Kategori Eklendi", {
          position: "bottom-center",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setNewCategory(false);
        cleanValues();
      });
  };
  const saveProduct = (id) => {
    let ref = firestore.collection("Restaurants").doc(cafe);
    firestore.runTransaction((transaction) => {
      return transaction
        .get(ref)
        .then((doc) => {
          let new_menu = doc.data().Menu;

          new_menu.forEach((item, i, new_menu) => {
            if (item.id == id) {
              new_menu[i].items = [
                ...item.items,
                {
                  id: uuid(),
                  name,
                  nameEn,
                  nameAr,
                  description,
                  descriptionEn,
                  descriptionAr,
                  price: price.toString().replace(",", "."),
                  paketPrice: paketPrice.toString().replace(",", "."),
                  addOptions: [],
                  extraOptions: [],
                  noPrint,
                  noPrint: noPrint,
                  removeOptions: [],
                  portions: [],
                  photo: mealPhoto,
                  sortOrder: mealSortOrder,
                  prepTime,
                  active: 1,
                },
              ];
            }
          });

          transaction.update(ref, {
            Menu: new_menu,
          });
        })
        .then(() => {
          toast.success("Ürün Eklendi", {
            position: "bottom-center",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setCategory();
          cleanValues();
        });
    });
  };

  const stokRef = useRef();

  const cleanValues = () => {
    setCat("");
    setCatEn("");
    setCatAr("");
    setPhoto("");
    setOrder("");
    setName("");
    setNameEn("");
    setNameAr("");
    setPrice("");
    setDescription("");
    setDescriptionEn("");
    setDescriptionAr("");
    setMealPhoto("");
    setMealSortOrder("");
    setPrepTime("");
    setActiveMeal();
    setOpenOpt(false);
    setAddOptions(null);
    setExtraOptions(null);
    setNoPrint(null);
    setRemoveOptions(null);
    setAdd(false);
    setRemove(false);
    setAddPortion(false);
  };

  const [name, setName] = useState("");
  const [nameEn, setNameEn] = useState("");
  const [nameAr, setNameAr] = useState("");
  const [description, setDescription] = useState("");
  const [descriptionEn, setDescriptionEn] = useState("");
  const [descriptionAr, setDescriptionAr] = useState("");
  const [mealPhoto, setMealPhoto] = useState();
  const [price, setPrice] = useState();
  const [paketPrice, setPaketPrice] = useState();
  const [mealSortOrder, setMealSortOrder] = useState();
  const [prepTime, setPrepTime] = useState();
  const [activeMeal, setActiveMeal] = useState();
  const [noPrint, setNoPrint] = useState();

  const [openOpt, setOpenOpt] = useState(false);
  const [addOptions, setAddOptions] = useState();
  const [extraOptions, setExtraOptions] = useState();
  const [add, setAdd] = useState(false);
  const [remove, setRemove] = useState(false);
  const [removeOptions, setRemoveOptions] = useState();
  const [addPortion, setAddPortion] = useState(false);
  const nameRef = useRef();
  const [portions, setPortions] = useState(false);

  const [addOptName, setAddOptName] = useState("");
  const [addOptNameEn, setAddOptNameEn] = useState("");
  const [addOptNameAr, setAddOptNameAr] = useState("");
  const [portionName, setPortionName] = useState("");
  const [portionNameEn, setPortionNameEn] = useState("");
  const [portionNameAr, setPortionNameAr] = useState("");
  const [portionPrice, setPortionPrice] = useState("");
  const [portionPaketPrice, setPortionPaketPrice] = useState("");
  const [portionSortOrder, setPortionSortOrder] = useState("");

  const [addOptPrice, setAddOptPrice] = useState(0);
  const [addOptPaketPrice, setAddOptPaketPrice] = useState(0);
  const [addOptSortOrder, setAddOptSortOrder] = useState(0);
  const [removeOptName, setRemoveOptName] = useState("");
  const [removeOptNameEn, setRemoveOptNameEn] = useState("");
  const [removeOptNameAr, setRemoveOptNameAr] = useState("");
  const [addOptId, setAddOptId] = useState("");
  const [removeOptId, setRemoveOptId] = useState("");
  const [removeOptSortOrder, setRemoveOptSortOrder] = useState("");
  const [updateOption, setUpdateOption] = useState(false);
  const [updatePortion, setUpdatePortion] = useState(false);
  const [updateOptionR, setUpdateOptionR] = useState(false);

  useEffect(() => {
    if (!category) return;
    let chosen = category.items;
    chosen.sort((a, b) => {
      if (parseFloat(a.sortOrder) < parseFloat(b.sortOrder)) return -1;
      else return 1;
    });
    setItems(chosen);
  }, [add, meal, category, menu]);

  const updateAddOption = (id) => {
    let Menu = [...menu];
    Menu.forEach((category, i1, Menu) => {
      category.items.map((item, i2) => {
        if (item.id == meal.id) {
          item.addOptions.map((opt, i3) => {
            if (opt.id == id) {
              Menu[i1].items[i2].addOptions[i3] = {
                id,
                name: addOptName,
                nameEn: addOptNameEn,
                nameAr: addOptNameAr,
                price: addOptPrice.toString().replace(",", "."),
                paketPrice: addOptPaketPrice.toString().replace(",", "."),
                sortOrder: addOptSortOrder,
              };
            }
          });
        }
      });
    });
    setMenu(Menu);

    firestore
      .collection("Restaurants")
      .doc(cafe)
      .update({
        Menu,
      })
      .then(() => {
        toast.success("Seçenek Güncellendi", {
          position: "bottom-center",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setAddOptName();
        setAddOptNameEn();
        setAddOptNameAr();
        setAddOptPrice();
        setCategory();
      });
  };
  const createAddOption = (id) => {
    let Menu = [...menu];
    Menu.forEach((item, i, Menu) => {
      item.items.map((it, ind) => {
        if (it.id == id) {
          if (!it.addOptions) {
            it.addOptions = [
              {
                id: uuid(),
                name: addOptName,
                nameEn: addOptNameEn,
                nameAr: addOptNameAr,
                price: addOptPrice.toString().replace(",", "."),
                paketPrice: addOptPaketPrice.toString().replace(",", "."),
                sortOrder: addOptSortOrder,
              },
            ];
          } else {
            it.addOptions.push({
              id: uuid(),
              name: addOptName,
              nameEn: addOptNameEn,
              nameAr: addOptNameAr,
              price: addOptPrice.toString().replace(",", "."),
              paketPrice: addOptPaketPrice.toString().replace(",", "."),
              sortOrder: addOptSortOrder,
            });
          }
        }
      });
    });
    setMenu(Menu);
    firestore
      .collection("Restaurants")
      .doc(cafe)
      .update({
        Menu,
      })
      .then(() => {
        toast.success("Seçenek Eklendi", {
          position: "bottom-center",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setAddOptName();
        setAddOptNameEn();
        setAddOptNameAr();
        setAddOptPrice();
        setAdd(false);
        setCategory();
      });
  };
  const createPortion = (id) => {
    let Menu = [...menu];
    Menu.forEach((item, i, Menu) => {
      item.items.map((it, ind) => {
        if (it.id == id) {
          if (!it.portions) {
            it.portions = [
              {
                name: portionName,
                nameEn: portionNameEn,
                nameAr: portionNameAr,
                price: portionPrice.toString().replace(",", "."),
                paketPrice: portionPaketPrice.toString().replace(",", "."),
                sortOrder: portionSortOrder,
              },
            ];
          } else {
            it.portions.push({
              name: portionName,
              nameEn: portionNameEn,
              nameAr: portionNameAr,
              price: portionPrice.toString().replace(",", "."),
              paketPrice: portionPaketPrice.toString().replace(",", "."),
              sortOrder: portionSortOrder,
            });
          }
        }
      });
    });
    setMenu(Menu);
    firestore
      .collection("Restaurants")
      .doc(cafe)
      .update({
        Menu,
      })
      .then(() => {
        toast.success("Seçenek Eklendi", {
          position: "bottom-center",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setPortionPrice();
        setPortionSortOrder();
        setPortionName();
        setPortionNameEn();
        setPortionNameAr();
        setAddPortion(false);
        setCategory();
      });
  };
  const deleteAddOption = (id) => {
    let Menu = [...menu];
    Menu.forEach((item, i, Menu) => {
      item.items.map((it, ind) => {
        if (it.id == meal.id) {
          Menu[i].items[ind].addOptions = it.addOptions?.filter(
            (opt) => opt.id != id
          );
        }
      });
    });

    setMenu(Menu);

    firestore
      .collection("Restaurants")
      .doc(cafe)
      .update({ Menu })
      .then(() => {
        toast.success("Seçenek Silindi", {
          position: "bottom-center",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setAddOptName();
        setAddOptNameEn();
        setAddOptNameAr();
        setAddOptId();
        setAddOptPrice();
        setCategory();
      });
  };
  const deletePortion = (name) => {
    let Menu = [...menu];
    Menu.forEach((item, i, Menu) => {
      item.items.map((it, ind) => {
        if (it.id == meal.id) {
          Menu[i].items[ind].portions = it.portions?.filter(
            (opt) => opt.name != name
          );
        }
      });
    });

    setMenu(Menu);

    firestore
      .collection("Restaurants")
      .doc(cafe)
      .update({ Menu })
      .then(() => {
        toast.success("Seçenek Silindi", {
          position: "bottom-center",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setPortionName();
        setPortionNameEn();
        setPortionNameAr();
        setPortionPrice();
        setPortionSortOrder();
        setCategory();
      });
  };
  const createRemoveOption = (id) => {
    let Menu = [...menu];
    Menu.forEach((item, i, Menu) => {
      item.items.map((it, ind) => {
        if (it.id == id) {
          if (!it.removeOptions) {
            it.removeOptions = [
              {
                id: uuid(),
                name: removeOptName,
                nameEn: removeOptNameEn,
                nameAr: removeOptNameAr,
                sortOrder: removeOptSortOrder,
              },
            ];
          } else {
            it.removeOptions.push({
              id: uuid(),
              name: removeOptName,
              nameEn: removeOptNameEn,
              nameAr: removeOptNameAr,
              sortOrder: removeOptSortOrder,
            });
          }
        }
      });
    });
    setMenu(Menu);
    firestore
      .collection("Restaurants")
      .doc(cafe)
      .update({
        Menu,
      })
      .then(() => {
        toast.success("Seçenek Eklendi", {
          position: "bottom-center",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setRemoveOptName("");
        setRemoveOptNameEn("");
        setRemoveOptNameAr("");
        setRemove(false);
        setCategory();
      });
  };
  const deleteRemoveOption = (id) => {
    let Menu = [...menu];
    Menu.forEach((item, i, Menu) => {
      item.items.map((it, ind) => {
        if (it.id == meal.id) {
          Menu[i].items[ind].removeOptions = it.removeOptions?.filter(
            (opt) => opt.id != removeOptId
          );
        }
      });
    });
    setMenu(Menu);

    firestore
      .collection("Restaurants")
      .doc(cafe)
      .update({
        Menu,
      })
      .then(() => {
        toast.success("Seçenek Silindi", {
          position: "bottom-center",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setUpdateOptionR(false);
        setCategory();
      });
  };
  const updateRemoveOption = (id) => {
    let Menu = [...menu];
    Menu.forEach((category, i1, Menu) => {
      category.items.map((item, i2) => {
        if (item.id == meal.id) {
          item.removeOptions.map((opt, i3) => {
            if (opt.id == id) {
              Menu[i1].items[i2].removeOptions[i3] = {
                id,
                name: removeOptName,
                nameEn: removeOptNameEn,
                nameAr: removeOptNameAr,
                sortOrder: removeOptSortOrder,
              };
            }
          });
        }
      });
    });
    setMenu(Menu);

    firestore
      .collection("Restaurants")
      .doc(cafe)
      .update({
        Menu,
      })
      .then(() => {
        toast.success("Seçenek Güncellendi", {
          position: "bottom-center",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setAddOptName("");
        setAddOptNameEn("");
        setAddOptNameAr("");
        setAddOptPrice();
        setCategory();
        setRemove(false);
      });
  };
  const updatePortions = (name) => {
    let Menu = [...menu];
    Menu.forEach((category, i1, Menu) => {
      category.items.map((item, i2) => {
        if (item.id == meal.id) {
          item.portions.map((opt, i3) => {
            if (opt.name == name) {
              Menu[i1].items[i2].portions[i3] = {
                name: portionName,
                nameEn: portionNameEn,
                nameAr: portionNameAr,
                price: portionPrice,
                paketPrice: portionPaketPrice,
                sortOrder: portionSortOrder,
              };
            }
          });
        }
      });
    });
    setMenu(Menu);

    firestore
      .collection("Restaurants")
      .doc(cafe)
      .update({
        Menu,
      })
      .then(() => {
        toast.success("Seçenek Güncellendi", {
          position: "bottom-center",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setPortionName();
        setPortionNameEn();
        setPortionNameAr();
        setPortionPrice();
        setCategory();
        setRemove(false);
      });
  };

  const [image, setImage] = useState();
  const uploadImage = () => {
    storage
      .ref(`${image.name}`)
      .put(image)
      .then((task) => {
        return task.ref.getDownloadURL();
      })
      .then((url) => {
        if (newCategory) setPhoto(url);
        if (newMeal) setMealPhoto(url);
        if (category && !meal) setPhoto(url);
        if (category && meal) setMealPhoto(url);
      });
  };
  const goBack = () => {
    history.push("/admin");
  };
  return (
    <div className="row" style={{ minHeight: "100vh", marginBottom: 100 }}>
      <div>
        <div className="btn btn-danger mt-3" onClick={goBack}>
          <i
            className="fa fa-arrow-left"
            aria-hidden="true"
            style={{ width: "2vw" }}
          ></i>
        </div>
      </div>
      <div className="col-12 mt-3">
        <ul
          className="list-group"
          style={{
            cursor: "pointer",
            fontSize: 15,

            fontWeight: "bold",
            flexDirection: "row",
          }}
        >
          {menu &&
            menu.map((m, i) => (
              <li
                key={i}
                className="list-group-item  list-group-item-action"
                onClick={() => {
                  setCategory(m);
                  setCat(m.category);
                  setCatEn(m.categoryEn || "");
                  setCatAr(m.categoryAr || "");
                  setPhoto(m.photo);
                  setOrder(m.sortOrder);
                  setMeal();
                  setActiveCategory(m.active);
                  setNewCategory(false);
                  setNewMeal(false);
                }}
              >
                <span style={{ fontWeight: "bold" }}>{i + 1}. </span>{" "}
                {m.category}
              </li>
            ))}
        </ul>
        {category == null && (
          <div className="row">
            <div
              className="btn btn-secondary mt-3 w-50 mx-auto"
              onClick={() => {
                setCategory();
                setMeal();
                setNewCategory(true);
                cleanValues();
              }}
            >
              Yeni Kategori
            </div>
          </div>
        )}
      </div>
      <div className="col-3 mt-5">
        {category && items && <h3 className="text-center">Ürünler</h3>}
        <ul
          className="list-group"
          style={{ cursor: "pointer", fontSize: 15, fontWeight: "bold" }}
        >
          {category &&
            items &&
            items.map((item, i) => (
              <li
                className="list-group-item list-group-item-action"
                key={i}
                onClick={() => {
                  setName(item.name);
                  setNameEn(item.nameEn || "");
                  setNameAr(item.nameAr || "");
                  setDescription(item.description);
                  setDescriptionEn(item.descriptionEn || "");
                  setDescriptionAr(item.descriptionAr || "");
                  setPrice(item.price);
                  setPaketPrice(item.paketPrice);
                  setMealPhoto(item.photo);
                  setMealSortOrder(item.sortOrder);
                  setMeal(item);
                  setPrepTime(item.prepTime);
                  setActiveMeal(item.active);
                  setNewMeal(false);
                  setOpenOpt(true);
                  setNoPrint(item.noPrint ?? false);
                  setAddOptions(item.addOptions);
                  setExtraOptions(item.extraOptions);
                  setRemoveOptions(item.removeOptions);
                  setPortions(item.portions);
                  setUpdateOption(false);
                  setUpdateOptionR(false);
                  setRemove(false);
                }}
              >
                <span style={{ fontWeight: "bold" }}>{i + 1}. </span>{" "}
                {item.name}
              </li>
            ))}
        </ul>
        <div className="row">
          {category && (
            <div
              className="btn btn-secondary mt-3 w-50 mx-auto"
              onClick={() => {
                setNewMeal(true);
                cleanValues();
                setMeal();
                setUpdateOption(false);
              }}
            >
              Yeni Ürün
            </div>
          )}
        </div>
      </div>

      {newCategory && (
        <div className="col-6 mt-5">
          <form>
            <p style={{ textAlign: "center", fontWeight: "bold" }}></p>
            <div className="form-group">
              <label for="exampleFormControlInput1">Kategori Adı</label>
              <input
                type="text"
                className="form-control"
                value={cat}
                onChange={(e) => setCat(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label for="exampleFormControlInput1">İngilizce</label>
              <input
                type="text"
                className="form-control"
                value={catEn}
                onChange={(e) => setCatEn(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label for="exampleFormControlInput1">Arapça</label>
              <input
                type="text"
                className="form-control"
                value={catAr}
                onChange={(e) => setCatAr(e.target.value)}
              />
            </div>
            <div className="row">
              <label for="exampleFormControlInput1">Fotoğraf Yükle</label>
              <input
                type="file"
                onChange={(e) => {
                  setImage(e.target.files[0]);
                }}
              />
            </div>
            <div className="row">
              <div className="col btn btn-primary mt-3" onClick={uploadImage}>
                Yükle
              </div>
            </div>
            <div className="form-group">
              <label for="exampleFormControlInput1">Fotoğraf</label>
              <input
                type="text"
                className="form-control"
                value={photo}
                onChange={(e) => setPhoto(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label for="exampleFormControlInput1">Sıralama</label>
              <input
                type="text"
                className="form-control"
                value={sortOrder}
                onChange={(e) => setOrder(e.target.value)}
              />
            </div>

            <div className="row">
              <div
                className="btn btn-primary w-50 mx-auto mt-5"
                onClick={() => saveCategory()}
              >
                Kaydet
              </div>
            </div>
          </form>
        </div>
      )}

      {category && !meal && !newMeal && (
        <>
          <div className="col-3"></div>
          <div className="col-3"></div>
          <div className="col-3 mt-5">
            <form>
              <p style={{ textAlign: "center", fontWeight: "bold" }}>
                {category.category} Kategorisini Güncelle
              </p>
              <div className="form-group">
                <label for="exampleFormControlInput1">Kategori Adı</label>
                <input
                  type="text"
                  className="form-control"
                  value={cat}
                  placeholder={category.category}
                  onChange={(e) => setCat(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label for="exampleFormControlInput1">İngilizce</label>
                <input
                  type="text"
                  className="form-control"
                  value={catEn}
                  onChange={(e) => setCatEn(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label for="exampleFormControlInput1">Arapça</label>
                <input
                  type="text"
                  className="form-control"
                  value={catAr}
                  onChange={(e) => setCatAr(e.target.value)}
                />
              </div>
              <div className="row">
                <label for="exampleFormControlInput1">Fotoğraf Yükle</label>
                <input
                  type="file"
                  onChange={(e) => {
                    setImage(e.target.files[0]);
                  }}
                />
              </div>
              <div className="row">
                <div className="col btn btn-primary mt-3" onClick={uploadImage}>
                  Yükle
                </div>
              </div>
              <div className="form-group">
                <label for="exampleFormControlInput1">Fotoğraf</label>
                <input
                  type="text"
                  className="form-control"
                  value={photo}
                  placeholder={category.photo}
                  onChange={(e) => setPhoto(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label for="exampleFormControlInput1">Sıralama</label>
                <input
                  type="text"
                  className="form-control"
                  value={sortOrder}
                  placeholder={category.sortOrder}
                  onChange={(e) => setOrder(e.target.value)}
                />
              </div>

              <div className="row">
                <div className="btn-group mx-auto" role="group">
                  <div
                    className="btn btn-secondary mx-auto mt-5"
                    onClick={() => update(category.id)}
                  >
                    Güncelle
                  </div>
                  <div
                    className="btn btn-danger  mx-auto mt-5"
                    onClick={() => deleteCategory(category.id)}
                  >
                    Sil
                  </div>
                  {activeCategory == 1 ? (
                    <div
                      className="btn btn-danger mx-auto mt-5"
                      onClick={() => setActiveCategory(0)}
                    >
                      Pasif Et
                    </div>
                  ) : (
                    <div
                      className="btn btn-success mx-auto mt-5"
                      onClick={() => setActiveCategory(1)}
                    >
                      Aktif Et
                    </div>
                  )}
                </div>
              </div>
            </form>
          </div>
        </>
      )}

      {category && (meal || newMeal) && (
        <div className="col-9 mt-5">
          <form>
            <div className="row">
              <div className="col-4">
                <p style={{ textAlign: "center", fontWeight: "bold" }}>
                  {newMeal ? "Ürün Ekle" : `${meal.name} Güncelle`}
                </p>
                <div className="form-group">
                  <label for="exampleFormControlInput1">Ürün Adı</label>
                  <input
                    type="text"
                    className="form-control"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label for="exampleFormControlInput1">İngilizce</label>
                  <input
                    type="text"
                    className="form-control"
                    value={nameEn}
                    onChange={(e) => setNameEn(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label for="exampleFormControlInput1">Arapça</label>
                  <input
                    type="text"
                    className="form-control"
                    value={nameAr}
                    onChange={(e) => setNameAr(e.target.value)}
                  />
                </div>
                <div className="row">
                  <div className="form-group w-50">
                    <label for="exampleFormControlInput1">Fiyatı</label>
                    <input
                      type="text"
                      className="form-control"
                      value={price}
                      onChange={(e) => setPrice(e.target.value)}
                    />
                  </div>
                  <div className="form-group w-50">
                    <label for="exampleFormControlInput1">Paket Fiyatı</label>
                    <input
                      type="text"
                      className="form-control"
                      value={paketPrice}
                      onChange={(e) => setPaketPrice(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="col-4">
                <div className="row">
                  <label for="exampleFormControlInput1">Fotoğraf Yükle</label>
                  <input
                    type="file"
                    onChange={(e) => {
                      setImage(e.target.files[0]);
                    }}
                  />
                </div>
                <div className="row">
                  <div
                    className="col btn btn-primary mt-3"
                    onClick={uploadImage}
                  >
                    Yükle
                  </div>
                </div>

                <div className="form-group">
                  <label for="exampleFormControlInput1">Fotoğraf</label>
                  <input
                    type="text"
                    className="form-control"
                    value={mealPhoto}
                    onChange={(e) => setMealPhoto(e.target.value)}
                  />
                </div>

                <div className="form-group">
                  <label for="exampleFormControlInput1">Sıralama</label>
                  <input
                    type="text"
                    className="form-control"
                    value={mealSortOrder}
                    onChange={(e) => setMealSortOrder(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label for="exampleFormControlInput1">
                    Hazırlanma Süresi
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={prepTime}
                    onChange={(e) => setPrepTime(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-4">
                <div className="form-group">
                  <label for="exampleFormControlTextarea1">Açıklama</label>

                  <textarea
                    className="form-control"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    rows="3"
                  ></textarea>
                </div>
                <div className="form-group">
                  <label for="exampleFormControlTextarea1">İngilizce</label>

                  <textarea
                    className="form-control"
                    value={descriptionEn}
                    onChange={(e) => setDescriptionEn(e.target.value)}
                    rows="3"
                  ></textarea>
                </div>
                <div className="form-group">
                  <label for="exampleFormControlTextarea1">Arapça</label>

                  <textarea
                    className="form-control"
                    value={descriptionAr}
                    onChange={(e) => setDescriptionAr(e.target.value)}
                    rows="3"
                  ></textarea>
                </div>
              </div>
              {meal ? (
                <div className="row">
                  <div className="btn-group mx-auto" role="group">
                    <div
                      className="btn btn-secondary  mx-auto mt-5"
                      onClick={() => updateProduct(meal.id)}
                    >
                      Güncelle
                    </div>
                    <div
                      className="btn btn-danger mx-auto mt-5"
                      style={{
                        borderRight: "1px solid black",
                        borderLeft: "1px solid black",
                      }}
                      onClick={() => deleteProduct(meal.id)}
                    >
                      Sil
                    </div>
                    {activeMeal == 1 ? (
                      <div
                        className="btn btn-danger mx-auto mt-5"
                        onClick={() => setActiveMeal(0)}
                      >
                        Pasif Et
                      </div>
                    ) : (
                      <div
                        className="btn btn-success mx-auto mt-5"
                        onClick={() => setActiveMeal(1)}
                      >
                        Aktif Et
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div className="row">
                  <div
                    className="btn btn-secondary w-50 mx-auto mt-5"
                    onClick={() => saveProduct(category.id)}
                  >
                    Ekle
                  </div>
                  <div></div>
                </div>
              )}
            </div>
          </form>
        </div>
      )}
      <div className="col-3 mt-5">
        {info?.hasUserSystem &&
          meal &&
          meal.name &&
          meal.name == "Pilav" &&
          openOpt && (
            <div className="row">
              <div
                className="btn btn-primary mt-3 w-75 mx-auto"
                onClick={() => {
                  updateOptions();
                }}
              >
                Ortak Seçenekleri Güncelle
              </div>
            </div>
          )}
        {category && items && meal && openOpt && (
          <>
            <ul
              className="list-group"
              style={{ cursor: "pointer", fontSize: 15, fontWeight: "bold" }}
            >
              {addOptions
                ?.sort((a, b) => {
                  if (parseFloat(a.sortOrder) > parseFloat(b.sortOrder))
                    return 1;
                  else return -1;
                })
                .map((item, i) => (
                  <li
                    className="list-group-item list-group-item-action"
                    key={i}
                    onClick={() => {
                      setAddOptName(item.name);
                      setAddOptNameEn(item.nameEn || "");
                      setAddOptNameAr(item.nameAr || "");
                      setAddOptPrice(item.price);
                      setAddOptPaketPrice(item.paketPrice);
                      setAddOptId(item.id);
                      setAddOptSortOrder(item.sortOrder);

                      setUpdateOption(true);
                    }}
                  >
                    <span style={{ fontWeight: "bold" }}>{i + 1}. </span>{" "}
                    {item.name}
                  </li>
                ))}
            </ul>
            <div className="row">
              <div
                className="btn btn-secondary mt-3 w-50 mx-auto"
                onClick={() => {
                  setAdd(true);
                  setUpdateOption();
                }}
              >
                Ekstra Ekle
              </div>
              {updateOption && openOpt && (
                <div
                  className="btn btn-danger mt-3 w-50 mx-auto"
                  onClick={() => {
                    deleteAddOption(addOptId);
                  }}
                >
                  Sil
                </div>
              )}
            </div>
            <ul
              className="list-group mt-5"
              style={{ cursor: "pointer", fontSize: 15, fontWeight: "bold" }}
            >
              {removeOptions
                ?.sort((a, b) => {
                  if (parseFloat(a.sortOrder) > parseFloat(b.sortOrder))
                    return 1;
                  else return -1;
                })
                .map((item, i) => (
                  <li
                    className="list-group-item list-group-item-action"
                    key={i}
                    onClick={() => {
                      setRemoveOptName(item.name);
                      setRemoveOptNameEn(item.nameEn || "");
                      setRemoveOptNameAr(item.nameAr || "");
                      setRemoveOptId(item.id);
                      setRemoveOptSortOrder(item.sortOrder);

                      setUpdateOptionR(true);
                    }}
                  >
                    <span style={{ fontWeight: "bold" }}>{i + 1}. </span>{" "}
                    {item.name}
                  </li>
                ))}
            </ul>
            <div className="row">
              {category && (
                <div
                  className="btn btn-secondary mt-3 w-50 mx-auto "
                  onClick={() => {
                    setAdd(false);
                    setAddPortion(false);
                    setRemove(true);
                    setRemoveOptName("");
                    setRemoveOptNameEn("");
                    setRemoveOptNameAr("");
                    setRemoveOptSortOrder();
                    setUpdateOptionR(false);
                  }}
                >
                  Çıkarılacak Ürün Ekle
                </div>
              )}
              {updateOptionR && (
                <div
                  className="btn btn-danger w-50 mt-3 mx-auto "
                  onClick={() => {
                    deleteRemoveOption();
                  }}
                >
                  Sil
                </div>
              )}
            </div>
            {/* Porsiyonlar */}
            <div className="mt-5"></div>
            <ul
              className="list-group"
              style={{ cursor: "pointer", fontSize: 15, fontWeight: "bold" }}
            >
              {portions
                ?.sort((a, b) => {
                  if (parseFloat(a.sortOrder) > parseFloat(b.sortOrder))
                    return 1;
                  else return -1;
                })
                .map((item, i) => (
                  <li
                    className="list-group-item list-group-item-action"
                    key={i}
                    onClick={() => {
                      setPortionName(item.name);
                      setPortionNameEn(item.nameEn || "");
                      setPortionNameAr(item.nameAr || "");
                      setPortionPrice(item.price);
                      setPortionPaketPrice(item.paketPrice ?? 0);
                      setPortionSortOrder(item.sortOrder);

                      setUpdatePortion(true);
                    }}
                  >
                    <span style={{ fontWeight: "bold" }}>{i + 1}. </span>{" "}
                    {item.name}
                  </li>
                ))}
            </ul>
            <div className="row">
              <div
                className="btn btn-secondary mt-3 w-50 mx-auto"
                onClick={() => {
                  setAdd(false);
                  setAddPortion(true);
                  setUpdatePortion();
                }}
              >
                Porsiyon Ekle
              </div>
              {updatePortion && openOpt && (
                <div
                  className="btn btn-danger mt-3 w-50 mx-auto"
                  onClick={() => {
                    deletePortion(portionName);
                  }}
                >
                  Sil
                </div>
              )}
            </div>
          </>
        )}
      </div>
      {addOptions && openOpt && updatePortion && (
        <div className="col-3 mt-5">
          <form>
            <p style={{ textAlign: "center", fontWeight: "bold" }}>
              {portionName} Düzenle
            </p>

            <div className="form-group">
              <label for="exampleFormControlInput1">İsim</label>
              <input
                type="text"
                className="form-control"
                value={portionName}
                onChange={(e) => setPortionName(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label for="exampleFormControlInput1">İngilizce</label>
              <input
                type="text"
                className="form-control"
                value={portionNameEn}
                onChange={(e) => setPortionNameEn(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label for="exampleFormControlInput1">Arapça</label>
              <input
                type="text"
                className="form-control"
                value={portionNameAr}
                onChange={(e) => setPortionNameAr(e.target.value)}
              />
            </div>
            <div className="row">
              <div className="form-group col">
                <label for="exampleFormControlInput1">Ücreti</label>
                <input
                  type="text"
                  className="form-control"
                  value={portionPrice}
                  onChange={(e) => setPortionPrice(e.target.value)}
                />
              </div>
              <div className="form-group col">
                <label for="exampleFormControlInput1">Paket Ücreti</label>
                <input
                  type="text"
                  className="form-control"
                  value={portionPaketPrice}
                  onChange={(e) => setPortionPaketPrice(e.target.value)}
                />
              </div>
            </div>
            <div className="form-group">
              <label for="exampleFormControlInput1">Sıralaması</label>
              <input
                type="text"
                className="form-control"
                value={portionSortOrder}
                onChange={(e) => setPortionSortOrder(e.target.value)}
              />
            </div>

            <div className="row">
              <div
                className="btn btn-primary w-50 mx-auto mt-5"
                onClick={() => {
                  updatePortions(portionName);
                }}
              >
                Kaydet
              </div>
            </div>
          </form>
        </div>
      )}
      {addOptions && openOpt && updateOption && (
        <div className="col-3 mt-5">
          <form>
            <p style={{ textAlign: "center", fontWeight: "bold" }}>
              {addOptName} Düzenle
            </p>

            <div className="form-group">
              <label for="exampleFormControlInput1">İsim</label>
              <input
                type="text"
                className="form-control"
                value={addOptName}
                onChange={(e) => setAddOptName(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label for="exampleFormControlInput1">İngilizce</label>
              <input
                type="text"
                className="form-control"
                value={addOptNameEn}
                onChange={(e) => setAddOptNameEn(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label for="exampleFormControlInput1">Arapça</label>
              <input
                type="text"
                className="form-control"
                value={addOptNameAr}
                onChange={(e) => setAddOptNameAr(e.target.value)}
              />
            </div>
            <div className="row">
              <div className="form-group">
                <label for="exampleFormControlInput1">Ücreti</label>
                <input
                  type="text"
                  className="form-control"
                  value={addOptPrice}
                  onChange={(e) => setAddOptPrice(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label for="exampleFormControlInput1">Paket Ücreti</label>
                <input
                  type="text"
                  className="form-control"
                  value={addOptPaketPrice}
                  onChange={(e) => setAddOptPaketPrice(e.target.value)}
                />
              </div>
            </div>
            <div className="form-group">
              <label for="exampleFormControlInput1">Ücreti</label>
              <input
                type="text"
                className="form-control"
                value={addOptPrice}
                onChange={(e) => setAddOptPrice(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label for="exampleFormControlInput1">Sıralaması</label>
              <input
                type="text"
                className="form-control"
                value={addOptSortOrder}
                onChange={(e) => setAddOptSortOrder(e.target.value)}
              />
            </div>

            <div className="row">
              <div
                className="btn btn-primary w-50 mx-auto mt-5"
                onClick={() => {
                  updateAddOption(addOptId);
                }}
              >
                Kaydet
              </div>
            </div>
          </form>
        </div>
      )}
      {remove && (
        <div className="col-3 mt-5">
          <form>
            <p style={{ textAlign: "center", fontWeight: "bold" }}>
              Çıkarılacak Ürün Ekle
            </p>

            <div className="form-group">
              <label for="exampleFormControlInput1">İsim</label>
              <input
                type="text"
                className="form-control"
                value={removeOptName}
                onChange={(e) => setRemoveOptName(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label for="exampleFormControlInput1">İngilizce</label>
              <input
                type="text"
                className="form-control"
                value={removeOptNameEn}
                onChange={(e) => setRemoveOptNameEn(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label for="exampleFormControlInput1">Arapça</label>
              <input
                type="text"
                className="form-control"
                value={removeOptNameAr}
                onChange={(e) => setRemoveOptNameAr(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label for="exampleFormControlInput1">Sıralaması</label>
              <input
                type="text"
                className="form-control"
                value={removeOptSortOrder}
                onChange={(e) => setRemoveOptSortOrder(e.target.value)}
              />
            </div>

            <div className="row">
              <div
                className="btn btn-primary w-50 mx-auto mt-5"
                onClick={() => createRemoveOption(meal.id)}
              >
                Kaydet
              </div>
            </div>
          </form>
        </div>
      )}
      {updateOptionR && (
        <div className="col-3 mt-5">
          <form>
            <p style={{ textAlign: "center", fontWeight: "bold" }}>
              {removeOptName} Düzenle
            </p>

            <div className="form-group">
              <label for="exampleFormControlInput1">İsim</label>
              <input
                type="text"
                className="form-control"
                value={removeOptName}
                onChange={(e) => setRemoveOptName(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label for="exampleFormControlInput1">İngilizce</label>
              <input
                type="text"
                className="form-control"
                value={removeOptNameEn}
                onChange={(e) => setRemoveOptNameEn(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label for="exampleFormControlInput1">Arapça</label>
              <input
                type="text"
                className="form-control"
                value={removeOptNameAr}
                onChange={(e) => setRemoveOptNameAr(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label for="exampleFormControlInput1">Sıralaması</label>
              <input
                type="text"
                className="form-control"
                value={removeOptSortOrder}
                onChange={(e) => setRemoveOptSortOrder(e.target.value)}
              />
            </div>

            <div className="row">
              <div
                className="btn btn-primary w-50 mx-auto mt-5"
                onClick={() => {
                  updateRemoveOption(removeOptId);
                }}
              >
                Kaydet
              </div>
            </div>
          </form>
        </div>
      )}
      {add && (
        <div className="col-3 mt-5">
          <form>
            <p style={{ textAlign: "center", fontWeight: "bold" }}>
              Yeni Seçenek Ekle
            </p>

            <div className="form-group">
              <label for="exampleFormControlInput1">İsim</label>
              <input
                type="text"
                className="form-control"
                value={addOptName}
                onChange={(e) => setAddOptName(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label for="exampleFormControlInput1">İngilizce</label>
              <input
                type="text"
                className="form-control"
                value={addOptNameEn}
                onChange={(e) => setAddOptNameEn(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label for="exampleFormControlInput1">Arapça</label>
              <input
                type="text"
                className="form-control"
                value={addOptNameAr}
                onChange={(e) => setAddOptNameAr(e.target.value)}
              />
            </div>
            <div className="row">
              <div className="form-group col">
                <label for="exampleFormControlInput1">Ücreti</label>
                <input
                  type="text"
                  className="form-control"
                  value={addOptPrice}
                  onChange={(e) => setAddOptPrice(e.target.value)}
                />
              </div>
              <div className="form-group col">
                <label for="exampleFormControlInput1">Paket Ücreti</label>
                <input
                  type="text"
                  className="form-control"
                  value={addOptPaketPrice}
                  onChange={(e) => setAddOptPaketPrice(e.target.value)}
                />
              </div>
            </div>

            <div className="form-group">
              <label for="exampleFormControlInput1">Sıralaması</label>
              <input
                type="text"
                className="form-control"
                value={addOptSortOrder}
                onChange={(e) => setAddOptSortOrder(e.target.value)}
              />
            </div>

            <div className="row">
              <div
                className="btn btn-primary w-505 mx-auto mt-5"
                onClick={() => createAddOption(meal.id)}
              >
                Kaydet
              </div>
            </div>
          </form>
        </div>
      )}
      {addPortion && (
        <div className="col-3 mt-5">
          <form>
            <p style={{ textAlign: "center", fontWeight: "bold" }}>
              Porsiyon Ekle
            </p>

            <div className="form-group">
              <label for="exampleFormControlInput1">İsim</label>
              <input
                type="text"
                className="form-control"
                value={portionName}
                onChange={(e) => setPortionName(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label for="exampleFormControlInput1">İngilizce</label>
              <input
                type="text"
                className="form-control"
                value={portionNameEn}
                onChange={(e) => setPortionNameEn(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label for="exampleFormControlInput1">Arapça</label>
              <input
                type="text"
                className="form-control"
                value={portionNameAr}
                onChange={(e) => setPortionNameAr(e.target.value)}
              />
            </div>
            <div className="row">
              <div className="form-group col">
                <label for="exampleFormControlInput1">Ücreti</label>
                <input
                  type="text"
                  className="form-control"
                  value={portionPrice}
                  onChange={(e) => setPortionPrice(e.target.value)}
                />
              </div>
              <div className="form-group col">
                <label for="exampleFormControlInput1">Paket Ücreti</label>
                <input
                  type="text"
                  className="form-control"
                  value={portionPaketPrice}
                  onChange={(e) => setPortionPaketPrice(e.target.value)}
                />
              </div>
            </div>
            <div className="form-group">
              <label for="exampleFormControlInput1">Sıralaması</label>
              <input
                type="text"
                className="form-control"
                value={portionSortOrder}
                onChange={(e) => setPortionSortOrder(e.target.value)}
              />
            </div>

            <div className="row">
              <div
                className="btn btn-primary w-50 mx-auto mt-5"
                onClick={() => createPortion(meal.id)}
              >
                Kaydet
              </div>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default Menu;
