import React from "react";
import "../css/print.css";

const Print = React.forwardRef((props, ref) => {
    const { order, total, table, menuObj } = props;
    const info = JSON.parse(localStorage.getItem("info"));
    return (
        order && (
            <div style={{
                width: '100vw',
                height: 'fit-content',
                maxHeight: '250px', textAlign: 'center', alignContent: 'center'
            }} ref={ref}>
                {info?.includeLogo && (<img
                    src={info.logo}
                    style={{
                        maxWidth: 'inherit',
                        width: 'inherit'
                    }}
                    alt="Logo"
                />)}

                <p style={{ fontWeight: 'bold', fontSize: 30 }}>
                    {info.name}
                </p>
                <p style={{ fontWeight: 'bold', fontSize: 20 }}>
                    {table.name}
                </p>
                <table style={{ borderCollapse: 'collapse', fontSize: '15px !important', width: '100%' }}>
                    <thead>
                        <tr>
                            <th style={{ width: '10%', wordBreak: 'break-all' }}>Adet</th>
                            <th style={{ width: '50%' }}>Ürün</th>
                            <th style={{ width: '30%', wordBreak: 'break-all', color: 'unset' }}>Fiyat</th>
                        </tr>
                    </thead>
                    <tbody style={{ borderBottom: "1px solid black" }}>
                        {order?.map((o) =>
                            o.cart.map((cart) => (
                                <>
                                    <tr >
                                        <td style={{ width: '15%', wordBreak: 'break-all' }}>
                                            {cart.quantity}</td>
                                        <td style={{ width: '55%' }}>
                                            {cart.portion != null && cart.portion ? (
                                                <p>{cart.portion.name}</p>
                                            ) : (
                                                ""
                                            )}
                                            <p>{menuObj[cart.meal].name}</p>
                                            <p style={{ lineHeight: 0.8, marginTop: -8 }}>
                                                {cart.checkedOptions?.length > 0 &&
                                                    cart.checkedOptions?.map((opt) => (
                                                        <span>{opt.name}</span>
                                                    ))}
                                            </p>
                                            {cart.removedOptions?.length > 0 && (
                                                <p style={{ lineHeight: 0.8, marginTop: -8 }}>
                                                    Çıkarılacaklar:
                                                    {" " +
                                                        cart.removedOptions?.map((opt) => opt.name).join(",")}
                                                </p>
                                            )}
                                            {cart.extraOptions && (
                                                <p style={{ lineHeight: 0.8, marginTop: -8 }}>
                                                    Patates Seçimi:
                                                    {cart.extraOptions.name}
                                                </p>
                                            )}
                                            {cart.mealNote && cart.mealNote != null && (
                                                <p style={{ lineHeight: 0.8, marginTop: -8 }}>
                                                    Not:
                                                    {cart.mealNote}
                                                </p>)}
                                        </td>
                                        <td tyle={{ width: '30%', wordBreak: 'break-all', color: 'unset' }}>
                                            {(parseFloat(cart.total) + parseFloat(cart.optPrice)) *
                                                cart.quantity + "TL"}
                                        </td>

                                    </tr>
                                    {o.note && (
                                        <tr>
                                            <td>Not:</td>
                                            <td>{o.note}</td>
                                        </tr>
                                    )}

                                </>
                            ))
                        )}
                    </tbody>
                    <tr>
                        <td>Toplam:</td>
                        <td></td>
                        <td>{total + " TL"}</td>
                    </tr>
                </table>
                <p style={{ marginTop: 5 }}>
                    Tercihiniz için teşekkürler!
                    <br /> AlkaQr Menu
                    <br /> info@dbateknoloji.com{" "}
                </p>
            </div>
        ));
});

export default Print;
