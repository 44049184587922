import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { firestore } from "../../utils/firebase";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../css/masalar.css";
import { v4 as uuid } from "uuid";
import { FaPowerOff } from "react-icons/fa";
import { useReactToPrint } from "react-to-print";
import { PaymentMethod } from '../../utils/items';
import { ButtonGroup, Button } from "react-bootstrap";
const Arama = ({ noSave = false }) => {
    const { number } = useParams();
    const [selected, select] = useState();
    const [order, setOrder] = useState();
    const history = useHistory();
    const cafe = localStorage.getItem("name");
    const info = JSON.parse(localStorage.getItem("info"));
    const [menu, setMenu] = useState(null);
    const [menuObj, setMenuObj] = useState(null);
    const [posSelected, posSelect] = useState(false);
    const note = useRef();
    const nameRef = useRef();
    const addressRef = useRef();
    const notRef = useRef();
    const addressTitleRef = useRef();
    const [cart, setCart] = useState([]);
    const [user, setUser] = useState();
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [selectedAddress, setSelectedAddress] = useState();
    const [paymentMethod, setPaymentMethod] = useState(PaymentMethod.Nakit);
    const priceRef = useRef();

    useEffect(() => {
        if (noSave) return;
        const id = uuid();
        firestore.collection(`Restaurants/${cafe}/aramalar`)
            .doc(id)
            .set({
                id,
                numara: number,
                tarih: new Date(),
            })

    }, [])

    useEffect(() => {
        if (number == null) return;

        firestore
            .collection(`Restaurants`)
            .doc(cafe)
            .onSnapshot((doc) => {
                let getMenu = doc.data().Menu;
                getMenu.sort((a, b) => {
                    if (a.sortOrder < b.sortOrder) return -1;
                    else return 1;
                });
                getMenu = getMenu.filter((m) => m.active == 1);
                setMenu(getMenu);
                setMenuObj(Object.assign({}, ...(getMenu.map(m => m.items.map(item => ({ [item.id]: item }))).flat())));
            });
        firestore
            .collection(`Restaurants/${cafe}/users`)
            .doc(number)
            .get()
            .then(doc => {
                if (doc.exists) {
                    setUser(doc.data());
                }
            })
    }, [number]);



    const sepeteEkle = (item) => {
        let i = 0;
        let id = uuid();
        if (cart?.length > 0) {
            let new_items = [...cart];
            let index = new_items.findIndex((el) => el == item);
            if (index != -1) {
                i = 1;
                new_items[index].quantity += parseInt(note.current.value) || 1;
                new_items[index].total += parseFloat(item.paketPrice ?? item.price) + getOptionsPrice();
                setCart(new_items);
            }
            if (i == 0)
                setCart([
                    ...cart,
                    {
                        id,
                        quantity: parseInt(note.current.value) || 1,
                        meal: item.id,
                        mealNote: "",
                        checkedOptions,
                        optPrice: getOptionsPrice(),
                        portion: portion || null,
                        total: parseFloat(item.paketPrice ?? item.price),
                    },
                ]);
        } else {
            setCart([
                {
                    id,
                    quantity: parseInt(note.current.value) || 1,
                    checkedOptions,
                    meal: item.id,
                    mealNote: "",
                    optPrice: getOptionsPrice(),
                    portion: portion || null,
                    total: parseFloat(item.paketPrice ?? item.price),
                },
            ]);
        }
        note.current.value = "";
        setCheckedOptions([]);
    };

    const sepettenCikar = (item) => {

        if (cart?.length > 0) {
            let new_items = [...cart];

            if (item.quantity == 1) {
                setCart(cart.filter((c) => c.id != item.id));
            } else {
                new_items.forEach((it, i, new_items) => {
                    if (item.id == it.id) {
                        new_items[i].quantity--;
                    }
                });
                setCart(new_items);
            }
        }
    };
    const calculateToplam = () => {
        let total = 0.0;
        cart?.map((c) => {
            total += (parseFloat(c.total) + parseFloat(c.optPrice)) * c.quantity;
        });

        return total.toFixed(2) + " TL";
    };
    const giveOrder = (e) => {

        if (calculateToplam() == "0 TL") {
            toast.error("Ürün yok.", {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }

        if (selectedAddress == null) {
            toast.error("Adres Seçiniz", {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }

        if (paymentMethod == null || paymentMethod == "") {
            toast.error("Ödeme Yöntemi Seçiniz", {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }
        setButtonDisabled(true);
        var today = new Date();
        let id = uuid();
        let toAdd = {
            id,
            cart,
            total: calculateToplamSepet(),
            note: note.current.value,
            status: 1,
            noNotification: true,
            day: today.toLocaleDateString("en-US"),
        };
        toAdd.address = selectedAddress.address ?? selectedAddress.addres;
        toAdd.phone = number;
        toAdd.arama = true;
        toAdd.date = new Date();
        toAdd.note = notRef.current.value ?? "";
        toAdd.name = (user.name || nameRef.current?.value) ?? "";
        toAdd.paymentMethod = paymentMethod;
        toAdd.cafe = cafe;
        firestore.collection(`Restaurants/${cafe}/paket-orders`).doc(id).set(toAdd)
            .then(() => {
                let date = new Date().toLocaleDateString();
                let ref = firestore.collection(`Restaurants/${cafe}/sales`).doc(date);

                ref.get().then((doc) => {
                    let data = doc.data() || {
                        sales: {},
                        sayı: 0,
                        arama: 0,
                    };
                    let items = cart.map((item) => item);
                    data.sayı += 1;
                    items.map((el) => {
                        if (data.sales[el.meal]) data.sales[el.meal] += el.quantity;
                        else data.sales[el.meal] = el.quantity;
                    });
                    data.arama = (data.arama || 0) + parseFloat(toAdd.total);
                    ref.set(data);
                }).then(() => {
                    cleanSepet();
                    setButtonDisabled(false);
                    window.open("about:blank", "_self");
                    window.close();
                })
            })



    };

    const cleanSepet = () => {
        setCart([]);
    };


    const calculateTotal = () => {
        let total = 0.0;
        order?.map((c) => {
            total += parseFloat(c.total);
        });
        return total.toFixed(2);
    };
    const calculateToplamSepet = () => {
        let total = 0.0;
        cart?.map((c) => {
            total += (parseFloat(c.total) + parseFloat(c.optPrice)) * c.quantity;
        });
        return total.toFixed(2);
    };
    const saveAddress = () => {
        var address = {};
        address.title = addressTitleRef.current.value;
        address.address = addressRef.current.value;

        var ref = firestore
            .collection(`Restaurants/${cafe}/users`)
            .doc(number);

        ref.get()
            .then(doc => {
                if (doc.exists) {
                    return doc.data();
                }
                return {};
            }).then(data => {
                if (!data.addressList) {
                    data.addressList = [];
                    data.addressList.push(address);
                }
                else {
                    data.addressList.push(address);
                }
                data.name = nameRef.current.value;
                ref.set(data);
                setUser(data);
                setSelectedAddress(address);
                setAddNew(false);
            });
    }
    const [openOptions, setOpenOptions] = useState();
    const [checkedOptions, setCheckedOptions] = useState([]);
    const [portion, setPortion] = useState();

    const getPrice = (a) => {
        if (a.paketPrice == null || a.paketPrice == "") {
            return a.price;
        }
        return parseFloat(a.paketPrice) || 0;
    };
    const getOptionsPrice = () => {
        let totalOpt = 0.0;
        checkedOptions.map((opt) => {
            totalOpt += parseFloat(getPrice(opt)) || 0;
        });
        return totalOpt;
    };

    const checkMenuItem = (id) => {
        return orders?.filter((o) => o.table == id).length > 0;
    };

    const [selectedItems, setSelectedItems] = useState({});
    const [selectedItem, setSelectedItem] = useState(null);
    const [showItemMenu, setShowItemMenu] = useState(false);
    const [addNew, setAddNew] = useState(false);
    const [orderToCancel, setOrderToCancel] = useState();
    const goBack = () => {
        history.push("/admin");
    };
    const [blurScreen, setBlurScreen] = useState(false);

    const [openMenu, setOpenMenu] = useState();

    const openOptionsMenu = () => {
        setOpenMenu("optionsMenu");
        setBlurScreen(true);
    };

    const calculateTotalToPayment = () => {
        let total = 0.0;
        Object.keys(selectedItems).map((item) => {
            if (selectedItems[item] != null)
                total +=
                    (parseFloat(menuObj[selectedItems[item]?.mealId].price) +
                        selectedItems[item]?.optPrice) *
                    selectedItems[item]?.quantity || 0.0;
        });
        return total;
    };

    const printRef = useRef();
    const [orderToPrint, setOrderToPrint] = useState(null);
    const printData = useReactToPrint({
        content: () => printRef.current,
        onAfterPrint: () => setOrderToPrint(null),
    });

    useEffect(() => {
        if (orderToPrint) {
            printData();
        }
    }, [orderToPrint]);

    return (
        <>

            <div>
                <div className="btn btn-dark mt-3" onClick={goBack}>
                    <i
                        className="fa fa-arrow-left"
                        aria-hidden="true"
                        style={{ width: "2vw" }}
                    ></i>
                </div>
            </div>
            <div
                className="blurred"
                style={{ display: blurScreen ? "block" : "none" }}
            ></div>

            {openMenu == "optionsMenu" && (
                <div>
                    {openOptions?.showInMenu ? (
                        <div className="meal-options-menu">
                            <div
                                className="meal-options-container text-center"
                                style={{ fontSize: "20px" }}
                            >
                                Tutar:
                                <input
                                    style={{ height: "5vh" }}
                                    type="number"
                                    ref={priceRef}
                                    onChange={() => {
                                        setCheckedOptions([
                                            {
                                                id: "0",
                                                name: "",
                                                price: priceRef.current.value,
                                                sortOrder: 0,
                                            },
                                        ]);
                                    }}
                                />
                                <div
                                    className="btn btn-danger"
                                    style={{ marginLeft: 100 }}
                                    onClick={() => {
                                        setOpenOptions(null);
                                        setOpenMenu();
                                        setCheckedOptions([]);
                                        setBlurScreen(false);
                                    }}
                                >
                                    Kapat
                                </div>
                                <div
                                    className=" btn btn-secondary"
                                    onClick={() => {
                                        sepeteEkle(openOptions);
                                        setOpenMenu();
                                        setBlurScreen(false);
                                        setOpenOptions(null);
                                    }}
                                >
                                    Onayla
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="meal-options-menu">
                            <div className="meal-options-container">
                                <div className="row">
                                    <div
                                        className={
                                            openOptions?.removeOptions &&
                                                openOptions?.removeOptions.length > 0
                                                ? "col-6"
                                                : "col-11"
                                        }
                                    >
                                        {openOptions?.addOptions?.map((opt) => (
                                            <div
                                                className="card"
                                                onClick={() => {
                                                    if (checkedOptions.includes(opt)) {
                                                        setCheckedOptions(
                                                            checkedOptions.filter((op) => op != opt)
                                                        );
                                                    } else {
                                                        setCheckedOptions([...checkedOptions, opt]);
                                                    }
                                                }}
                                                style={{
                                                    border: checkedOptions.includes(opt)
                                                        ? "0.5px solid red"
                                                        : "",
                                                }}
                                            >
                                                <h5>{opt.name}</h5>
                                                <h5>{opt.paketPrice} TL</h5>
                                            </div>
                                        ))}
                                    </div>
                                    {openOptions?.removeOptions &&
                                        openOptions.removeOptions.length > 0 && (
                                            <div className="col-5 card">
                                                {openOptions?.removeOptions?.map((opt) => (
                                                    <div
                                                        onClick={() => {
                                                            if (checkedOptions.includes(opt)) {
                                                                setCheckedOptions(
                                                                    checkedOptions.filter((op) => op != opt)
                                                                );
                                                            } else {
                                                                setCheckedOptions([...checkedOptions, opt]);
                                                            }
                                                        }}
                                                        style={{
                                                            border: checkedOptions.includes(opt)
                                                                ? "0.5px solid red"
                                                                : "",
                                                        }}
                                                    >
                                                        <h5>{opt.name}</h5>
                                                        {/* <h5>{opt.paketPrice} TL</h5> */}
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    <div className="col-1">
                                        {openOptions?.portions?.map((opt) => (
                                            <div
                                                className="card"
                                                onClick={() => {
                                                    if (checkedOptions.includes(opt)) {
                                                        setCheckedOptions(
                                                            checkedOptions.filter((op) => op != opt)
                                                        );
                                                    } else {
                                                        setCheckedOptions([...checkedOptions, opt]);
                                                    }
                                                }}
                                                style={{
                                                    border: checkedOptions.includes(opt)
                                                        ? "0.5px solid red"
                                                        : "",
                                                }}
                                            >
                                                <h5 className="card-title">{opt.name}</h5>
                                                <h5 className="card-text">{(opt.paketPrice == null || opt.paketPrice == "") ? opt.price : opt.paketPrice} TL</h5>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className="mt-5 text-center" style={{ height: 60 }}>
                                    <div
                                        className="btn btn-danger w-25 h-100"
                                        style={{ fontSize: "1.3vw" }}
                                        onClick={() => {
                                            setOpenOptions(null);
                                            setOpenMenu();
                                            setCheckedOptions([]);
                                            setBlurScreen(false);
                                        }}
                                    >
                                        Kapat
                                    </div>
                                    <div
                                        className="btn btn-secondary w-25 h-100"
                                        style={{ fontSize: "1.3vw" }}
                                        onClick={() => {
                                            sepeteEkle(openOptions);
                                            setOpenMenu();
                                            setBlurScreen(false);
                                            setOpenOptions(null);
                                        }}
                                    >
                                        Onayla
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}

            <div style={{ height: "100vh", overflowX: "hidden" }}>
                {(
                    <div className="pos">
                        <div className="content">
                            <div className="row">
                                <div className="col-2" style={{ cursor: "pointer", height: '100vh', borderRight: '1px solid black' }}>

                                    <>
                                        <div
                                            className="menu-item text-center">
                                            {user?.addressList != null && !addNew ? (
                                                <>
                                                    <h1>Arayan</h1>
                                                    <h3 style={{ color: 'red' }}>{user?.name}</h3>
                                                    <h3>{number}</h3>
                                                    <div className="mt-5">
                                                        {user?.addressList.map((address, i) => (
                                                            <div className="card" key={i}
                                                                onClick={() => {
                                                                    setSelectedAddress(address);
                                                                }}
                                                                style={{ background: selectedAddress == address ? "#ddd" : "" }}>
                                                                <div className="card-body">
                                                                    <h5 className="card-title">{address.title != "" ? address.title : "Adres"}</h5>
                                                                    <p className="card-text">{address.address ?? address.addres}</p>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                    <div style={{ marginTop: 20 }} onClick={() => {
                                                        setAddNew(true);
                                                    }} class="btn btn-danger">Yeni Adres Ekle</div>
                                                </>
                                            ) : (
                                                <div className="text-center">
                                                    <h1>Arayan</h1>
                                                    <h3>{number}</h3>
                                                    <form>
                                                        <div className="form-group  mx-auto">
                                                            <label for="exampleFormControlInput1">İsim</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                ref={nameRef}
                                                                value={addNew ? user.name : null}
                                                            />
                                                        </div>
                                                        <div className="form-group  mx-auto">
                                                            <label for="exampleFormControlInput1">Adres Başlığı</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                ref={addressTitleRef}
                                                            />
                                                        </div>
                                                        <div className="form-group  mx-auto">
                                                            <label for="exampleFormControlInput1">Adres</label>
                                                            <textarea
                                                                type="text"
                                                                className="form-control"
                                                                style={{ height: '20vh' }}
                                                                ref={addressRef}
                                                            />
                                                        </div>
                                                        <div className="row">
                                                            <div
                                                                style={{ background: '#444', color: 'white' }}
                                                                className="btn w-50 mx-auto mt-5"
                                                                onClick={() => {
                                                                    saveAddress();
                                                                }}
                                                            >
                                                                Kaydet
                                                            </div>
                                                            {addNew && (<div
                                                                className="btn btn-danger w-50 mx-auto mt-5"
                                                                onClick={() => {
                                                                    setAddNew(false);
                                                                }}
                                                            >
                                                                İptal
                                                            </div>)}
                                                        </div>
                                                    </form>
                                                </div>
                                            )}
                                        </div>
                                        <div className="row mx-auto mt-4" style={{

                                        }}>
                                            <ButtonGroup className="mt-1 flex-wrap">
                                                {info.paymentMethods.map(method => (
                                                    <Button variant={paymentMethod == method ? "danger" : "secondary"}
                                                        className="m-1"
                                                        onClick={() => setPaymentMethod(method)} >{method}</Button>)
                                                )}
                                            </ButtonGroup>
                                            <div className="text-center mt-5">
                                                <h1>Not Ekle</h1>
                                                <textarea
                                                    style={{ height: '200%', fontSize: 20, width: '95%' }}
                                                    ref={notRef}>
                                                </textarea>
                                            </div>
                                        </div>

                                        <button
                                            className="btn btn-danger"
                                            disabled={buttonDisabled}
                                            style={{
                                                position: 'absolute',
                                                left: 10,
                                                bottom: 0,
                                                width: '15%',
                                            }}
                                            onClick={() => {
                                                if (cart?.length > 0) giveOrder();
                                                select(null);
                                                setSelectedItems([]);
                                                setSelectedItem(null);
                                                setOpenOptions(false);
                                                setCheckedOptions([]);
                                                setPaymentMethod();
                                            }}
                                        >

                                            <FaPowerOff />
                                        </button>
                                    </>

                                </div>
                                <div className="col-3"
                                    style={{
                                        position: "relative",
                                    }}
                                >
                                    {/*<div className="not-ekle">
                  <p>Not Ekle</p>""
                  <div className="input-group" id="meal-note">
                    <textarea
                      className="form-control"
                      aria-label="With textarea"
                      ref={note}
                      style={{
                        borderRadius: 10,
                        height: "3%",
                      }}
                    ></textarea>
                  </div>
                    </div>*/}

                                    <p
                                        style={{ fontWeight: "bold" }}
                                        className="text-center mt-3"
                                    >
                                        Adisyon
                                    </p>
                                    <ul className="list-group adisyon">
                                        {cart &&
                                            cart?.length > 0 &&
                                            cart?.map((s) => (
                                                <li
                                                    className="list-group-item"
                                                    style={{
                                                        cursor: "pointer",
                                                        fontWeight: "bold",
                                                    }}
                                                    onClick={() => {
                                                        sepettenCikar(s);
                                                    }}
                                                >
                                                    <div className="row">
                                                        <div className="col-10">
                                                            <span>
                                                                {s.quantity} x
                                                                {(s.portion != null ? s.portion.name : " ") +
                                                                    " " +
                                                                    menuObj[s.meal].name}
                                                            </span>
                                                            <p style={{ color: "#727272" }}>
                                                                {s.checkedOptions &&
                                                                    s.checkedOptions
                                                                        .map((opt) => opt.name)
                                                                        .join(",")}
                                                            </p>
                                                        </div>
                                                        <div className="col-2">
                                                            <span style={{ position: "absolute", right: 10 }}>
                                                                {(parseFloat(s.total) + parseFloat(s.optPrice)) * s.quantity} TL
                                                            </span>
                                                        </div>
                                                    </div>
                                                </li>
                                            ))}
                                        {cart.length > 0 && (
                                            <p
                                                style={{
                                                    textAlign: "right",
                                                    fontSize: "1.5vw",
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                Sepet Toplam: {calculateToplamSepet()} ₺
                                            </p>
                                        )}

                                        {order &&
                                            order
                                                ?.sort((a, b) => {
                                                    if (a.time >= b.time) return -1;
                                                    else return 1;
                                                })
                                                .map((o) => (
                                                    <div>
                                                        <span
                                                            style={{
                                                                textAlign: "left",
                                                                fontSize: "0.8vw",
                                                                marginTop: "1%",
                                                            }}
                                                        >
                                                            {o.time}
                                                        </span>
                                                        <span
                                                            style={{
                                                                float: "right",
                                                                fontWeight: "bold",
                                                                fontSize: "0.8vw",
                                                                color: "red",
                                                                cursor: "pointer",
                                                            }}
                                                            onClick={() => {
                                                                setOrderToCancel(o);
                                                                openCancelMenu();
                                                            }}
                                                        >
                                                            Sipariş İptal
                                                        </span>
                                                        {o.cart.map((cart) => (
                                                            <>
                                                                <li
                                                                    className="list-group-item"
                                                                    style={{
                                                                        cursor: "pointer",
                                                                        fontWeight: "bold",
                                                                        backgroundColor: selectedItems[cart.id]
                                                                            ? "#eee"
                                                                            : "",
                                                                        border:
                                                                            cart.id == selectedItem
                                                                                ? "1px solid black"
                                                                                : "",
                                                                    }}
                                                                    onClick={() => {
                                                                        if (cart.quantity < 1) return;

                                                                        if (!selectedItems[cart.id]) {
                                                                            setSelectedItems({
                                                                                ...selectedItems,
                                                                                [cart.id]: {
                                                                                    orderId: o.id,
                                                                                    quantity: 1,
                                                                                    maxQuantity: cart.quantity,
                                                                                    mealId: cart.meal,
                                                                                    optPrice: cart.optPrice,
                                                                                },
                                                                            });
                                                                        } else {
                                                                            if (
                                                                                Object.keys(selectedItems).length == 1
                                                                            ) {
                                                                                setSelectedItems({});
                                                                                setSelectedItem(null);
                                                                                setShowItemMenu(false);
                                                                                return;
                                                                            }
                                                                            setSelectedItems({
                                                                                ...selectedItems,
                                                                                [cart.id]: null,
                                                                            });

                                                                            setSelectedItem(null);
                                                                            return;
                                                                        }

                                                                        setShowItemMenu(true);
                                                                        setSelectedItem(cart.id);
                                                                    }}
                                                                >
                                                                    <div
                                                                        className="row"
                                                                        style={{ fontSize: "0.7vw" }}
                                                                    >
                                                                        <div className="col-10">
                                                                            {cart.quantity > 0 ? (
                                                                                <>
                                                                                    <span>
                                                                                        {cart.quantity} x
                                                                                        {cart.portion ? (
                                                                                            <>
                                                                                                {cart.portion.name}
                                                                                                &nbsp; {menuObj[cart.meal].name}
                                                                                            </>
                                                                                        ) : (
                                                                                            <>
                                                                                                &nbsp; {menuObj[cart.meal].name}
                                                                                            </>
                                                                                        )}
                                                                                        <span style={{ fontSize: "1vw" }}>
                                                                                            {selectedItems[cart.id]
                                                                                                ? " (" +
                                                                                                selectedItems[cart.id]
                                                                                                    .quantity +
                                                                                                ")"
                                                                                                : null}
                                                                                        </span>
                                                                                    </span>
                                                                                    <p style={{ color: "#727272" }}>
                                                                                        {cart.checkedOptions &&
                                                                                            cart.checkedOptions
                                                                                                .map((opt) => opt.name)
                                                                                                .join(",")}
                                                                                    </p>
                                                                                    {cart.mealNote && (
                                                                                        <p style={{ color: "#727272" }}>
                                                                                            Not: {cart.mealNote}
                                                                                        </p>
                                                                                    )}
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    <span
                                                                                        style={{
                                                                                            color: "#aaa",
                                                                                            textDecoration: "line-through",
                                                                                        }}
                                                                                    >
                                                                                        {cart.paid} x
                                                                                        {(cart.portion != null ? (
                                                                                            <p>
                                                                                                {cart.portion &&
                                                                                                    cart.portion.name + " "}{" "}
                                                                                                <span
                                                                                                    style={{ fontWeight: "bold" }}
                                                                                                >
                                                                                                    {menuObj[cart.meal].name}
                                                                                                </span>
                                                                                            </p>
                                                                                        ) : (
                                                                                            " "
                                                                                        )) +
                                                                                            " " +
                                                                                            menuObj[cart.meal].name}
                                                                                        <span style={{ fontSize: "1vw" }}>
                                                                                            {selectedItems[cart.id]
                                                                                                ? " (" +
                                                                                                selectedItems[cart.id]
                                                                                                    .quantity +
                                                                                                ")"
                                                                                                : null}
                                                                                        </span>
                                                                                        <p>
                                                                                            {cart.checkedOptions &&
                                                                                                cart.checkedOptions
                                                                                                    .map((opt) => opt.name)
                                                                                                    .join(",")}
                                                                                        </p>
                                                                                    </span>
                                                                                </>
                                                                            )}
                                                                            {cart.iptalMiktari
                                                                                ? Object.keys(cart.iptal).map((ip) => {
                                                                                    return (
                                                                                        <p style={{ color: "red" }}>
                                                                                            İptal:{" "}
                                                                                            {cart.iptal[ip] +
                                                                                                " x " +
                                                                                                menuObj[cart.meal].name +
                                                                                                " "}
                                                                                            {ip}
                                                                                        </p>
                                                                                    );
                                                                                })
                                                                                : null}
                                                                        </div>
                                                                        <div className="col-2 mt-3">
                                                                            <span>
                                                                                {cart.total + cart.optPrice} TL
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            </>
                                                        ))}
                                                        {o.discountAmount &&
                                                            parseFloat(o.discountAmount) != 0 && (
                                                                <div
                                                                    style={{
                                                                        fontSize: "0.7vw",
                                                                        fontWeight: "bold",
                                                                        color: "#909090",
                                                                    }}
                                                                >
                                                                    <span>
                                                                        Kullanılan Puan, Telefon: {o.phone}
                                                                    </span>
                                                                    <span style={{ float: "right" }}>
                                                                        {" "}
                                                                        {o.discountAmount}₺
                                                                    </span>
                                                                </div>
                                                            )}
                                                    </div>
                                                ))}
                                    </ul>

                                    <div
                                        style={{
                                            textAlign: "center",
                                            position: "absolute",
                                            bottom: 0,
                                            width: "100%",
                                        }}
                                        className="mx-auto"
                                    >
                                        <p
                                            style={{
                                                fontWeight: "bold",
                                                fontSize: "2vw",
                                                textAlign: "center",
                                            }}
                                        >
                                            Toplam: <span>{calculateTotal()} ₺</span>
                                        </p>
                                    </div>
                                </div>
                                <div className="col-7">
                                    <div className="row" style={{ width: "98%", height: "100%" }}>
                                        <div
                                            className="col-3 categories mt-5"
                                            style={{
                                                gridTemplateRows:
                                                    "repeat(" +
                                                    (menu?.map((m) => m.category).length / 2 + 1) +
                                                    ",1fr)",
                                            }}
                                        >
                                            {menu &&
                                                menu.map((m) => (
                                                    <div
                                                        className="category-item"
                                                        onClick={() => posSelect(m)}
                                                        style={{ cursor: "pointer" }}
                                                    >
                                                        {m.category}
                                                    </div>
                                                ))}
                                        </div>

                                        <div className="col-9  mt-5 ">
                                            <div className="itemler">
                                                {posSelected &&
                                                    posSelected.items
                                                        .filter((i) => i.active == 1)
                                                        .map((item) => (
                                                            <>
                                                                <div
                                                                    className="card"
                                                                    onClick={() => {
                                                                        if (
                                                                            item.addOptions?.length > 0 ||
                                                                            item.removeOptions?.length > 0 ||
                                                                            item.portions?.length > 0
                                                                        ) {
                                                                            setOpenOptions(item);
                                                                            openOptionsMenu();
                                                                        } else {
                                                                            sepeteEkle(item);
                                                                        }
                                                                    }}
                                                                    style={{
                                                                        border:
                                                                            openOptions == item
                                                                                ? "1px solid red"
                                                                                : "",
                                                                    }}
                                                                >
                                                                    <div className="card-body">
                                                                        <h5 className="card-title">{item.name}</h5>
                                                                        <h5 className="card-text">
                                                                            {item.paketPrice ?? item.price} TL
                                                                        </h5>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        ))}
                                            </div>
                                            <div
                                                style={{
                                                    height: "fit-content",
                                                    position: "absolute",
                                                    bottom: "5%",
                                                }}
                                            >
                                                <div>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        aria-label="With textarea"
                                                        ref={note}
                                                        placeholder="0"
                                                        style={{
                                                            borderRadius: 10,
                                                            textAlign: "right",
                                                        }}
                                                    ></input>

                                                    <div className="numbers">
                                                        <div
                                                            className="number-item"
                                                            onClick={() => (note.current.value += "1")}
                                                        >
                                                            1
                                                        </div>
                                                        <div
                                                            className="number-item"
                                                            onClick={() => (note.current.value += "2")}
                                                        >
                                                            2
                                                        </div>
                                                        <div
                                                            className="number-item"
                                                            onClick={() => (note.current.value += "3")}
                                                        >
                                                            3
                                                        </div>
                                                        <div
                                                            className="number-item"
                                                            onClick={() => (note.current.value += "4")}
                                                        >
                                                            4
                                                        </div>
                                                        <div
                                                            className="number-item"
                                                            onClick={() => (note.current.value += "5")}
                                                        >
                                                            5
                                                        </div>
                                                        <div
                                                            className="number-item"
                                                            onClick={() => (note.current.value += "6")}
                                                        >
                                                            6
                                                        </div>
                                                        <div
                                                            className="number-item"
                                                            onClick={() => (note.current.value += "7")}
                                                        >
                                                            7
                                                        </div>
                                                        <div
                                                            className="number-item"
                                                            onClick={() => (note.current.value += "8")}
                                                        >
                                                            8
                                                        </div>
                                                        <div
                                                            className="number-item"
                                                            onClick={() => (note.current.value += "9")}
                                                        >
                                                            9
                                                        </div>
                                                        <div
                                                            className="number-item"
                                                            onClick={() => (note.current.value += "0")}
                                                        >
                                                            0
                                                        </div>
                                                        <div
                                                            className="number-item"
                                                            onClick={() =>
                                                            (note.current.value = note.current.value.slice(
                                                                0,
                                                                -1
                                                            ))
                                                            }
                                                        >
                                                            {" "}
                                                            {"<"}{" "}
                                                        </div>
                                                        <div
                                                            className="number-item"
                                                            onClick={() => (note.current.value = "")}
                                                        >
                                                            {"C"}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default Arama;
