import React from "react";

const Garson = () => {
  /*
    const getBill = () => {
    firestore
      .collection("Restaurants")
      .doc(cafe)
      .update({
        adisyon: firebase.firestore.FieldValue.arrayUnion(table),
      })
      .then(() => {
        history.push(`/menu/${cafe}/${table}`);
      });
    setBill([]);
     <a href="https://www.animatedimages.org/cat-waiters-and-servers-1631.htm">
        <img
          src="https://www.animatedimages.org/data/media/1631/animated-waiter-and-waitress-and-server-image-0033.gif"
          border="0"
          alt="animated-waiter-and-waitress-and-server-image-0033"
        />
      </a>
  };*/
  return <div></div>;
};

export default Garson;
