import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../css/footer.css";
import { toast } from "react-toastify";
import { MdRestaurantMenu, MdReceipt, MdInfo } from "react-icons/md";
import { FaStarHalfAlt } from "react-icons/fa";

const Footer = ({ cafe, table, active, menu, info }) => {
  const [selectValue, setValue] = useState();
  const [options, setOptions] = useState([]);
  const [text, setText] = useState("");
  const lang = localStorage.getItem("alka-menu-lang") || "tr";
  const handleChange = (e) => {
    const options = {
      method: "POST",
      body: JSON.stringify({ cafe, table, request: e.target.value }),
      headers: { "Content-Type": "applications/json" },
    };
    fetch(
      "https://us-central1-qr-p-94567.cloudfunctions.net/sendRequest",
      options
    );

    setValue("");
    toast.success("İsteğiniz İletildi!", {
      position: "bottom-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  useEffect(() => {
    setOptions(info.options);
    setText(info.optionsText);
  }, []);

  if (info.hasOnlyMenu) {
    return <></>;
  } else {
    return (
      <nav className="nav">
        <Link
          to={`/m/${cafe}/${table}`}
          className={active == 1 ? "nav__link nav__link--active" : "nav__link"}
        >
          <i className="nav__icon">
            <MdRestaurantMenu />
          </i>
          <span className="nav__text t-1">
            {lang == "tr" ? "Menü" : lang == "en" ? "Menu" : "قائمة الطعام"}
          </span>
        </Link>
        <Link
          to={{
            pathname: `/cart`,
            state: {
              cafe,
              table,
              info,
              menu,
            },
          }}
          className={active == 2 ? "nav__link nav__link--active" : "nav__link"}
        >
          <i className="bi bi-bag-fill nav__icon"></i>
          <span className="nav__text">
            {lang == "tr" ? "Sepet" : lang == "en" ? "Cart" : "العربة"}
          </span>
        </Link>

        <div className="selectDiv nav__link">
          <span className="selectDefault"></span>
          <select value={selectValue} onChange={handleChange}>
            <option default> {text} </option>
            {options &&
              options
                ?.sort((a, b) => {
                  if (parseFloat(a.sortOrder) < parseFloat(b.sortOrder))
                    return -1;
                  else return 1;
                })
                .map((o) => (
                  <option value={o.name}>
                    {" "}
                    {lang == "tr"
                      ? o.name
                      : lang == "en"
                      ? o.nameEn
                      : o.nameAr}{" "}
                  </option>
                ))}
          </select>
        </div>
        <Link
          to={{
            pathname: `/hesap`,
            state: {
              cafe,
              table,
              menu,
              info,
            },
          }}
          className={active == 4 ? "nav__link nav__link--active" : "nav__link"}
        >
          <i className="nav__icon">
            {" "}
            <MdReceipt />
          </i>
          <span className="nav__text">
            {" "}
            {lang == "tr" ? "Hesap" : lang == "en" ? "Bill" : "فاتورة"}
          </span>
        </Link>
        <Link
          to={{
            pathname: `/degerlendirme`,
            state: {
              cafe,
              table,
              info,
              menu,
            },
          }}
          className={active == 5 ? "nav__link nav__link--active" : "nav__link"}
        >
          <i className=" nav__icon">
            <FaStarHalfAlt />
          </i>
          <span className="nav__text">
            {lang == "tr" ? "Anket" : lang == "en" ? "Survey" : "استعراض"}
          </span>
        </Link>
      </nav>
    );
  }
};

export default Footer;

/*

  <Link
        to={`/hesap/${cafe}/${table}`}
        className={active == 2 ? "nav__link nav__link--active" : "nav__link"}
      >
        <i className="material-icons nav__icon">info</i>
        <span className="nav__text">Bilgi</span>
      </Link>
      
 <a
        href="#"
        className={active == 4 ? "nav__link nav__link--active" : "nav__link"}
      >
        <i className="material-icons nav__icon">language</i>
        <span className="nav__text">Dil</span>
      </a>
      <a
        href="#"
        className={active == 5 ? "nav__link nav__link--active" : "nav__link"}
      >
        <i className="material-icons nav__icon">payment</i>
        <span className="nav__text">Adisyon İste</span>
      </a>





        <Link
        to="/garson"
        className={
          active == 3
            ? "nav__link nav__link--active border-start border-end"
            : "nav__link border-start border-end"
        }
        onClick={() =>
          firestore
            .collection("Restaurants")
            .doc(cafe)
            .update({
              garson: firebase.firestore.FieldValue.arrayUnion(table),
            })
            .then(() => {})
        }
      >
        <i className="material-icons nav__icon">person</i>
        <span className="nav__text">Garson Çağır</span>
      </Link>
      */
