import React, { useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";

const PrintOrder = () => {
  const cafe = localStorage.getItem("name");
  const { order, table } = useLocation().state;
  const history = useHistory();
  useEffect(() => {
    if (order) {
      window.print();
      history.push("/admin/siparisler");
    }
  });

  return (
    <div
      className="ticket"
      style={{ fontFamily: "Helvetica", fontSize: 12 }}
      onFocus={() => window.close()}
    >
      <p className="centered" style={{ fontweight: "bold", fontSize: 13 }}>
        {table}
        <br />
      </p>
      <div>
        {order.cart.map((o) => {
          return (
            <>
              <h5
                className="card-title"
                style={{ textAlign: "left", fontSize: 12 }}
              >
                {o.quantity} x{" "}
                {(o.portion != null && o.portion ? o.portion.name : " ") +
                  " " +
                  o.meal.name}{" "}
              </h5>
              {o.checkedOptions.length > 0 && (
                <p>
                  Ekstralar:{" "}
                  {o.checkedOptions?.map((opt) => (
                    <>
                      {opt.name} <br />{" "}
                    </>
                  ))}
                </p>
              )}
              {o.removedOptions.length > 0 && (
                <p>
                  Çıkarılacaklar:{" "}
                  {o.removedOptions?.map((opt) => (
                    <>
                      {opt.name} <br />{" "}
                    </>
                  ))}
                </p>
              )}
              {o.mealNote && <h5 className="card-title">{o.mealNote} </h5>}
              <hr
                style={{
                  height: 2,
                  color: "black",
                  backgroundColor: "black",
                }}
              />
            </>
          );
        })}
      </div>
      {order.note && <p className="card-text">Notu: {order.note} </p>}
    </div>
  );
};

export default PrintOrder;
