import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import "./Stok.css";
import firebase, { firestore } from '../../utils/firebase';
import { v4 as uuid } from "uuid";
import { toast } from "react-toastify";
import { Hint } from 'react-autocomplete-hint';
import { FiEdit } from 'react-icons/fi';
import { Dropdown } from "react-bootstrap";

export default function Stok() {
    const history = useHistory();
    const [blurScreen, setBlurScreen] = useState(false);
    const [openMenu, setOpenMenu] = useState(false);
    const [inventory, setInventory] = useState([]);
    const [inventoryList, setInventoryList] = useState([]);
    const [search, setSearch] = useState();
    const [menu, setMenu] = useState();
    const [inventoryDetails, setInventoryDetails] = useState([]);
    const [categories, setCategories] = useState([]);
    const [updateType, setUpdateType] = useState("plus");
    const [editItem, setEditItem] = useState();
    const [filter, setFilter] = useState();
    const [menuStock, setMenuStock] = useState();
    const [selectedStock, setSelectedStock] = useState();
    const [selectedItem, setSelectedItem] = useState();
    const cafe = localStorage.getItem("name");
    let nameRef = useRef();
    let amountRef = useRef();
    let catRef = useRef();
    let unitRef = useRef();
    let initStock = useRef();
    const [refresh, setRefresh] = useState(false);
    useEffect(() => {
        firestore
            .collection(`Restaurants/${cafe}/inventory`)
            .get()
            .then(doc => {
                setInventory(doc.docs.map(d => d.data()));
                setInventoryList(doc.docs.map(d => d.data()));
                let cat = (doc.docs.map(d => d.data().category));
                setCategories(Array.from(new Set(cat)));
            })
        firestore
            .collection(`Restaurants/${cafe}/menuStock`)
            .get()
            .then(doc => {

                let items = [];
                doc.docs.forEach(d => {
                    items.push({
                        [d.id]: [
                            ...d.data().items
                        ]
                    })
                })
                setMenuStock(Object.assign({}, ...items));

            })
        firestore
            .collection("Restaurants")
            .doc(cafe)
            .onSnapshot((doc) => {
                if (doc.exists) {
                    let menu = doc.data().Menu;

                    menu.sort((a, b) => {
                        if (parseFloat(a.sortOrder) < parseFloat(b.sortOrder))
                            return -1;
                        else return 1;
                    });
                    setMenu(menu.map(m => m.items).flat());

                } else {
                    // doc.data() will be undefined in this case
                    console.log("No such document!");
                }
            });
    }, [refresh]);
    useEffect(() => {
        if (!filter) setInventoryList(inventory);
        else setInventoryList(inventory.filter(item => item.category == filter));
    }, [filter]);
    useEffect(() => {
        if (!search) setInventoryList(inventory);
        else setInventoryList(inventory.filter(item => item.name.toLowerCase().includes(search.toLowerCase())))
    }, [search]);

    const goBack = () => {
        history.push("/admin");
    };
    const OpenNewEntry = () => {
        setBlurScreen(true);
        setOpenMenu("newEntry");
    }
    const OpenStockMatching = () => {
        setBlurScreen(true);
        setOpenMenu("newStockMatching");
    }
    const openDetails = (item) => {
        setBlurScreen(true);
        setOpenMenu("details");
        firestore
            .collection(`Restaurants/${cafe}/movements`)
            .where("item", "==", item.id)
            .orderBy("date", "desc")
            .limit(50)
            .get()
            .then(doc => {
                setInventoryDetails(doc.docs.map(d => { return { ...d.data(), name: item.name } }));
            })
    }
    const openEdit = (item) => {
        setBlurScreen(true);
        setOpenMenu("edit");
        setEditItem(item);
    }
    const updateStock = () => {
        if (!amountRef.current.value || !nameRef.current.value) {
            alert("Eksik Giriş");
            return;
        }
        let id = uuid();
        let amount = parseFloat(amountRef.current.value);
        let ref = firestore
            .collection(`Restaurants/${cafe}/inventory`)
            .doc(editItem.id);

        let ref2 = firestore
            .collection(`Restaurants/${cafe}/movements`)
            .doc(id);
        ref
            .get()
            .then((doc) => {
                let data = doc.data();

                ref.update({
                    amount: updateType == "plus" ? firebase.firestore.FieldValue.increment(amount) : firebase.firestore.FieldValue.increment(amount * -1)
                })
                return data;
            })
            .then((data) => {
                ref2.set({
                    id,
                    item: editItem.id,
                    description: nameRef.current.value,
                    date: firebase.firestore.FieldValue.serverTimestamp(),
                    initial: data.amount,
                    change: amount,
                    amount: updateType == "plus" ? data.amount + amount : data.amount - amount,
                })
            })
            .then(() => {
                toast.success("Stok Güncellendi!", {
                    position: "bottom-center",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setRefresh(!refresh);
                setBlurScreen(false);
                setOpenMenu();
            })

    }
    const saveNewEntry = () => {
        if ([nameRef.current.value, unitRef, catRef.current.value, initStock.current.value].some(x => x.trim() == "" || x == null)) {
            alert("Eksik Bilgi Girişi");
            return;
        }
        let id = uuid();
        let amount = parseFloat(initStock.current.value);
        let newInventory = {
            id,
            name: nameRef.current.value,
            category: catRef.current.value,
            unit: unitRef,
            amount,
            date: firebase.firestore.FieldValue.serverTimestamp()
        };
        firestore
            .collection(`Restaurants/${cafe}/inventory`)
            .doc(id)
            .set(newInventory)
            .then(() => {
                let id2 = uuid();
                firestore
                    .collection(`Restaurants/${cafe}/movements`)
                    .doc(id2)
                    .set({
                        id: id2,
                        item: id,
                        description: 'Başlangıç Stok Miktarı',
                        date: firebase.firestore.FieldValue.serverTimestamp(),
                        initial: 0,
                        change: amount,
                        amount,
                    })
            });
        setBlurScreen(false);
        setOpenMenu();
        toast.success("Stok Eklendi!", {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        setRefresh(!refresh);
    }

    const saveNewMenuStock = () => {
        if ([initStock.current.value].some(x => x.trim() == "" || x == null) || selectedItem == null || selectedStock == null) {
            alert("Eksik Bilgi Girişi");
            return;
        }

        let amount = parseFloat(initStock.current.value);
        let newInventory = {
            stock: selectedStock.id,
            amount
        };
        let ref = firestore
            .collection(`Restaurants/${cafe}/menuStock`)
            .doc(selectedItem.id);
        ref
            .get()
            .then(doc => {
                let items = [];
                if (doc.exists && doc.data().items.length > 0) items = [...doc.data().items];
                items.push(newInventory);

                ref.set({ items: items });
            })
            .then(() => {
                let stocks = menuStock;
                if (stocks[selectedItem.id] == null) stocks[selectedItem.id] = [{ amount, stock: selectedStock.id }]
                else stocks[selectedItem.id].push({ amount, stock: selectedStock.id });
                setMenuStock(stocks);
                initStock.current.value = 0;
                unitRef.current.value = "";
                setSelectedStock();
                toast.success("Eklendi!", {
                    position: "bottom-center",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });
    }

    return (
        <div className="row " style={{ width: '100vw' }}>

            <div
                className="blurred"
                style={{ display: blurScreen ? "block" : "none" }}
            ></div>
            {openMenu == "newEntry" && (
                <div className="new-entry-menu">
                    <div className="new-entry-container">
                        <form>
                            <p className="text-center" style={{ fontWeight: 'bold', fontSize: 20 }}>Stok Ekle</p>
                            <div className="form-group">
                                <label for="exampleInputEmail1">Adı</label>
                                <input type="email" className="form-control" ref={nameRef} />
                            </div>

                            <div className="form-group">
                                <label for="exampleInputPassword1">Kategori</label>
                                <Hint options={categories}>
                                    <input type="text" className="form-control" ref={catRef} />
                                </Hint>
                            </div>
                            <div className="form-group">
                                <label for="exampleFormControlSelect1">Alış/Satış Birimi</label>
                                <select className="form-control" required onChange={(e) => unitRef = e.target.value}>
                                    <option>Birim Seçiniz</option>
                                    <option>Adet</option>
                                    <option>Gram</option>
                                    <option>Kilogram</option>
                                    <option>Litre</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <label for="exampleInputPassword1">Başlangıç Stoğu</label>
                                <input type="number" className="form-control" value={0} ref={initStock} />
                            </div>

                            <div className="mt-5 text-center">
                                <div
                                    className="btn btn-danger"
                                    style={{ cursor: "pointer", marginRight: 10 }}
                                    onClick={() => {
                                        setBlurScreen(false);
                                        setOpenMenu();
                                    }}
                                >
                                    Kapat
                                </div>
                                <div className="btn btn-primary" onClick={() => saveNewEntry()}>Kaydet</div>
                            </div>

                        </form>

                    </div>
                </div>
            )
            }
            {openMenu == "newStockMatching" && (
                <div className="stockMenu-menu">
                    <div className="stockMenu-container row">
                        <div className="col-6">
                            <form>
                                <p className="text-center" style={{ fontWeight: 'bold', fontSize: 20 }}>Stok Menü Eşleştirme</p>
                                <div className="form-group">
                                    <label for="exampleInputEmail1">Ürün Adı</label>
                                    <Dropdown>
                                        <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                                            {selectedItem?.name || "Ürün Seçiniz"}
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            {menu.map(m => {
                                                return (
                                                    <Dropdown.Item onClick={() => setSelectedItem(m)}>{m.name}</Dropdown.Item>
                                                )
                                            })}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                                <div className="form-group">
                                    <label for="exampleInputPassword1">Stok Karşılığı</label>
                                    <Dropdown>
                                        <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                                            {selectedStock?.name || "Stok Seçiniz"}
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            {inventory.map(m => {
                                                return (
                                                    <Dropdown.Item onClick={() => setSelectedStock(m)}>{m.name}</Dropdown.Item>
                                                )
                                            })}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                                <div className="form-group">
                                    <label for="exampleFormControlSelect1">Birimi</label>
                                    <input type="text" className="form-control" disabled value={selectedStock?.unit} ref={unitRef} />,
                                </div>

                                <div className="form-group">
                                    <label for="exampleInputPassword1">Miktar</label>
                                    <input type="number" className="form-control" ref={initStock} />
                                </div>

                                <div className="mt-5 text-center">
                                    <div
                                        className="btn btn-danger"
                                        style={{ cursor: "pointer", marginRight: 10 }}
                                        onClick={() => {
                                            setBlurScreen(false);
                                            setOpenMenu();
                                        }}
                                    >
                                        Kapat
                                    </div>
                                    <div className="btn btn-primary" onClick={() => saveNewMenuStock()}>Kaydet</div>
                                </div>

                            </form>
                        </div>
                        <div className="col-6">
                            <ul
                                className="list-group"
                                style={{ cursor: "pointer", fontSize: 15, fontWeight: "bold" }}
                            >
                                {menuStock && selectedItem &&
                                    menuStock[selectedItem.id]?.map((m, i) => (
                                        <li
                                            key={i}
                                            className="list-group-item  list-group-item-action"
                                        >
                                            <span style={{ fontWeight: "bold" }}>{i + 1}. </span>{" "}
                                            {inventory?.filter(x => x.id == m.stock)[0].name + " " + m.amount + " " +
                                                inventory.filter(x => x.id == m.stock)[0].unit}
                                        </li>
                                    ))}
                            </ul>

                        </div>
                    </div>
                </div>
            )
            }
            {openMenu == "details" && (
                <div className="details-menu">
                    <div className="details-container">
                        <div
                            className="btn btn-danger"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                setBlurScreen(false);
                                setOpenMenu();
                            }}
                        >
                            Kapat
                        </div>
                        <table className="table table-bordered table-hover mt-5">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Adı</th>
                                    <th scope="col">Açıklama</th>
                                    <th scope="col">İlk Miktar</th>
                                    <th scope="col">Değişim</th>
                                    <th scope="col">Miktar</th>
                                    <th scope="col">Zaman</th>
                                </tr>
                            </thead>
                            <tbody>
                                {inventoryDetails?.map((item, i) => (
                                    <tr
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <th scope="row">{i}</th>
                                        <td>{item.name}</td>
                                        <td>{item.description}</td>
                                        <td>{item.initial}</td>
                                        <td>{item.change}</td>
                                        <td>{item.amount}</td>
                                        <td>{item.date.toDate().toLocaleDateString("tr-TR") + " " + item.date.toDate().toLocaleTimeString('tr-TR')}</td>
                                    </tr>
                                ))}


                            </tbody>
                        </table>
                    </div>
                </div>
            )}
            {openMenu == "edit" && (
                <div className="edit-menu">
                    <div className="edit-container">
                        <form>
                            <p className="text-center" style={{ fontWeight: 'bold', fontSize: 20 }}>Stok Düzenle</p>
                            <div className="form-group">
                                <label>Açıklama</label>
                                <input type="email" className="form-control" ref={nameRef} />
                            </div>

                            <div className="form-group">
                                <label for="exampleInputPassword1">Miktar</label>
                                <div className={updateType == "plus" ? "btn btn-success" : "btn btn-outline-success"} onClick={() => setUpdateType("plus")} style={{ marginRight: 10 }}>+</div>
                                <div className={updateType == "minus" ? "btn btn-danger" : "btn btn-outline-danger"} onClick={() => setUpdateType("minus")}>-</div>
                                <input type="number" className="form-control mt-2" ref={amountRef} />
                            </div>
                        </form>
                        <div className="text-center mt-3">
                            <div
                                className="btn btn-danger"
                                style={{ cursor: "pointer", marginRight: 10 }}
                                onClick={() => {
                                    setBlurScreen(false);
                                    setOpenMenu();
                                }}
                            >
                                Kapat
                            </div>
                            <div
                                className="btn btn-secondary"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    updateStock();
                                }}
                            >
                                Kaydet
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div onClick={goBack}>
                <div className="btn btn-danger mt-3">
                    <i
                        className="fa fa-arrow-left"
                        aria-hidden="true"
                        style={{ width: "2vw" }}
                    ></i>
                </div>
            </div>
            <div className="w-75 mx-auto">
                <div className="btn btn-secondary" onClick={() => OpenNewEntry()}>Yeni Kayıt</div>
                <div style={{ marginLeft: 10 }} className="btn btn-danger" onClick={() => OpenStockMatching()}>Menü-Stok Eşleştirme</div>

                <div className="categories-buttons mt-5">
                    {categories?.map(category => (
                        <div className="btn btn-outline-danger category-button" onClick={() => setFilter(category)} >{category}</div>
                    ))}
                    <div className="btn btn-outline-secondary category-button" onClick={() => setFilter()} >Tümü</div>
                </div>
                <div className="input-group mt-3 w-50 mx-auto">
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1">Ara</span>
                    </div>
                    <input type="text" className="form-control" value={search} onChange={e => setSearch(e.target.value)} aria-label="Username"
                        aria-describedby="basic-addon1" />
                </div>
                <table className="table table-bordered table-hover mt-5">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Adı</th>
                            <th scope="col">Kategori</th>
                            <th scope="col">Birim</th>
                            <th scope="col">Adet</th>
                            <th scope="col" style={{ width: '4%' }}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {inventoryList?.map((item, i) => (
                            <tr
                                style={{ cursor: 'pointer' }}
                                onClick={() => openDetails(item)}
                            >
                                <th scope="row">{i}</th>
                                <td>{item.name}</td>
                                <td>{item.category}</td>
                                <td>{item.unit}</td>
                                <td>{item.amount}</td>
                                <td onClick={(e) => { e.stopPropagation(); openEdit(item); }} ><FiEdit size={20} /> </td>
                            </tr>
                        ))}

                    </tbody>
                </table>
            </div>

        </div>
    );
}