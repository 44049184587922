import { Button, Spinner } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import "../css/main.css";
import { AiFillInstagram, AiFillFacebook } from "react-icons/ai";

const Main = () => {
  const { cafe, table } = useParams();
  const [info, setInfo] = useState();
  const [masa, setMasa] = useState();
  const [loaded, setLoaded] = useState(false);
  const history = useHistory();
  const [showPopup, setShowPopup] = useState(false);
  const [discount, setDiscount] = useState();
  const phoneNumber = useRef();
  const [lang, setLanguage] = useState(localStorage.getItem("alka-menu-lang") || "tr");

  const routeChange = () => {
    history.push(`/m/${cafe}/${table}`);
  };
  useEffect(() => {
    localStorage.setItem("alka-menu-lang", lang);

  }, [lang]);
  useEffect(() => {
    if (table) {
      const options = {
        method: "POST",
        body: JSON.stringify({ cafe, table }),
        headers: { "Content-Type": "applications/json" },
      };
      fetch("https://us-central1-qr-p-94567.cloudfunctions.net/getInfo", options)
        .then((res) => res.json())
        .then(({ info, table }) => {
          setInfo(info); localStorage.setItem("info", JSON.stringify(info)); setMasa(table);
          if (info?.menuType == 2) {
            history.push(`/menu1/${cafe}/${table.id}`)
          }
        });
    }
  }, []);

  const checkUser = async () => {
    let phone = phoneNumber.current.value.replace(/\D/g, "").replace(/\s+/g, "");
    if (phone.charAt(0) != "0") {
      phone = "0" + phone;
    }
    const options = {
      method: "POST",
      body: JSON.stringify({ phone, cafe }),
      headers: { "Content-Type": "applications/json" },
    };
    const { discount } = await (
      await fetch(
        "https://us-central1-qr-p-94567.cloudfunctions.net/getAmount",
        options
      )
    ).json();
    setDiscount(discount);
  };

  return (
    <div style={{ backgroundColor: info?.backgroundColor, minHeight: '100vh', color: info?.textColor, marginTop: 30 }}>
      <div className={loaded ? "a text-center main mate" : "d-none"}>
        <p className="" style={{ fontSize: 30 }}>
          {info?.name}
        </p>
        <p className="" style={{ fontSize: 22 }}>
          {masa?.name}
        </p>
        <img
          src={info?.logo}
          onLoad={() => setLoaded(true)}
          style={{ maxHeight: "25vh", width: "auto", marginBottom: 20 }}
        />
        <p className="mt-1">
          {lang == "tr"
            ? info?.welcomeText
            : lang == "en"
              ? info?.welcomeTextEn
              : info?.welcomeTextAr}</p>
        <div className="button dropdown" style={{ boxShadow: info?.boxShadow }}>
          <select className="form__select" style={{ color: info?.textColor }}
            value={lang}
            onChange={(e) => { setLanguage(e.target.value); }}>
            <option value="tr" data-toggle="tab" >
              Türkçe
            </option>
            <option value="en" data-toggle="tab">
              English
            </option>
            <option value="ar" data-toggle="tab">
              عربي
            </option>
          </select>
        </div>
        <p style={{ fontSize: 25, marginTop: 20 }}> {info?.campaignInfo} </p>
        {info?.active ? (
          <div className="row mx-1">
            <div className="col">
              <Button
                style={{ backgroundColor: info?.color }}
                className="menuButton"
                onClick={routeChange}
              >
                {lang == "tr"
                  ? "Menü"
                  : lang == "en"
                    ? "Menu"
                    : "قائمة الطعام"}
              </Button>


            </div>
            {info?.hasUserSystem && (
              <div className="col">
                <Button
                  className="menuButton btn-secondary"
                  onClick={() => {
                    setShowPopup(true);
                  }}
                >
                  Kampanya Sorgula
                </Button>
              </div>
            )}
          </div>) : (
          <h2>Restoran şu anda hizmet verememektedir.</h2>
        )}

        {(info?.instagram || info?.facebook) && (
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: 40 }}>
            {info.instagram && info.instagram.trim() != "" && (<a href={info.instagram}><AiFillInstagram size={50} color={info?.iconColor || "black"} /></a>)}
            {info.facebook && info.facebook.trim() != "" && (<a href={info.facebook}><AiFillFacebook size={50} color={info?.iconColor || "black"} /></a>)}
          </div>
        )}


        <div className={showPopup ? "overlay visible" : "overlay"}>
          <div className="popup">
            <div
              className="close"
              onClick={(e) => {
                setShowPopup(false);
                setDiscount();
              }}
            >
              &times;
            </div>
            <div className="content">
              <form>
                <p style={{ textAlign: "center", fontWeight: "bold" }}>
                  {info?.campaignQuery}
                </p>

                {discount == null ? (
                  <>
                    <div className="form-group">
                      <label for="exampleFormControlInput1">
                        Telefon Numarası
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        ref={phoneNumber}
                      />
                    </div>
                    <div className="row">
                      <div
                        className="btn w-50 mx-auto mt-5"
                        style={{ backgroundColor: info?.color }}
                        onClick={() => {
                          checkUser();
                        }}
                      >
                        Sorgula
                      </div>
                    </div>
                  </>
                ) : (
                  <div>
                    {info?.campaignOrderCount}:{" "}
                    {parseFloat(discount).toFixed(2)}
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>

      <div className={loaded ? "d-none" : "spinner-container"}>
        <Spinner animation="border" role="status" className="spinner">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
      <footer style={{
        position: 'fixed', bottom: 0, marginTop: 10, width: '100vw',
        backgroundColor: info?.footerColor, color: info?.textColor
      }} >
        <div className="container text-center py-2">
          <small>@{new Date().getFullYear()} Dba Teknoloji Yazılım</small>
        </div>
      </footer>
    </div>
  );
};

export default Main;

/*
 <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "65vw",
          margin: "0 auto",
          marginTop: "20%",
        }}
      >
        <Button
          style={{
            height: "7vh",
            background: "rgb(250,250,250)",
            color: "black",
            fontWeight: 300,
          }}
          variant="outline-success"
        >
          <img
            src="https://www.hareketligifler.net/data/media/771/ingiltere-bayragi-hareketli-resim-0004.gif"
            border="0"
            style={{ width: 40, float: "left" }}
            alt="ingiltere-bayragi-hareketli-resim-0004"
          />
          English
        </Button>
        <Link to="/menu/nar/asd123">
          <Button
            style={{
              height: "7vh",
              width: "65vw",
              background: "rgb(250,250,250)",
              color: "black",
              fontWeight: 300,
            }}
            variant="outline-success"
          >
            <img
              src="https://www.hareketligifler.net/data/media/903/turkiye-bayragi-hareketli-resim-0001.gif"
              style={{ width: 40, float: "left" }}
              border="0"
              alt="turkiye-bayragi-hareketli-resim-0001"
            />
            Türkçe
          </Button>
        </Link>
        <Button
          style={{
            height: "7vh",
            background: "rgb(250,250,250)",
            color: "black",
            fontWeight: 300,
          }}
          variant="outline-success"
        >
          <img
            src="https://www.hareketligifler.net/data/media/869/suudi-arabistan-bayragi-hareketli-resim-0001.gif"
            style={{ width: 40, float: "left" }}
            border="0"
            alt="suudi-arabistan-bayragi-hareketli-resim-0001"
          />
          عربي
        </Button>
      </div>


*/
