import React, { createContext, useEffect, useRef, useState } from "react";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import routes, { routesWithUserSystem } from "../utils/routes.js";
import styles from "../components/assets/jss/material-dashboard-react/layouts/adminStyle.js";
import { auth, firestore } from "../utils/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { useReactToPrint } from "react-to-print";
import AutoPrint from "../pages/AutoPrint.jsx";
import Audio from "../components/assets/notification.mp3";
import { toast } from "react-toastify";
var _info = localStorage.getItem("info");

if (_info == "undefined") {
  localStorage.clear("info");
  _info = null;
}
const info = JSON.parse(_info);


const useStyles = makeStyles(styles);
export const AdminContext = createContext();


export default function Admin({ ...rest }) {
  // styles
  const classes = useStyles();
  const mainPanel = React.createRef();
  const [cafe, setCafe] = useState("");
  const history = useHistory();
  const [user, loading, error] = useAuthState(auth);
  const [orders, setOrders] = useState([]);
  const [paketOrders, setPaketOrders] = useState([]);
  const [tables, setTables] = useState([]);
  const [tablesObj, setTablesObj] = useState([]);
  const [menu, setMenu] = useState(null);
  const [menuObj, setMenuObj] = useState(null);
  const isMainPanel =
    info?.multiPanel == null ? true :
      (localStorage.getItem("mainPanel") == "Y");

  useEffect(() => {
    if (!loading && !user) {
      history.push("/login");
    }
  }, [mainPanel]);

  const goToAdmin = () => {
    history.push("/adminpanel");
  };
  const getLastOrder = () => {
    let order = orders.sort((a, b) => {
      if (a.time < b.time) return -1;
      else return 1;
    })[0];
    return order;
  };


  const switchRoutes = (
    <Switch>
      {info?.hasUserSystem
        ? routesWithUserSystem.map((prop, key) => {
          if (prop.layout === "/admin") {
            return (
              <Route
                path={prop.layout + prop.path}
                render={() => (
                  <AdminContext.Provider value={{ orders, paketOrders, tables, menu, menuObj, tablesObj, isMainPanel }}>
                    <prop.component />
                  </AdminContext.Provider>
                )}
                key={key}
              />
            );
          }
          return null;
        })
        : routes?.map((prop, key) => {
          if (prop.layout === "/admin") {
            return (
              <Route
                path={prop.layout + prop.path}
                render={() => (
                  <AdminContext.Provider value={{ orders, paketOrders, tables, menu, menuObj, isMainPanel, tablesObj }}>
                    <prop.component />
                  </AdminContext.Provider>
                )}
                key={key}
              />
            );
          }
          return null;
        })}
      <Redirect from="/admin" to="/admin/dashboard" />
    </Switch>
  );
  const printRef = useRef();
  const [orderToPrint, setOrderToPrint] = useState(null);
  const printData = useReactToPrint({
    content: () => printRef.current,
    onAfterPrint: () => setOrderToPrint(null),
  });

  useEffect(() => {
    if (orderToPrint && info?.autoPrint) printData();
  }, [orderToPrint]);
  useEffect(() => {
    let name;
    auth.onAuthStateChanged((user) => {
      if (user) {
        firestore
          .collection("Admin")
          .doc(user.email)
          .get()
          .then((doc) => {
            name = doc.data().restaurant;

            setCafe(name);
            localStorage.setItem("name", doc.data().restaurant);
            if (doc.data().type == "Admin") goToAdmin();
          })
          .then(() => {
            let ref = firestore.collection(`Restaurants/${name}/info`);
            ref
              .doc("tables")
              .get()
              .then((doc) => {
                if (doc.exists) {
                  let masa = Object.entries(doc.data()).sort((a, b) =>
                    parseInt(a[1].sortOrder) > parseInt(b[1].sortOrder) ? 1 : -1
                  );
                  setTables(masa);
                  let tables = Object.assign({}, doc.data());
                  setTablesObj(tables);
                  return tables;
                } else {
                  // doc.data() will be undefined in this case
                  console.log("No such document!");
                }
              })
              .then((tables) => {
                var MenuOBJ;
                firestore
                  .collection(`Restaurants`)
                  .doc(name)
                  .onSnapshot((doc) => {
                    let getMenu = doc.data().Menu;
                    getMenu.sort((a, b) => {
                      if (a.sortOrder < b.sortOrder) return -1;
                      else return 1;
                    });
                    getMenu = getMenu.filter((m) => m.active == 1);
                    setMenu(getMenu);
                    MenuOBJ = Object.assign({}, ...(getMenu.map(m => m.items.map(item => ({ [item.id]: item }))).flat()));
                    setMenuObj(Object.assign({}, ...(getMenu.map(m => m.items.map(item => ({ [item.id]: item }))).flat())));
                  });

                firestore
                  .collection(`Restaurants/${name}/requests`)
                  .where("seen", "==", false)
                  .onSnapshot(doc => {
                    doc.docChanges().forEach((change) => {
                      if (change.type === "added") {
                        firestore
                          .collection(`Restaurants/${name}/requests`).doc(change.doc.id).update({ seen: true });
                        let newRequest = change.doc.data();
                        document.getElementsByClassName("audio-element")[0].play();
                        toast.success(tables[newRequest.table].name + ": " + newRequest.request + " İsteği", {
                          position: "bottom-center",
                          autoClose: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                        });
                      }
                    })
                  });

                firestore
                  .collection(`Restaurants/${name}/orders`)
                  .where("status", "==", 0)
                  .onSnapshot((doc) => {
                    setOrders(doc.docs.map((d) => d.data()));

                    doc.docChanges().forEach((change) => {
                      if (change.type === "added") {
                        try {
                          if (change.doc.data().seen) return;

                          let newOrder = change.doc.data();
                          if (isMainPanel) {
                            var count = newOrder.cart.filter(x => MenuOBJ[x.meal].noPrint == null || MenuOBJ[x.meal].noPrint == false).length;
                            if (count > 0) {
                              setOrderToPrint(newOrder);
                              document.getElementsByClassName("audio-element")[0].play();
                            }
                            firestore
                              .collection(`Restaurants/${name}/orders`).doc(change.doc.id).set({ seen: true }, { merge: true });

                            if (newOrder.noNotification || info.noNotification) return;
                            toast.success("Gelen Sipariş: " + tables[newOrder.table].name, {
                              position: "bottom-center",
                              autoClose: false,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                              progress: undefined,
                            });
                          }
                        } catch (err) {
                          console.error(err);
                        }
                      }
                    });
                  });
                firestore
                  .collection(`Restaurants/${name}/paket-orders`)
                  .onSnapshot((doc) => {

                    setPaketOrders(doc.docs.map((d) => d.data()));

                    doc.docChanges().forEach((change) => {
                      if (change.type === "added") {
                        try {
                          if (change.doc.data().seen) return;
                          firestore
                            .collection(`Restaurants/${name}/paket-orders`).doc(change.doc.id).set({ seen: true }, { merge: true });
                          let newOrder = change.doc.data();
                          if (isMainPanel) {
                            setOrderToPrint(newOrder); document.getElementsByClassName("audio-element")[0].play();
                          }

                          if (newOrder.noNotification) return;


                          if (newOrder.paket) {
                            toast.info("Gelen Paket Siparişi: " + newOrder.name, {
                              position: "bottom-center",
                              autoClose: false,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                              progress: undefined,
                            });
                          }
                          else {
                            toast.success("Gelen Sipariş: " + tables[newOrder.table].name, {
                              position: "bottom-center",
                              autoClose: false,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                              progress: undefined,
                            });
                          }

                        } catch (err) {
                          console.error(err);
                        }
                      }
                    });
                  });
              });
            firestore
              .collection(`Restaurants/${name}/info`)
              .doc("info")
              .get()
              .then((doc) => {
                if (doc.exists) {
                  localStorage.setItem("info", [JSON.stringify(doc.data())]);
                } else {
                  // doc.data() will be undefined in this case
                  console.log("No such document!");
                }
              });
            firestore
              .collection(`Restaurants/${name}/orders`)
              .where("status", "==", 0)
              .get()
              .then((doc) => {
                setOrders(doc.docs.map((d) => d.data()));
              });

          });
      }
    });

  }, []);

  return (
    user && (
      <div className={classes.wrapper}>
        {/*<Sidebar
          routes={info?.hasUserSystem ? dashboardRoutes1 : routes}
          logoText={cafe}
          logo={logo}
          image={image}
          handleDrawerToggle={handleDrawerToggle}
          open={mobileOpen}
          color={color}
          {...rest}
        />*/}
        <div style={{ display: "none" }}>
          <AutoPrint
            price={false}
            ref={printRef}
            order={orderToPrint}
            table={tablesObj[orderToPrint?.table]}
            menuObj={menuObj}
            tablesObj={tablesObj}
          />
        </div>
        <div className={classes.mainPanel} ref={mainPanel}>
          {/* On the /maps route we want the map to be on full screen -
         this is not possible if the content and conatiner classes are present 
         because they have some paddings which would make the map smaller */}
          {switchRoutes}
        </div>
        <audio className="audio-element">
          <source src={Audio}></source>
        </audio>
      </div>
    )
  );
}
