import React, { useState, useEffect } from "react";
import { firestore } from "../../utils/firebase";
import { v4 as uuid } from "uuid";
import QRCode from "qrcode.react";
import { useHistory } from "react-router-dom";

const TableSettings = () => {
  const [tables, setTables] = useState();
  const [categories, setCategories] = useState();
  const cafe = localStorage.getItem("name");

  const [selected, Select] = useState();
  const [name, setName] = useState();
  const [newTable, setNewTable] = useState(false);
  const [sortOrder, setSortOrder] = useState();
  const history = useHistory();
  useEffect(() => {
    setName(selected?.name);
    setSortOrder(selected?.sortOrder);
    setNewTable(false);
  }, [selected]);

  useEffect(() => {
    firestore
      .collection(`Restaurants/${cafe}/info`)
      .doc("info")
      .get()
      .then((doc) => {
        setCategories(doc.data().tableCategories);
      });
  }, []);
  useEffect(() => {
    let ref = firestore.collection(`Restaurants/${cafe}/info`);

    ref.doc("tables").onSnapshot((doc) => {
      if (doc.exists) {
        let data = Object.entries(doc.data()).sort((a, b) =>
          parseFloat(a[1].sortOrder) > parseFloat(b[1].sortOrder) ? 1 : -1
        );
        setTables(data);
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }
    });
  }, [cafe]);

  const createTable = () => {
    let id = uuid().toString().substring(1, 10);
    let new_tables = Object.fromEntries(tables);
    new_tables[id] = {
      id,
      name,
      sortOrder: parseFloat(sortOrder),
      active: true,
      status: false,
    };
    firestore
      .collection(`Restaurants/${cafe}/info`)
      .doc("tables")
      .set(new_tables);
  };
  const updateTable = () => {
    let new_tables = Object.fromEntries(tables);
    new_tables[selected.id].name = name;
    new_tables[selected.id].sortOrder = sortOrder;
    if (selectedCategory != null)
      new_tables[selected.id].category = selectedCategory;
    firestore
      .collection(`Restaurants/${cafe}/info`)
      .doc("tables")
      .set(new_tables);

    Select();
  };
  const deleteTable = () => {
    let new_tables = Object.fromEntries(
      tables.filter((o) => o[1].id != selected.id)
    );
    firestore
      .collection(`Restaurants/${cafe}/info`)
      .doc("tables")
      .set(new_tables);

    Select();
  };
  const downloadQR = () => {
    const canvas = document.getElementById("qr");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = "123456.png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };
  const goBack = () => {
    history.push("/admin");
  };

  const [selectedCategory, setSelectedCategory] = useState(selected?.category);

  const handleSelectChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  return (
    <div className="row">
      <div className="col-3">
        <div>
          <div className="btn btn-danger mt-3" onClick={goBack}>
            <i
              className="fa fa-arrow-left"
              aria-hidden="true"
              style={{ width: "2vw" }}
            ></i>
          </div>
        </div>
        <ul
          className="list-group mt-3"
          style={{ cursor: "pointer", fontSize: 15, fontWeight: "bold" }}
        >
          {tables?.map((table, i) => (
            <li
              key={i}
              className="list-group-item  list-group-item-action"
              onClick={() => {
                Select(table[1]);
              }}
            >
              <span style={{ fontWeight: "bold" }}>{i + 1}. </span>{" "}
              {table[1].name}
            </li>
          ))}
        </ul>
        <div className="row">
          <div
            className="btn btn-secondary mt-3 w-50 mx-auto"
            onClick={() => {
              setNewTable(true);
              setName();
              setSortOrder();
            }}
          >
            Yeni Masa
          </div>
        </div>
      </div>

      <div className="col-3">
        {selected && !newTable && (
          <form>
            <p style={{ textAlign: "center", fontWeight: "bold" }}></p>
            <div className="form-group">
              <label for="exampleFormControlInput1">Adı</label>
              <input
                type="text"
                className="form-control"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label for="exampleFormControlInput1">Sıralama</label>
              <input
                type="text"
                className="form-control"
                value={sortOrder}
                onChange={(e) => setSortOrder(e.target.value)}
              />
            </div>
            {categories && (
              <div className="form-group">
                <label for="exampleFormControlInput1">Masa Kategorisi</label>
                <select
                  class="form-select"
                  aria-label="Default select example"
                  onChange={handleSelectChange}
                >
                  <option value="Default" selected>
                    Default
                  </option>
                  {categories.map((c) => {
                    if (selected.category == c) {
                      return (
                        <option value={c} selected>
                          {c}
                        </option>
                      );
                    } else {
                      return <option value={c}>{c}</option>;
                    }
                  })}
                </select>
              </div>
            )}
            <div className="form-group">
              <label for="exampleFormControlInput1">Kategori</label>
              <input
                type="text"
                className="form-control"
                value={sortOrder}
                onChange={(e) => setSortOrder(e.target.value)}
              />
            </div>

            <div className="row">
              <div
                className="btn btn-secondary w-50 mx-auto mt-5"
                onClick={() => {
                  updateTable();
                }}
              >
                Kaydet
              </div>
              <div
                className="btn btn-danger w-25 mx-auto mt-5"
                onClick={() => {
                  deleteTable();
                }}
              >
                Sil
              </div>
            </div>
          </form>
        )}
        {newTable && (
          <form>
            <p style={{ textAlign: "center", fontWeight: "bold" }}>
              Yeni Masa Oluştur
            </p>
            <div className="form-group">
              <label for="exampleFormControlInput1">Adı</label>
              <input
                type="text"
                className="form-control"
                value={name}
                placeholder="Masa Adı"
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label for="exampleFormControlInput1">Sıralama</label>
              <input
                type="text"
                className="form-control"
                value={sortOrder}
                placeholder="Paneldeki Sıralaması"
                onChange={(e) => setSortOrder(e.target.value)}
              />
            </div>

            <div className="row">
              <div
                className="btn btn-primary w-25 mx-auto mt-5"
                onClick={() => {
                  createTable();
                }}
              >
                Kaydet
              </div>
            </div>
          </form>
        )}
      </div>

      <div className="col-3">
        {selected && (
          <div>
            <QRCode
              id="qr"
              value={`https://alka.dbateknoloji.com/menu/${cafe}/${selected.id}`}
              size={290}
              level={"H"}
              includeMargin={true}
            />
            <div
              className="btn btn-outline-secondary"
              style={{ marginLeft: "30%" }}
              onClick={downloadQR}
            >
              QR Kodu indir
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TableSettings;
