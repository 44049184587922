import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import "./Muhasebe.css";
import firebase, { firestore } from '../../utils/firebase';
import { v4 as uuid } from "uuid";
import { toast } from "react-toastify";
import { Hint } from 'react-autocomplete-hint';
import { FiEdit } from 'react-icons/fi';
import { DayPicker } from 'react-day-picker';
import tr from 'date-fns/locale/tr';

export default function Muhasebe() {
    const history = useHistory();
    const [blurScreen, setBlurScreen] = useState(false);
    const [openMenu, setOpenMenu] = useState(false);
    const [inventory, setInventory] = useState([]);
    const [inventoryList, setInventoryList] = useState([]);
    const [search, setSearch] = useState();
    const [inventoryDetails, setInventoryDetails] = useState([]);
    const [categories, setCategories] = useState([]);
    const [updateType, setUpdateType] = useState("plus");
    const [editItem, setEditItem] = useState();
    const [filter, setFilter] = useState();
    const cafe = localStorage.getItem("name");
    let nameRef = useRef();
    let amountRef = useRef();
    let dateRef = useRef();
    let catRef = useRef();
    let unitRef = "";
    let initStock = useRef();
    const [refresh, setRefresh] = useState(false);
    const [selectedDay, setSelectedDay] = useState(new Date().toLocaleDateString());

    useEffect(() => {
        firestore
            .collection(`Restaurants/${cafe}/expense/${selectedDay}/data`)
            .get()
            .then(doc => {

                firestore
                    .collection(
                        `Restaurants/${cafe}/sales`
                    )
                    .doc(selectedDay)
                    .get()
                    .then(d => {
                        if (d.exists) {
                            setInventory([...doc.docs.map(d => d.data()),
                            { category: "Sipariş", name: "Sipariş", amount: (d.data().nakit || 0 + d.data().kredi) || 0 },
                            { category: "Paket Sipariş", name: "Paket Sipariş", amount: d.data().paket || 0 },
                            { category: "Sipariş", name: "Arama", amount: d.data().arama || 0 }
                            ]);
                            setInventoryList([...doc.docs.map(d => d.data()), {
                                category: "Sipariş", name: "Masa Sipariş", amount: (d.data().nakit || 0 + d.data().kredi) || 0
                            },
                            { category: "Sipariş", name: "Paket Sipariş", amount: d.data().paket || 0 },
                            { category: "Sipariş", name: "Arama", amount: d.data().arama || 0 }
                            ]);
                        }
                        else {
                            setInventory(doc.docs.map(d => d.data()));
                            setInventoryList(doc.docs.map(d => d.data()));
                        }
                    })

                let cat = (doc.docs.map(d => d.data().category));
                setCategories(Array.from(new Set(cat)));
            })
    }, [refresh, selectedDay]);

    useEffect(() => {
        if (!filter) setInventoryList(inventory);
        else setInventoryList(inventory.filter(item => item.category == filter));
    }, [filter]);
    useEffect(() => {
        if (!search) setInventoryList(inventory);
        else setInventoryList(inventory.filter(item => item.name.toLowerCase().includes(search.toLowerCase())))
    }, [search]);

    const goBack = () => {
        history.push("/admin");
    };
    const OpenNewIncome = () => {
        setBlurScreen(true);
        setOpenMenu("gelir");
    }
    const OpenNewExpense = () => {
        setBlurScreen(true);
        setOpenMenu("gider");
    }

    const saveNewEntry = (outcome = false) => {
        if ([nameRef.current.value, amountRef.current.value, catRef.current.value].some(x => x.trim() == "" || x == null)) {
            alert("Eksik Bilgi Girişi");
            return;
        }
        let id = uuid();
        let newExpense = {
            id,
            name: nameRef.current.value,
            category: catRef.current.value,
            amount: amountRef.current.value,
            time: new Date().toLocaleTimeString(),
            outcome
        };
        firestore
            .collection(`Restaurants/${cafe}/expense/${selectedDay}/data`)
            .doc(id)
            .set(newExpense);
        setBlurScreen(false);
        setOpenMenu();
        toast.success("Gelir Eklendi!", {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        setRefresh(!refresh);
    }
    const totalIncome = () => {
        let total = 0.0;
        inventory.filter(x => x.outcome != true).map(x => {
            total += parseFloat(x.amount);
        });
        return total;
    }
    const totalOutcome = () => {
        let total = 0.0;
        inventory.filter(x => x.outcome == true).map(x => {
            total += parseFloat(x.amount);
        });
        return total;
    }
    const net = () => {
        return totalIncome() - totalOutcome();
    }

    return (
        <div className="row " style={{ width: '100vw' }}>

            <div
                className="blurred"
                style={{ display: blurScreen ? "block" : "none" }}
            ></div>
            {openMenu == "gelir" && (
                <div className="new-entry-menu">
                    <div className="new-entry-container">
                        <form>
                            <p className="text-center" style={{ fontWeight: 'bold', fontSize: 20 }}>Gelir Ekle</p>


                            <div className="form-group">
                                <label for="exampleInputPassword1">Kategori</label>
                                <Hint options={categories}>
                                    <input type="text" className="form-control" ref={catRef} />
                                </Hint>
                            </div>

                            <div className="form-group">
                                <label for="exampleInputEmail1">Açıklama</label>
                                <input type="email" className="form-control" ref={nameRef} />
                            </div>

                            <div className="form-group">
                                <label for="exampleInputEmail1">Miktar</label>
                                <input type="email" className="form-control" ref={amountRef} />
                            </div>

                            <div className="form-group" onClick={() => {
                                var val = dateRef.current.style.display;
                                dateRef.current.style.display = val == "flex" ? "none" : "flex";
                            }}>
                                <label for="exampleInputEmail1">Tarih</label>
                                <input type="text" disabled style={{ cursor: 'pointer' }} className="form-control" value={selectedDay} />
                            </div>

                            <div
                                style={{ display: 'none', width: '100%', justifyContent: 'center' }}
                                ref={dateRef}>
                                <DayPicker
                                    mode="single"
                                    locale={tr}
                                    selected={selectedDay}
                                    onSelect={(e) => setSelectedDay(new Date(e).toLocaleDateString())}
                                />
                            </div>
                            <div className="mt-5 text-center">
                                <div
                                    className="btn btn-danger"
                                    style={{ cursor: "pointer", marginRight: 10 }}
                                    onClick={() => {
                                        setBlurScreen(false);
                                        setOpenMenu();
                                    }}
                                >
                                    Kapat
                                </div>
                                <div className="btn btn-primary" onClick={() => saveNewEntry()}>Kaydet</div>
                            </div>

                        </form>

                    </div>
                </div>
            )
            }
            {openMenu == "gider" && (
                <div className="new-entry-menu">
                    <div className="new-entry-container">
                        <form>
                            <p className="text-center" style={{ fontWeight: 'bold', fontSize: 20 }}>Gider Ekle</p>

                            <div className="form-group">
                                <label for="exampleInputPassword1">Kategori</label>
                                <Hint options={categories}>
                                    <input type="text" className="form-control" ref={catRef} />
                                </Hint>
                            </div>

                            <div className="form-group">
                                <label for="exampleInputEmail1">Açıklama</label>
                                <input type="email" className="form-control" ref={nameRef} />
                            </div>
                            <div className="form-group">
                                <label for="exampleInputEmail1">Miktar</label>
                                <input type="email" className="form-control" ref={amountRef} />
                            </div>
                            <div className="form-group" onClick={() => {
                                var val = dateRef.current.style.display;
                                dateRef.current.style.display = val == "flex" ? "none" : "flex";
                            }}>
                                <label for="exampleInputEmail1">Tarih</label>
                                <input type="text" disabled style={{ cursor: 'pointer' }} className="form-control" value={selectedDay} />
                            </div>

                            <div
                                style={{ display: 'none', width: '100%', justifyContent: 'center' }}
                                ref={dateRef}>
                                <DayPicker
                                    mode="single"
                                    locale={tr}
                                    selected={selectedDay}
                                    onSelect={(e) => setSelectedDay(new Date(e).toLocaleDateString())}
                                />
                            </div>
                            <div className="mt-5 text-center">
                                <div
                                    className="btn btn-danger"
                                    style={{ cursor: "pointer", marginRight: 10 }}
                                    onClick={() => {
                                        setBlurScreen(false);
                                        setOpenMenu();
                                    }}
                                >
                                    Kapat
                                </div>
                                <div className="btn btn-primary" onClick={() => saveNewEntry(true)}>Kaydet</div>
                            </div>

                        </form>

                    </div>
                </div>
            )
            }

            <div onClick={goBack}>
                <div className="btn btn-danger mt-3">
                    <i
                        className="fa fa-arrow-left"
                        aria-hidden="true"
                        style={{ width: "2vw" }}
                    ></i>
                </div>
            </div>
            <div className="w-75 mx-auto">
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div className="btn" style={{ backgroundColor: "rgba(0,255,0,0.3)" }} onClick={() => OpenNewIncome()}>Gelir Ekle</div>
                    <div className="btn " style={{ marginLeft: 10, backgroundColor: "rgba(255,0,0,0.3)" }} onClick={() => OpenNewExpense()}>Gider Ekle</div>
                </div>


                <div className="form-group mx-auto" onClick={() => {
                    var val = dateRef.current.style.display;
                    dateRef.current.style.display = val == "flex" ? "none" : "flex";
                }} style={{ width: '30%' }}>
                    <label for="exampleInputEmail1">Tarih</label>
                    <input type="text" disabled style={{ cursor: 'pointer' }} className="form-control" value={selectedDay} />
                </div>

                <div
                    style={{ display: 'none', justifyContent: 'center' }}
                    ref={dateRef}>
                    <DayPicker
                        mode="single"
                        locale={tr}
                        selected={selectedDay}
                        onSelect={(e) => setSelectedDay(new Date(e).toLocaleDateString())}
                    />
                </div>

                <table className="table table-bordered table-hover mt-5">
                    <thead>
                        <tr>
                            <th scope="col">Toplam Gelir</th>
                            <th scope="col">Toplam Gider</th>
                            <th scope="col">Fark</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            style={{ cursor: 'pointer', backgroundColor: net() < 0 ? "rgba(255,0,0,0.5)" : "" }}
                        >
                            <th scope="row">{totalIncome()}</th>
                            <td>{totalOutcome()}</td>
                            <td>{net()}</td>
                        </tr>
                    </tbody>
                </table>

                <table className="table table-bordered table-hover mt-5">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Adı</th>
                            <th scope="col">Kategori</th>
                            <th scope="col">Miktar</th>
                        </tr>
                    </thead>
                    <tbody>
                        {inventoryList?.map((item, i) => (
                            <tr
                                style={{ cursor: 'pointer', backgroundColor: item.outcome ? "rgba(255,0,0,0.1)" : "rgba(0,255,0,0.1)" }}
                            >
                                <th scope="row">{i}</th>
                                <td>{item.name}</td>
                                <td>{item.category}</td>
                                <td>{item.amount}</td>
                            </tr>
                        ))}

                    </tbody>
                </table>
            </div>

        </div>
    );
}