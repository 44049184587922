import "../css/mealDetail.css";
import React, { useState, useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { v4 as uuid } from "uuid";
import defaultPhoto from "../img/default.png";
import { getPaketPrice } from "../utils/items";
const useCartWithLocalStorage = (key, prefix) => {
  const [cart, setCart] = useState(
    JSON.parse(localStorage.getItem(prefix + "-" + key)) || []
  );

  useEffect(() => {
    localStorage.setItem(prefix + "-" + key, [JSON.stringify(cart)]);
  }, [cart]);
  return [cart, setCart];
};

const MealDetail = () => {
  const { cafe, meal, info, paket, location, category } = useLocation().state;
  const lang = localStorage.getItem("alka-menu-lang") || "tr";
  const [quantity, setQuantity] = useState(1);
  const note = useRef();
  const [cart, setCart] = useCartWithLocalStorage(
    paket ? "paket-cart" : "cart",
    cafe
  );
  const history = useHistory();
  let [checkedOptions, setChecked] = useState([]);
  let [removedOptions, setRemoved] = useState([]);
  let [extraOptions, setExtraOptions] = useState(null);
  const [optPrice, setOptPrice] = useState(0.0);
  const [portion, setPortion] = useState(null);

  const goBack = () => {
    if (paket) {
      history.push({
        pathname: `/paketmenu/${cafe}`,
        state: { loc: location, category },
      });
    } else {
      history.goBack();
    }
  };
  const handleClick = () => {
    let id = uuid();
    setCart([
      ...cart,
      {
        id,
        meal,
        quantity,
        mealNote: note.current.value,
        checkedOptions,
        removedOptions,
        extraOptions,
        portion,
        optPrice:
          optPrice +
          parseFloat((paket ? getPaketPrice(portion) : portion?.price) || 0),
        total: getPrice(),
      },
    ]);
    setTimeout(() => {
      toast.success("Sepete Eklendi!", {
        position: "bottom-center",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      if (paket) {
        history.push({
          pathname: `/paketmenu/${cafe}`,
          state: { loc: location, category },
        });
      } else {
        history.goBack();
      }
    }, 600);
  };
  const getPrice = () => (paket ? getPaketPrice(meal) : parseFloat(meal.price));
  const getMealPrice = () => {
    let total = getPrice();
    total += parseFloat(optPrice);
    total += (paket ? getPaketPrice(portion) : parseFloat(portion?.price)) || 0;

    return total > 0 ? total + " TL" : "";
  };
  const handleCheck = (i, opt) => {
    if (!checkedOptions.includes(opt)) {
      setOptPrice(
        parseFloat(optPrice) +
          (paket ? parseFloat(getPaketPrice(opt)) : parseFloat(opt.price))
      );
      setChecked([...checkedOptions, opt]);
    } else {
      setChecked(checkedOptions.filter((c) => c.id != opt.id));
      setOptPrice(
        optPrice -
          (paket ? parseFloat(getPaketPrice(opt)) : parseFloat(opt.price))
      );
    }
  };

  const handlePortions = (i, opt) => {
    setPortion(opt);
  };

  const handleRemoveCheck = (i, opt) => {
    if (!removedOptions.includes(opt)) {
      setRemoved([...removedOptions, opt]);
    } else {
      setRemoved(removedOptions.filter((c) => c.id != opt.id));
    }
  };

  const handleExtra = (opt) => {
    if (!extraOptions.includes(opt)) {
      setExtraOptions([...extraOptions, opt]);
    } else {
      setExtraOptions(extraOptions.filter((c) => c.id != opt.id));
    }
  };

  return (
    <div
      style={{
        overflow: "hidden",
        minHeight: "100vh",
        backgroundColor: info?.backgroundColor,
        color: info?.textColor,
      }}
    >
      <div className="meal-detail mate">
        <div
          className="back-icon"
          onClick={goBack}
          style={{
            borderRadius: "20%",
            boxShadow: "rgb(0 0 0 / 15%) 2px 2px 10px",
            fontSize: "1.5em",
          }}
        >
          <i
            className="bi bi-chevron-left"
            style={{ borderRadius: "20%", backgroundColor: "white" }}
          ></i>
        </div>

        <div className="detail-image animate__animated animate__slideInRight">
          <img
            src={meal.photo.trim() == "" ? defaultPhoto : meal.photo}
            alt={meal.name}
          />
        </div>
        <div className=" animate__animated animate__slideInUp mt-3">
          <p className="text-center mt-3 fw-bold">
            {lang == "tr"
              ? meal.name
              : lang == "en"
              ? meal.nameEn
              : meal.nameAr}
          </p>
          <div className="text-center" style={{ fontSize: 16 }}>
            <p>
              {lang == "tr"
                ? meal.description
                : lang == "en"
                ? meal.descriptionEn
                : meal.descriptionAr}
            </p>
            <span style={{ marginRight: 15, fontSize: 18, fontWeight: "bold" }}>
              {" "}
              {getMealPrice()}
            </span>

            {meal.prepTime != "" && (
              <span>
                <i className="bi bi-clock-fill"></i> {meal.prepTime} dak.{" "}
              </span>
            )}
          </div>
          {!meal.showInMenu && meal.addOptions?.length > 0 && (
            <div className="options">
              <p>Eklenecek ürünleri seçiniz:</p>
              {meal.addOptions
                ?.sort((a, b) => {
                  if (parseFloat(a.sortOrder) < parseFloat(b.sortOrder))
                    return -1;
                  else return 1;
                })
                .map((opt, i) => (
                  <div className="box">
                    <input
                      id={i + "-add"}
                      type="checkbox"
                      onChange={() => {
                        handleCheck(i, opt);
                      }}
                    />
                    <span className="check"></span>
                    <label for={i + "-add"}>
                      {lang == "tr"
                        ? opt.name
                        : lang == "en"
                        ? opt.nameEn || opt.name
                        : opt.nameAr || opt.name}
                    </label>
                    {opt.price != "0" && (
                      <span
                        style={{
                          fontWeight: 600,
                          position: "absolute",
                          left: "70vw",
                        }}
                      >
                        {paket ? getPaketPrice(opt) : opt.price} TL
                      </span>
                    )}
                  </div>
                ))}
            </div>
          )}
          {!meal.showInMenu && meal.removeOptions?.length > 0 && (
            <div className="options">
              <p>Çıkarmak istediğiniz ürünleri seçiniz</p>
              {meal.removeOptions
                ?.sort((a, b) => {
                  if (parseFloat(a.sortOrder) < parseFloat(b.sortOrder))
                    return -1;
                  else return 1;
                })
                .map((opt, i) => {
                  return (
                    <div className="box mt-2">
                      <input
                        id={i + "-remove"}
                        type="checkbox"
                        onChange={() => {
                          handleRemoveCheck(i, opt);
                        }}
                      />
                      <span className="check"></span>
                      <label for={i + "-remove"}>
                        {lang == "tr"
                          ? opt.name
                          : lang == "en"
                          ? opt.nameEn || opt.name
                          : opt.nameAr || opt.name}
                      </label>
                    </div>
                  );
                })}
            </div>
          )}
          {!meal.showInMenu &&
            meal.extraOptions?.length > 0 &&
            meal.extraOptions.map((optList) => (
              <div className="options">
                <p>{optList.title}</p>
                {optList.Options?.sort((a, b) => {
                  if (parseFloat(a.sortOrder) < parseFloat(b.sortOrder))
                    return -1;
                  else return 1;
                }).map((opt, i) => {
                  return (
                    <div className="box mt-2">
                      <input
                        id={i + "-extra"}
                        type="checkbox"
                        checked={opt == extraOptions ? true : false}
                        onChange={() => {
                          setExtraOptions(opt);
                        }}
                      />
                      <span className="check"></span>
                      <label for={i + "-extra"}>
                        {lang == "tr"
                          ? opt.name
                          : lang == "en"
                          ? opt.nameEn || opt.name
                          : opt.nameAr || opt.name}
                      </label>
                    </div>
                  );
                })}
              </div>
            ))}
          {!meal.showInMenu && meal.portions?.length > 0 && (
            <div className="options">
              <p>Porsiyon Seçiniz</p>
              <div
                className="box mt-2"
                onClick={() => {
                  setPortion(null);
                }}
              >
                <input type="checkbox" checked={portion ? false : true} />
                <span className="check"></span>
                <label>İstemiyorum </label>
              </div>
              {meal.portions
                ?.sort((a, b) => {
                  if (parseFloat(a.sortOrder) < parseFloat(b.sortOrder))
                    return -1;
                  else return 1;
                })
                .map((opt, i) => {
                  return (
                    <div className="box mt-2">
                      <input
                        id={i + "-portion"}
                        type="checkbox"
                        checked={opt == portion ? true : false}
                        onClick={() => {
                          handlePortions(i, opt);
                        }}
                      />
                      <span className="check"></span>
                      <label for={i + "-portion"}>{opt.name}</label>
                      {(paket ? getPaketPrice(opt) : opt.price) != "0" && (
                        <label
                          style={{
                            fontWeight: 600,
                            width: "100%",
                            position: "absolute",
                            left: "60vw",
                          }}
                        >
                          {paket ? getPaketPrice(opt) : opt.price} TL
                        </label>
                      )}
                    </div>
                  );
                })}
            </div>
          )}
          {info.hasOnlyMenu != null && info.hasOnlyMenu == true ? (
            <></>
          ) : (
            !meal.showInMenu && (
              <>
                <p style={{ marginLeft: 40 }}>
                  {lang == "tr"
                    ? "Not Ekle"
                    : lang == "en"
                    ? "Add a Note"
                    : "اضف ملاحظة"}
                </p>
                <div className="input-group" id="meal-note">
                  <textarea
                    className="form-control"
                    aria-label="With textarea"
                    ref={note}
                  ></textarea>
                </div>

                <div
                  className="row text-center mt-3"
                  style={{ width: "40vw", margin: "0 auto", fontSize: 25 }}
                >
                  <div
                    className="col-4"
                    style={{ marginTop: 3 }}
                    onClick={() => {
                      if (quantity > 1) setQuantity(quantity - 1);
                    }}
                  >
                    {quantity > 1 ? (
                      <i className="bi bi-dash-circle"></i>
                    ) : null}
                  </div>
                  <div className="col-4 " style={{}}>
                    <p>{quantity}</p>
                  </div>
                  <div
                    className="col-4"
                    style={{ marginTop: 3 }}
                    onClick={() => setQuantity(quantity + 1)}
                  >
                    <i className="bi bi-plus-circle"></i>
                  </div>
                </div>
              </>
            )
          )}
        </div>
        {!meal.showInMenu && !info.hasOnlyMenu && (
          <div
            className="btn w-100 btn-dark"
            style={{ position: "fixed", bottom: 0, padding: "3% 0" }}
            onClick={handleClick}
          >
            {lang == "tr"
              ? "Sepete Ekle"
              : lang == "en"
              ? "Add to Cart"
              : "أضف إلى العربة"}{" "}
          </div>
        )}

        <div style={{ height: "10vh" }}></div>
      </div>
    </div>
  );
};

export default MealDetail;
