import React, { useRef, useState } from "react";
import Logo from "../components/assets/img/logo.jpg";
import Footer from "../components/footer";
import "../css/info.css";
import { useLocation, useHistory } from "react-router-dom";
import { toast } from "react-toastify";

const Info = () => {
  const { cafe, table, menu, info } = useLocation().state;
  const [pressed, Press] = useState();
  const lang = localStorage.getItem("alka-menu-lang") || "tr";
  const [rate1, setRate1] = useState();
  const [rate2, setRate2] = useState();
  const [rate3, setRate3] = useState();

  const history = useHistory();
  const sendRate = () => {
    if (!rate1 || !rate2 || !rate3) {
      alert("Puanlama Eksik!");
      return;
    }
    let today = new Date();
    const options = {
      method: "POST",
      body: JSON.stringify({
        cafe,
        table,
        hiz: rate1,
        lezzet: rate2,
        hizmet: rate3,
        day: today.toLocaleDateString("en-US"),
        time: today.toLocaleTimeString(),
      }),
      headers: { "Content-Type": "applications/json" },
    };
    fetch(
      "https://us-central1-qr-p-94567.cloudfunctions.net/sendReview",
      options
    ).then(() => {
      toast.info("Değerlendirdiğiniz için Teşekkürler!", {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      history.push(`m/${cafe}/${table}`);
    });
  };

  const ref = useRef();
  const sikayetGonder = () => {
    if (ref.current.value == "") {
      alert("Boş Metin Gönderilemez.");
      return;
    }
    let today = new Date();
    const options = {
      method: "POST",
      body: JSON.stringify({
        cafe,
        table,
        message: ref.current.value,
        day: today.toLocaleDateString("en-US"),
        time: today.toLocaleTimeString(),
      }),
      headers: { "Content-Type": "applications/json" },
    };
    fetch(
      "https://us-central1-qr-p-94567.cloudfunctions.net/submitComplaint",
      options
    ).then(() => {
      toast.info("Değerlendirdiğiniz için Teşekkürler!", {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      history.push(`m/${cafe}/${table}`);
    });
  };
  return (
    <div
      style={{
        backgroundColor: info?.backgroundColor,
        color: info?.textColor,
        minHeight: "100vh",
      }}
    >
      <div className="text-center info p-5">
        <span>
          {lang == "tr"
            ? "Aldığınız Hizmeti Değerlendirebilir veya Restoranla ilgili bir şikayetinizi anında yöneticelere bildirebilirsiniz!"
            : lang == "en"
            ? "You can evaluate the service you receive or immediately report a complaint about the restaurant to the managers."
            : "يمكنك تقييم الخدمة التي تتلقاها أو الإبلاغ فورًا عن شكوى بشأن المطعم إلى المديرين."}
        </span>
        <div
          className="d-block mt-5 btn  w-50 mx-auto btn-danger"
          onClick={() => {
            if (pressed) {
              sikayetGonder();
            } else Press(true);
          }}
        >
          {pressed
            ? lang == "tr"
              ? "Gönder"
              : lang == "en"
              ? "Send"
              : "إرسال"
            : lang == "tr"
            ? "Şikayette Bulun"
            : lang == "en"
            ? "Make a Complaint"
            : "تقديم شكوى"}
        </div>
        {pressed ? (
          <>
            <div className="input-group mt-5 w-75 mx-auto" id="meal-note">
              <textarea
                ref={ref}
                className="form-control"
                aria-label="With textarea"
                placeholder={
                  lang == "tr"
                    ? "İstek ve Şikayetlerinizi bildirebilirsiniz."
                    : ""
                }
                style={{
                  borderRadius: 10,
                  height: "25vh",
                }}
              ></textarea>
            </div>
          </>
        ) : (
          <>
            <>
              <h5 className="mt-5">
                {lang == "tr" ? "Hız" : lang == "en" ? "Speed" : "سرعة"}
              </h5>
              <div className="star-source">
                <svg>
                  <linearGradient
                    x1="50%"
                    y1="5.41294643%"
                    x2="87.5527344%"
                    y2="65.4921875%"
                    id="grad"
                  >
                    <stop stop-color="#bf209f" offset="0%"></stop>
                    <stop stop-color="#dc3545" offset="60%"></stop>
                    <stop stop-color="#dc3545" offset="100%"></stop>
                  </linearGradient>
                  <symbol id="star" viewBox="153 89 106 108">
                    <polygon
                      id="star-shape"
                      stroke="url(#grad)"
                      stroke-width="5"
                      fill="currentColor"
                      points="206 162.5 176.610737 185.45085 189.356511 150.407797 158.447174 129.54915 195.713758 130.842203 206 95 216.286242 130.842203 253.552826 129.54915 222.643489 150.407797 235.389263 185.45085"
                    ></polygon>
                  </symbol>
                </svg>
              </div>
              <div className="star-container">
                <input
                  type="radio"
                  name="star"
                  id="five"
                  onChange={() => {
                    setRate1(5);
                  }}
                />
                <label for="five">
                  <svg className="star">
                    <use xlinkHref="#star" />
                  </svg>
                </label>
                <input
                  type="radio"
                  name="star"
                  id="four"
                  onChange={() => {
                    setRate1(4);
                  }}
                />
                <label for="four">
                  <svg className="star">
                    <use xlinkHref="#star" />
                  </svg>
                </label>
                <input
                  type="radio"
                  name="star"
                  id="three"
                  onChange={() => {
                    setRate1(3);
                  }}
                />
                <label for="three">
                  <svg className="star">
                    <use xlinkHref="#star" />
                  </svg>
                </label>
                <input
                  type="radio"
                  name="star"
                  id="two"
                  onChange={() => {
                    setRate1(2);
                  }}
                />
                <label for="two">
                  <svg className="star">
                    <use xlinkHref="#star" />
                  </svg>
                </label>
                <input
                  type="radio"
                  name="star"
                  id="one"
                  onChange={() => {
                    setRate1(1);
                  }}
                />
                <label for="one">
                  <svg className="star">
                    <use xlinkHref="#star" />
                  </svg>
                </label>
              </div>
            </>

            <>
              <h5 className="mt-3">
                {lang == "tr" ? "Lezzet" : lang == "en" ? "Taste" : "المذاق"}
              </h5>
              <div className="star-source">
                <svg>
                  <linearGradient
                    x1="50%"
                    y1="5.41294643%"
                    x2="87.5527344%"
                    y2="65.4921875%"
                    id="grad1"
                  >
                    <stop stop-color="#bf209f" offset="0%"></stop>
                    <stop stop-color="#dc3545" offset="60%"></stop>
                    <stop stop-color="#dc3545" offset="100%"></stop>
                  </linearGradient>
                  <symbol id="star1" viewBox="153 89 106 108">
                    <polygon
                      id="star-shape"
                      stroke="url(#grad1)"
                      stroke-width="5"
                      fill="currentColor"
                      points="206 162.5 176.610737 185.45085 189.356511 150.407797 158.447174 129.54915 195.713758 130.842203 206 95 216.286242 130.842203 253.552826 129.54915 222.643489 150.407797 235.389263 185.45085"
                    ></polygon>
                  </symbol>
                </svg>
              </div>
              <div className="star-container">
                <input
                  type="radio"
                  name="star1"
                  id="1-five"
                  onChange={() => {
                    setRate2(5);
                  }}
                />
                <label for="1-five">
                  <svg className="star">
                    <use xlinkHref="#star1" />
                  </svg>
                </label>
                <input
                  type="radio"
                  name="star1"
                  id="1-four"
                  onChange={() => {
                    setRate2(4);
                  }}
                />
                <label for="1-four">
                  <svg className="star">
                    <use xlinkHref="#star1" />
                  </svg>
                </label>
                <input
                  type="radio"
                  name="star1"
                  id="1-three"
                  onChange={() => {
                    setRate2(3);
                  }}
                />
                <label for="1-three">
                  <svg className="star">
                    <use xlinkHref="#star1" />
                  </svg>
                </label>
                <input
                  type="radio"
                  name="star1"
                  id="1-two"
                  onChange={() => {
                    setRate2(2);
                  }}
                />
                <label for="1-two">
                  <svg className="star">
                    <use xlinkHref="#star1" />
                  </svg>
                </label>
                <input
                  type="radio"
                  name="star1"
                  id="1-one"
                  onChange={() => {
                    setRate2(1);
                  }}
                />
                <label for="1-one">
                  <svg className="star">
                    <use xlinkHref="#star1" />
                  </svg>
                </label>
              </div>
            </>

            <>
              <h5 className="mt-3">
                {lang == "tr" ? "Hizmet" : lang == "en" ? "Service" : "الخدمات"}
              </h5>
              <div className="star-source">
                <svg>
                  <linearGradient
                    x1="50%"
                    y1="5.41294643%"
                    x2="87.5527344%"
                    y2="65.4921875%"
                    id="grad2"
                  >
                    <stop stop-color="#bf209f" offset="0%"></stop>
                    <stop stop-color="#dc3545" offset="60%"></stop>
                    <stop stop-color="#dc3545" offset="100%"></stop>
                  </linearGradient>
                  <symbol id="star2" viewBox="153 89 106 108">
                    <polygon
                      id="star-shape"
                      stroke="url(#grad2)"
                      stroke-width="5"
                      fill="currentColor"
                      points="206 162.5 176.610737 185.45085 189.356511 150.407797 158.447174 129.54915 195.713758 130.842203 206 95 216.286242 130.842203 253.552826 129.54915 222.643489 150.407797 235.389263 185.45085"
                    ></polygon>
                  </symbol>
                </svg>
              </div>
              <div className="star-container">
                <input
                  type="radio"
                  name="star2"
                  id="2-five"
                  onChange={() => {
                    setRate3(5);
                  }}
                />
                <label for="2-five">
                  <svg className="star">
                    <use xlinkHref="#star2" />
                  </svg>
                </label>
                <input
                  type="radio"
                  name="star2"
                  id="2-four"
                  onChange={() => {
                    setRate3(4);
                  }}
                />
                <label for="2-four">
                  <svg className="star">
                    <use xlinkHref="#star2" />
                  </svg>
                </label>
                <input
                  type="radio"
                  name="star2"
                  id="2-three"
                  onChange={() => {
                    setRate3(3);
                  }}
                />
                <label for="2-three">
                  <svg className="star">
                    <use xlinkHref="#star2" />
                  </svg>
                </label>
                <input
                  type="radio"
                  name="star2"
                  id="2-two"
                  onChange={() => {
                    setRate3(2);
                  }}
                />
                <label for="2-two">
                  <svg className="star">
                    <use xlinkHref="#star2" />
                  </svg>
                </label>
                <input
                  type="radio"
                  name="star2"
                  id="2-one"
                  onChange={() => {
                    setRate3(1);
                  }}
                />
                <label for="2-one">
                  <svg className="star">
                    <use xlinkHref="#star2" />
                  </svg>
                </label>
              </div>
            </>

            <div
              className="btn btn-outline-secondary m-5"
              style={{
                color: info?.textColor,
              }}
              onClick={sendRate}
            >
              {lang == "tr" ? "Gönder" : lang == "en" ? "Send" : "إرسال"}
            </div>
          </>
        )}

        <div style={{ height: "10vh" }}></div>
        {/*<div className="" style={{ fontSize: 30, color: "#f98d2b" }}>
          <TiSocialInstagram />
          <TiSocialFacebook />
  </div>*/}
      </div>
      <Footer active={5} cafe={cafe} table={table} menu={menu} info={info} />
    </div>
  );
};

export default Info;
