import { Button, Spinner } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import "../css/main.css";
import { AiFillInstagram, AiFillFacebook } from "react-icons/ai";
import Kralkumru from "../components/kralkumru";
import MainFooter from '../components/MainFooter';

const PaketMain = () => {
  const { cafe } = useParams();
  const [info, setInfo] = useState();
  const [masa, setMasa] = useState();
  const [loaded, setLoaded] = useState(false);
  const history = useHistory();
  const [orderLimit, setLimit] = useState(null);
  const [lang, setLanguage] = useState(localStorage.getItem("alka-menu-lang") || "tr");

  const routeChange = () => {
    history.push({ pathname: `/paketmenu/${cafe}`, state: { limit: orderLimit } });
  };
  useEffect(() => {
    localStorage.setItem("alka-menu-lang", lang);
  }, [lang]);

  useEffect(() => {
    if (cafe) {
      const options = {
        method: "POST",
        body: JSON.stringify({ cafe }),
        headers: { "Content-Type": "applications/json" },
      };
      fetch("https://us-central1-qr-p-94567.cloudfunctions.net/getInfo", options)
        .then((res) => res.json())
        .then(({ info }) => {
          info.paket = true;
          setInfo(info); localStorage.setItem("info", JSON.stringify(info));
        });
    }
  }, []);
  return (
    info?.name == "Kral Kumru" ? (
      <Kralkumru info={info} />
    ) : (
      <div style={{ backgroundColor: info?.backgroundColor, minHeight: '100vh', color: info?.textColor }}>
        <div className={loaded ? "a text-center main mate" : "d-none"}>
          <p className="" style={{ fontSize: 30, marginTop: 50 }}>
            {info?.name}
          </p>
          <p className="" style={{ fontSize: 22 }}>
            Paket Sipariş
          </p>
          <img
            src={info?.logo}
            onLoad={() => setLoaded(true)}
            style={{ maxHeight: "25vh", width: "auto", marginTop: 10 }}
          />
          <p className="mt-3">
            {lang == "tr"
              ? info?.paketWelcomeText
              : lang == "en"
                ? info?.paketWelcomeTextEn
                : info?.paketWelcomeTextAr}</p>

          {info?.orderLimits &&
            (<><h2>Konum Seçiniz</h2>
              <div className="button dropdown" style={{ boxShadow: info?.boxShadow }}>
                <select className="form__select" style={{ color: info?.textColor }}
                  onChange={(e) => {
                    setLimit({
                      mahalle: e.target.selectedOptions[0].label,
                      limit: e.target.value
                    })
                  }}>
                  <option value="0" data-toggle="tab" selected>
                    Seçiniz
                  </option>
                  {Object.keys(info.orderLimits).map(limit => (
                    <option value={info.orderLimits[limit]} data-toggle="tab" selected={orderLimit?.mahalle == limit ? "true" : "false"}>
                      {limit}
                    </option>
                  ))}

                </select>
              </div>
              {orderLimit?.limit > 0 && (<h2 style={{ marginTop: 10 }}>Sipariş Limiti: {orderLimit.limit} </h2>)}
            </>)}


          <p style={{ fontSize: 25, marginTop: 20 }}> {info?.paketCampaignText} </p>

          {info?.active ? (
            <div>
              <Button
                style={{ backgroundColor: info?.color }}
                className="menuButton"
                onClick={routeChange}
              >
                {lang == "tr"
                  ? "Sipariş Ver"
                  : lang == "en"
                    ? "Give Order"
                    : "قائمة الطعام"}
              </Button>
            </div>) :
            (
              <h2>Restoran şu anda hizmet verememektedir.</h2>
            )}
        </div>

        <div className={loaded ? "button dropdown" : "d-none"} style={{ boxShadow: info?.boxShadow, marginTop: 20 }}>
          <select className="form__select" style={{ color: info?.textColor }}
            value={lang}
            onChange={(e) => { setLanguage(e.target.value); }}>
            <option value="tr" data-toggle="tab" >
              Türkçe
            </option>
            <option value="en" data-toggle="tab">
              English
            </option>
            <option value="ar" data-toggle="tab">
              عربي
            </option>
          </select>
        </div>

        <div className={loaded ? "d-none" : "spinner-container"}>
          <Spinner animation="border" role="status" className="spinner">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
        <MainFooter info={info} />
      </div>
    ));
};

export default PaketMain;