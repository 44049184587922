import React, { useState, useEffect } from "react";
import { ImArrowLeft2 } from "react-icons/im";
import { useHistory, useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Button, Container } from "react-bootstrap";
import "../css/menu.css";
import firebase, { firestore } from "../utils/firebase";
import "../css/meal.css";
import Footer from "../components/footer";
import { toast } from "react-toastify";
import { v4 as uuid } from "uuid";

const Bill = () => {
  const [totalValue, setTotal] = useState(0);
  const { cafe, table, menu, info } = useLocation().state;
  const history = useHistory();
  const [bill, setBill] = useState([]);
  const [menuObj, setMenuObj] = useState();

  const lang = localStorage.getItem("alka-menu-lang") || "tr";

  const goBack = () => {
    history.goBack();
  };
  useEffect(() => {
    setOrders();
  }, []);
  useEffect(() => {
    setMenuObj(
      Object.assign(
        {},
        ...menu
          ?.map((m) => m.items?.map((item) => ({ [item.id]: item })))
          .flat()
      )
    );
  }, [menu]);

  const setOrders = () => {
    const options = {
      method: "POST",
      body: JSON.stringify({ cafe, table }),
      headers: { "Content-Type": "applications/json" },
    };
    fetch("https://us-central1-qr-p-94567.cloudfunctions.net/getBill", options)
      .then((res) => res.json())
      .then(({ orders }) => {
        setBill(Object.entries(orders).map((or) => or[1]));
      });
  };
  useEffect(() => {
    if (bill.length == 0) return;
    let total = 0;
    bill.map((c) => (total += parseFloat(c.total)));
    setTotal(total);
  }, [bill]);

  const goToMeal = (meal) => {
    history.push({
      pathname: "/meal",
      state: { meal },
    });
  };

  const getBill = (e) => {
    const options = {
      method: "POST",
      body: JSON.stringify({ cafe, table, request: "Hesabı İste" }),
      headers: { "Content-Type": "applications/json" },
    };
    fetch(
      "https://us-central1-qr-p-94567.cloudfunctions.net/sendRequest",
      options
    ).then(() => {
      toast.success("İsteğiniz İletildi!", {
        position: "bottom-center",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      goBack();
    });
  };

  return (
    <div
      style={{
        overflow: "hidden",
        minHeight: "100vh",
        backgroundColor: info?.backgroundColor,
        color: info?.textColor,
      }}
    >
      <div className="container mate">
        <div
          className="back-cart mt-3"
          style={{ color: info?.backColor }}
          onClick={goBack}
        >
          <i className="bi bi-chevron-double-left"></i>
        </div>

        <div id="restaurant-menu" style={{ paddingTop: 30 }}>
          <Container>
            <div className="section-title text-center">
              <h2>
                {lang == "tr" ? "Adisyon" : lang == "en" ? "Bill" : "فاتورة"}
              </h2>
            </div>
            <label>
              {lang == "tr"
                ? "Toplam Tutar: "
                : lang == "en"
                ? "Total Price: "
                : "السعر الكلي"}{" "}
            </label>{" "}
            <label>{totalValue} ₺</label>
            <div className="mt-5">
              {bill?.map((b) => (
                <>
                  <p> {b.time} </p>
                  {b.cart.map((m) => (
                    <div
                      className="row meal mx"
                      style={{
                        backgroundColor: info?.backgroundColor,
                        boxShadow: info?.boxShadow,
                      }}
                    >
                      <div className="col-5 mt-2 text">
                        <div className="header">
                          <p>
                            {lang == "tr"
                              ? menuObj[m.meal].name
                              : lang == "en"
                              ? menuObj[m.meal].nameEn
                              : menuObj[m.meal].nameAr}
                          </p>
                        </div>
                        <div className="content">{m.mealNote}</div>
                        <div className="content">
                          {m.checkedOptions?.map((opt) => (
                            <p>{opt.name}</p>
                          ))}
                        </div>
                        <div className="content">
                          {m.quantity} x{" "}
                          {parseFloat(menuObj[m.meal].price) +
                            parseFloat(m.optPrice ? m.optPrice : 0)}
                          TL
                        </div>
                        <div className="price">{m.total} ₺</div>
                      </div>
                      <div className="col-2"></div>
                      <div className="col-3">
                        <div className="image cart">
                          <img src={menuObj[m.meal].photo} />
                        </div>
                      </div>
                    </div>
                  ))}
                  <hr />
                </>
              ))}
            </div>
          </Container>
          <div className="row">
            <button
              className="btn menuButton"
              onClick={getBill}
              style={{ backgroundColor: info?.color, color: info?.textColor }}
            >
              {lang == "tr"
                ? "Hesabı İste"
                : lang == "en"
                ? "Ask for the Bill"
                : "اطلب الفاتورة"}
            </button>
          </div>
          <div style={{ height: "10vh" }}></div>
        </div>
      </div>

      <Footer cafe={cafe} table={table} menu={menu} info={info} active={4} />
    </div>
  );
};

export default Bill;
