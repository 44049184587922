import React from "react";

const MainFooter = ({ info }) => {
  return (
    <footer
      style={{
        position: "fixed",
        bottom: 0,
        marginTop: 30,
        width: "100vw",
        backgroundColor: info?.footerColor,
        color: info?.textColor,
      }}
    >
      <div className="container text-center py-2">
        <small>@{new Date().getFullYear()} Dba Teknoloji Yazılım</small>
      </div>
    </footer>
  );
};

export default MainFooter;
