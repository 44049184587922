import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Menu from "./pages/menu";
import PaketMenu from "./pages/paketMenu";
import Menu1 from "./pages/menu1";
import "bootstrap/dist/css/bootstrap.min.css";
import Cart from "./pages/cart";
import Panel from "./pages/panel";
import Footer from "./components/footer";
import Bill from "./pages/bill";

import Admin from "./layouts/Admin.js";
import Main from "./pages/Main";
import PaketMain from "./pages/PaketMain";
import MealDetail from "./pages/MealDetail";
import Login from "./pages/Login";
import Garson from "./components/garson-cagir.jsx";
import Info from "./pages/info";
import "animate.css";
import Print from "./pages/Print";
import PrintPaket from "./views/Siparisler/Print";
import PrintOrder from "./pages/printOrder";
import PrintGarson from "./pages/printGarson";
import AdminPanel from "./pages/AdminPanel";
import TableSettings from "./views/Masalar/tableSettings";
import RestaurantSettings from "./views/Settings/RestaurantSettings";

import Muhasebe from "./views/Muhasebe/Muhasebe";
import Campaign from "./views/Settings/Campaign";
import Category from "./pages/Category";
import ScrollToTop from "./utils/ScrollToTop";
import PaketCart from "./pages/paketCart";
import Arama from "./views/Arama/Arama";
import OrdersTable from "./views/Tablolar/Siparisler";
import SonAramalar from "./views/Arama/SonAramalar";

function App() {
  return (
    <Router>
      <ScrollToTop>
        <div />
      </ScrollToTop>
      <Switch>
        <Route path="/menu/:cafe/:table">
          <Main />
        </Route>
        <Route path="/paketsiparis/:cafe">
          <PaketMain />
        </Route>

        <Route path="/paketmenu/:cafe">
          <PaketMenu />
        </Route>
        <Route path="/m/:cafe/:table">
          <Menu />
        </Route>
        <Route path="/menu1/:cafe/:table">
          <Menu1 />
        </Route>
        <Route path="/admin/arama-tekrar/:number">
          <Arama noSave={true} />
        </Route>
        <Route path="/admin/arama/:number">
          <Arama />
        </Route>
        <Route path="/login" component={Login} />
        <Route path="/cart">
          <Cart />
        </Route>
        <Route path="/paket-cart">
          <PaketCart />
        </Route>
        <Route path="/meal">
          <MealDetail />
        </Route>
        <Route path="/category">
          <Category />
        </Route>
        <Route path="/degerlendirme">
          <Info />
        </Route>
        <Route path="/garson">
          <Garson />
        </Route>

        <Route path="/print">
          <PrintOrder />
        </Route>
        <Route path="/printBill">
          <Print />
        </Route>
        <Route path="/printW">
          <PrintGarson />
        </Route>

        <Route path="/hesap">
          <Bill />
        </Route>

        <Route path="/admin/ayarlar/kampanya" component={Campaign} />

        <Route path="/admin/muhasebe" component={Muhasebe} />
        <Route path="/admin/son-aramalar" component={SonAramalar} />
        <Route path="/admin/ayarlar/masa" component={TableSettings} />
        <Route path="/admin/ayarlar" component={RestaurantSettings} />
        <Route path="/admin" component={Admin} />
        <Route exact path="/adminpanel">
          <AdminPanel />
        </Route>
        <Redirect from="/" to="/admin/" />
      </Switch>
    </Router>
  );
}

export default App;
