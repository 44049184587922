export const PaymentMethod = {
    Nakit: "Nakit",
    KrediKarti: "Kredi Kartı",
    Metropol: "Metropol",
    Multinet: "Multinet",
    Sodexo: "Sodexo",
    Edenred: "Edenred"
}

export const getPaketPrice = (item) => {
    try {
        if (item.paketPrice == null || item.paketPrice.trim() == "") {
            return parseFloat(item.price);
        }
        return parseFloat(item.paketPrice);
    }
    catch {
        return 0;
    }
}