import React from "react";
import "../css/print.css";

const PrintReport = React.forwardRef((props, ref) => {
    const { methods, total } = props;
    const info = JSON.parse(localStorage.getItem("info"));
    return (
        (
            <div className="ticket" ref={ref}>
                <p className="centered">
                    {info.name}
                    <br />
                </p>
                <table>
                    <thead>
                        <tr>
                            <th className="description">Ödeme</th>
                            <th className="price">Tutar</th>
                        </tr>
                    </thead>
                    <tbody style={{ borderBottom: "1px solid black" }}>
                        {methods?.map((method) =>

                            <>
                                <tr style={{ borderBottom: '1px solid gray' }}>
                                    <td className="description">{method.name}</td>
                                    <td className="price">{method.amount} ₺
                                    </td>


                                </tr>
                            </>

                        )}
                    </tbody>
                    <tr>
                        <td>Toplam:</td>
                        <td>{total} ₺</td>
                    </tr>
                </table>

            </div>
        ));
});

export default PrintReport;
