import firebase, { firestore, auth } from "../../utils/firebase";
import { useContext, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import "./Siparisler.css";
import { AdminContext } from "../../layouts/Admin";
import Order from "./Order";

const GelenSiparisler = () => {
  const cafe = localStorage.getItem("name");
  const { paketOrders, menu, menuObj } = useContext(AdminContext);
  const history = useHistory();
  const approveOrder = (id) => {
    var order = paketOrders.filter(x => x.id == id)[0];
    order.status = 1;
    firestore
      .collection(`Restaurants/${cafe}/paket-orders`)
      .doc(id)
      .set(order);
  };
  const cancelOrder = (id) => {
    var order = paketOrders.filter(x => x.id == id)[0];
    order.status = 3;
    firestore
      .collection(`Restaurants/${cafe}/paket-orders`)
      .doc(id)
      .set(order).then(() => {
        if (!order.email) return;
        fetch(
          `https://yjltp1ft55.execute-api.eu-central-1.amazonaws.com/default/emailer?
        email=${order.email}&type=2`
        )
      })
  };
  const deliverOrder = (id, type) => {
    var order = paketOrders.filter(x => x.id == id)[0];
    order.status = 2;
    firestore
      .collection(`Restaurants/${cafe}/paket-orders`)
      .doc(id)
      .set(order)
      .then(() => {
        if (type == "deliver" && order.email) fetch(
          `https://yjltp1ft55.execute-api.eu-central-1.amazonaws.com/default/emailer?email=${order.email}&type=1`
        )
      });
  };

  const goBack = () => {
    history.push("/admin");
  };

  return (
    <>
      <div className="btn btn-dark mt-3" onClick={goBack}>
        <i
          className="fa fa-arrow-left"
          aria-hidden="true"
          style={{ width: "2vw" }}
        ></i>
      </div>

      <div className="row mt-3">

        {paketOrders &&

          paketOrders
            .filter((o) => o.status == 0)
            .sort(function (a, b) {
              return b.date.toDate() - a.date.toDate()
            })
            .map((order) => {
              return (
                <Order
                  order={order}
                  approveOrder={approveOrder}
                  cancelOrder={cancelOrder}
                  menuObj={cafe != order.cafe ? null : menuObj}
                />
              );
            })}
      </div>
      <div className="row" style={{ height: "50%" }}>
        {paketOrders &&

          paketOrders
            .filter((o) => o.status == 1)
            .sort(function (a, b) {
              return b.date.toDate() - a.date.toDate()
            })
            .map((order) => {
              return (
                <Order
                  order={order}
                  cancelOrder={cancelOrder}
                  deliverOrder={deliverOrder}
                  menuObj={cafe != order.cafe ? null : menuObj}
                />
              );
            })}
      </div>
    </>
  );
};

export default GelenSiparisler;
