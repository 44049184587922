import React, { useState, useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Container, Spinner } from "react-bootstrap";
import "../css/menu.css";
import "../css/meal.css";
import "../css/main.css";
import Footer from "../components/footer";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { confirmAlert } from "react-confirm-alert";

const useCartWithLocalStorage = (prefix) => {
  const [cart, setCart] = useState(
    JSON.parse(localStorage.getItem(prefix + "-cart")) || []
  );

  useEffect(() => {
    localStorage.setItem(prefix + "-cart", [JSON.stringify(cart)]);
  }, [cart]);
  return [cart, setCart];
};

const Cart = () => {
  const history = useHistory();
  const { cafe, table, menu, info } = useLocation().state;
  const [cart, setCart] = useCartWithLocalStorage(cafe);
  const note = useRef();
  const [amount, setAmount] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const discountAmountRef = useRef();
  const [clicked, Click] = useState(false);
  const lang = localStorage.getItem("alka-menu-lang") || "tr";
  const calculateTotal = () => {
    let total = 0;
    cart.map((c) => (total += (c.total + c.optPrice) * c.quantity));
    return total;
  };

  const goBack = () => {
    history.goBack();
  };

  const handleButtonClick = () => {
    const confirmMessage =
      lang === "tr"
        ? "Sepeti temizlemek istediğinize emin misiniz?"
        : lang === "en"
        ? "Are you sure you want to clear the cart?"
        : "هل ترغب حقًا في مسح العربة؟";

    confirmAlert({
      title:
        lang === "tr"
          ? "Sepeti Temizle"
          : lang === "en"
          ? "Clear Cart"
          : "مسح العربة",
      message: confirmMessage,
      buttons: [
        {
          label: lang === "tr" ? "Evet" : lang === "en" ? "Yes" : "نعم",
          onClick: () => clearCart(1),
        },
        {
          label: lang === "tr" ? "Hayır" : lang === "en" ? "No" : "لا",
          onClick: () => console.log("Kullanıcı onay vermedi."),
        },
      ],
    });
  };

  function clearCart(val) {
    if (calculateTotal() === 0) {
      toast.error("Sepette Ürün yok.", {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    setCart([]);
    if (val === 0) return;
    const successMessage =
      lang === "tr"
        ? "Sepet Temizlendi!"
        : lang === "en"
        ? "Cart Cleared!"
        : "تم مسح العربة!";
    toast.success("Sepet Temizlendi!", {
      position: "bottom-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    history.goBack();
  }

  const giveOrder = (e, i = 0) => {
    if (calculateTotal() == 0) {
      toast.error("Sepette Ürün yok.", {
        position: "bottom-center",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    if (info?.hasUserSystem && i == 0) {
      setShowPopup(true);
      return;
    }

    var today = new Date();
    let c = cart.map((c) => ({
      ...c,
      meal: c.meal.id,
      checkedOptions: c.checkedOptions.map((opt) => {
        return {
          name: opt.name,
          id: opt.id,
        };
      }),
    }));
    const order = {
      cart: c,
      note: note.current.value,
      cafe,
      table,
      date: today.toLocaleDateString(),
    };
    const orderText =
      table +
      "\n" +
      cart.map((x) => x.quantity + " x " + x.meal.name + " \n") +
      "Toplam: " +
      calculateTotal() +
      "₺";
    if (info?.wpNumber && info?.wpNumber.length > 0) {
      window.location.href = `https://wa.me/${
        info.wpNumber
      }?text=${encodeURIComponent(orderText)}`;
    }
    const options = {
      method: "POST",
      body: JSON.stringify(order),
      headers: { "Content-Type": "applications/json" },
    };
    setLoaded(true);
    fetch(
      "https://us-central1-qr-p-94567.cloudfunctions.net/sendOrder",
      options
    ).then(() => {
      clearCart(0);
      toast.success("Sipariş Verildi!", {
        position: "bottom-center",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      history.push("/m/" + cafe + "/" + table);
    });
  };

  const updateCart = (m) => {
    setCart(cart.filter((c) => c));
  };
  const removeFromCart = (m) => {
    setCart(cart.filter((c) => c !== m));
  };

  const [showPopup, setShowPopup] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [userDiscount, setUserDiscount] = useState();
  const [showPopup2, setShowPopup2] = useState(false);
  const submit = async () => {
    var today = new Date();
    let c = cart.map((c) => ({ ...c, meal: c.meal.id }));
    let order;
    if (info.hasUserSystem) {
      let phone = phoneNumber.replace(/\D/g, "").replace(/\s+/g, "");
      if (phone.charAt(0) != "0") {
        phone = "0" + phone;
      }
      if (showPopup2 == true) {
        if (!discountAmountRef.current.value) {
          toast.error("Kullanmak istediğiniz puan miktarını giriniz!", {
            position: "bottom-center",
            autoClose: 2500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          return;
        }
        if (parseFloat(discountAmountRef.current.value) > calculateTotal()) {
          toast.error(
            "Kullanmak istediğiniz puan miktarı toplam tutardan fazla olamaz!",
            {
              position: "bottom-center",
              autoClose: 2500,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
          return;
        }
        if (parseFloat(discountAmountRef.current.value) > userDiscount) {
          toast.error(
            "Kullanmak istediğiniz puan miktarı toplam puanınızda fazla olamaz!",
            {
              position: "bottom-center",
              autoClose: 2500,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
          return;
        }
        order = {
          cart: c,
          note: note.current.value,
          cafe,
          table,
          phone,
          discountAmount: discountAmountRef.current.value,
        };
      } else {
        order = {
          cart: c,
          note: note.current.value,
          cafe,
          table,
          phone,
        };
      }
    } else {
      order = {
        cart: c,
        note: note.current.value,
        cafe,
        table,
      };
    }
    setLoaded(true);
    setShowPopup(false);
    setShowPopup2(false);
    const options = {
      method: "POST",
      body: JSON.stringify(order),
      headers: { "Content-Type": "applications/json" },
    };
    fetch(
      "https://us-central1-qr-p-94567.cloudfunctions.net/sendOrder",
      options
    ).then(() => {
      clearCart(0);
      toast.success("Sipariş Verildi!", {
        position: "bottom-center",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      Click(false);
      history.push("/m/" + cafe + "/" + table);
    });
  };

  const checkUserAndSubmit = async () => {
    if (!phoneNumber) {
      toast.error("Eksik Giriş", {
        position: "bottom-center",
        autoClose: 3500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    setLoaded(true);
    setShowPopup(false);
    let phone = phoneNumber.replace(/\D/g, "").replace(/\s+/g, "");
    if (phone.charAt(0) != "0") {
      phone = "0" + phone;
    }
    const options = {
      method: "POST",
      body: JSON.stringify({ phone, cafe }),
      headers: { "Content-Type": "applications/json" },
    };
    const { discount } = await (
      await fetch(
        "https://us-central1-qr-p-94567.cloudfunctions.net/getAmount",
        options
      )
    ).json();
    setUserDiscount(discount);
    let nextDiscount = parseFloat(discount) + calculateTotal() * 0.1;
    if (discount >= 20) {
      setLoaded(false);
      setShowPopup(true);
      setShowPopup2(true);
      discountAmountRef.current.value = "";
      return;
    } else if (nextDiscount > 20) {
      toast.info(info.aa, {
        position: "bottom-center",
        autoClose: 4500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.info(nextDiscount.toFixed(2) + " TL " + info.bb, {
        position: "bottom-center",
        autoClose: 4500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    submit();
  };

  return (
    <>
      {loaded && (
        <div className="spinner-container">
          <Spinner animation="border" role="status" className="spinner">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}

      <div
        className="cart container mate"
        style={{
          backgroundColor: info?.backgroundColor,
          color: info?.textColor,
          minHeight: "100vh",
        }}
      >
        <div
          className="back-cart mt-3"
          style={{ color: info?.backColor }}
          onClick={goBack}
        >
          <i className="bi bi-chevron-double-left"></i>
        </div>

        <div id="restaurant-menu">
          <Container>
            <div className="section-title text-center">
              <h2 className="section-title" style={{ paddingTop: 30 }}>
                {lang == "tr" ? "Sepet" : lang == "en" ? "Cart" : "العربة"}
              </h2>
            </div>

            {cart?.map((m) => (
              <div
                className="row meal mx"
                style={{
                  backgroundColor: info?.backgroundColor,
                  boxShadow: info?.boxShadow,
                }}
              >
                <div className="col-5 mt-2 text">
                  <div className="header">
                    <p>
                      {m.portion
                        ? m.portion.name + " " + m.meal.name
                        : "" + lang == "tr"
                        ? m.meal.name
                        : lang == "en"
                        ? m.meal.nameEn
                        : m.meal.nameAr}
                    </p>
                  </div>

                  <div className="content">
                    {m.checkedOptions.length > 0 && (
                      <>
                        <p>Ekstralar:</p>
                        {m.checkedOptions.map((opt) => (
                          <p>{opt.name}</p>
                        ))}
                      </>
                    )}
                    {m.removedOptions.length > 0 && (
                      <>
                        Çıkarılacaklar:
                        {m.removedOptions.map((opt) => (
                          <p>{opt.name}</p>
                        ))}
                      </>
                    )}
                    {m.extraOptions && m.extraOptions != [] && (
                      <>Patates Seçimi: {m.extraOptions.name}</>
                    )}
                  </div>
                  {m.mealNote.trim() != "" && (
                    <div className="content">Not: {m.mealNote}</div>
                  )}
                  <div className="price">
                    {(m.total + m.optPrice) * m.quantity} ₺
                  </div>
                </div>

                <div className="col-2 text-center ">
                  <div
                    className="row cart-icon"
                    onClick={() => {
                      if (m.quantity > 0) {
                        m.quantity++;
                        updateCart();
                        setAmount(!amount);
                      }
                    }}
                  >
                    {" "}
                    <i className="bi bi-plus-circle"></i>
                  </div>
                  {m.quantity}
                  <div
                    className="row cart-icon"
                    onClick={() => {
                      if (m.quantity == 1) {
                        removeFromCart(m);
                      } else {
                        m.quantity--;
                        updateCart();
                        setAmount(!amount);
                      }
                    }}
                  >
                    {" "}
                    <i
                      className={
                        m.quantity > 1 ? "bi bi-dash-circle" : "bi bi-trash"
                      }
                    ></i>
                  </div>
                </div>
                <div className="col-3">
                  <div className="image cart">
                    <img src={m.meal.photo} />
                  </div>
                </div>
              </div>
            ))}
          </Container>
          <Container>
            <label>
              {" "}
              {lang == "tr"
                ? "Toplam Tutar: "
                : lang == "en"
                ? "Total Price: "
                : "السعر الكلي"}
            </label>{" "}
            <label>{calculateTotal()} ₺</label>
            <p style={{ marginLeft: 40, marginTop: 15 }}>
              {" "}
              {lang == "tr"
                ? "Not Ekle"
                : lang == "en"
                ? "Add a Note"
                : "اضف ملاحظة"}
            </p>
            <div className="input-group" id="meal-note">
              <textarea
                className="form-control"
                aria-label="With textarea"
                ref={note}
                style={{
                  borderRadius: 10,
                  height: "12vh",
                }}
              ></textarea>
            </div>
            <div className="col">
              <div className="col">
                <button
                  className="btn menuButton"
                  onClick={(e) => {
                    giveOrder(e);
                  }}
                  style={{
                    backgroundColor: info?.color,
                    color: info?.textColor,
                  }}
                >
                  {" "}
                  {lang == "tr"
                    ? "Onayla"
                    : lang == "en"
                    ? "Give Order"
                    : "اعطاء أوامر"}
                </button>
              </div>
              <div className="col">
                <button
                  className="btn menuButton2"
                  onClick={handleButtonClick}
                  style={{
                    color: info?.textColor,
                  }}
                >
                  {lang === "tr"
                    ? "Temizle"
                    : lang === "en"
                    ? "Clear cart"
                    : "حذف الكل"}
                </button>
              </div>
            </div>
          </Container>
        </div>
        <div style={{ height: "10vh" }}></div>
      </div>

      <div className={showPopup ? "overlay visible" : "overlay"}>
        <div className="popup">
          <div
            className="close"
            onClick={(e) => {
              setShowPopup(false);
            }}
          >
            &times;
          </div>
          <div className="content">
            <form>
              {showPopup2 == false ? (
                <>
                  <p style={{ textAlign: "center", fontWeight: "bold" }}>
                    {info?.campaignText}
                  </p>

                  {/*<div className="form-group">
                <label for="exampleFormControlInput1">Kullanıcı Adı</label>
                <input
                  type="text"
                  className="form-control"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
          </div>*/}

                  <div className="form-group">
                    <label for="exampleFormControlInput1">
                      Telefon Numarası
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                  </div>

                  <div className="row">
                    <div
                      className="btn w-50 mx-auto mt-5"
                      style={{
                        backgroundColor: info?.color,
                      }}
                      onClick={() => {
                        checkUserAndSubmit();
                      }}
                    >
                      Gönder
                    </div>
                  </div>
                  {/* <div className="row">
                    <div
                      className="btn w-50 mx-auto mt-5 btn-danger"
                      onClick={(e) => {
                        if (clicked) return;
                        setShowPopup(false);
                        giveOrder(e, 1);
                      }}
                    >
                      Kampanyasız Sipariş Ver
                    </div>
                  </div> */}
                </>
              ) : (
                <>
                  <p style={{ textAlign: "center", fontWeight: "bold" }}>
                    {info?.campaignOrderCount}: {userDiscount.toFixed(2)}
                  </p>

                  <div className="form-group">
                    <label for="exampleFormControlInput1">
                      Kullanılacak Miktar
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Puanı Giriniz."
                      ref={discountAmountRef}
                    />
                  </div>

                  <div className="row">
                    <div
                      className="btn w-50 mx-auto mt-5"
                      style={{
                        backgroundColor: info?.color,
                      }}
                      onClick={() => {
                        submit();
                      }}
                    >
                      Gönder
                    </div>
                  </div>
                </>
              )}
            </form>
          </div>
        </div>
      </div>

      <Footer cafe={cafe} table={table} menu={menu} info={info} active={2} />
    </>
  );
};

export default Cart;
