import React, { useState, useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Container, Spinner } from "react-bootstrap";
import "../css/menu.css";
import "../css/meal.css";
import "../css/main.css";
import Footer from "../components/footer";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PaymentMethod } from "../utils/items";

export const useCartWithLocalStorage = (prefix) => {
  const [cart, setCart] = useState(
    JSON.parse(localStorage.getItem(prefix + "-paket-cart")) || []
  );

  useEffect(() => {
    localStorage.setItem(prefix + "-paket-cart", [JSON.stringify(cart)]);
  }, [cart]);
  return [cart, setCart];
};

const PaketCart = () => {
  const history = useHistory();
  const { cafe, table, menu, info, limit } = useLocation().state;
  const [cart, setCart] = useCartWithLocalStorage(cafe);
  const note = useRef();
  const name = useRef();
  const phone = useRef();
  const address = useRef();
  const email = useRef();
  const [amount, setAmount] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const discountAmountRef = useRef();
  const [clicked, Click] = useState(false);
  const lang = localStorage.getItem("alka-menu-lang") || "tr";

  const checkIfAboveLimit = () => {
    var _limit = limit?.limit;
    if (calculateTotalWithDiscount() < _limit) return false;
    return true;
  };

  const calculateTotal = () => {
    let total = 0;
    cart.map((c) => (total += (c.total + c.optPrice) * c.quantity));
    return total;
  };
  const calculateTotalWithDiscount = () => {
    let total = 0;
    cart.map((c) => (total += (c.total + c.optPrice) * c.quantity));

    if (info?.discountRate != null && info?.discountRate.trim() != "") {
      return total - (total * parseInt(info?.discountRate)) / 100;
    } else if (info?.paketDiscount != null) {
      var limit = parseFloat(info?.paketDiscount.split("/")[0]);
      var discount = parseFloat(info?.paketDiscount.split("/")[1]);

      if (total < limit) return total;
      return total - discount;
    }
    return total;
  };

  const goBack = () => {
    history.goBack();
  };

  const clearCart = (val = 1) => {
    if (calculateTotal() == 0) {
      toast.error("Sepette Ürün yok.", {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    setCart([]);
    if (val == 0) return;
    toast.success("Sepet Temizlendi!", {
      position: "bottom-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    history.goBack();
  };

  useEffect(() => {
    name.current.value = localStorage.getItem("alka-paket-siparis-isim") || "";
    address.current.value =
      localStorage.getItem("alka-paket-siparis-adres") || "";
    email.current.value =
      localStorage.getItem("alka-paket-siparis-email") || "";
    phone.current.value =
      localStorage.getItem("alka-paket-siparis-telefon") || "";
  }, []);

  const giveOrder = (e, i = 0) => {
    if (calculateTotal() == 0) {
      toast.error("Sepette Ürün yok.", {
        position: "bottom-center",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    if (limit != null && checkIfAboveLimit() == false) {
      toast.error("Minimum Sipariş Limitinin Altında!", {
        position: "bottom-center",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    if (
      name.current.value == "" ||
      address.current.value == "" ||
      phone.current.value == "" ||
      paymentMethod == ""
    ) {
      toast.error("Bilgiler Boş Bırakılamaz!", {
        position: "bottom-center",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    var today = new Date();
    let c = cart.map((c) => ({
      ...c,
      meal: c.meal.id,
      checkedOptions: c.checkedOptions.map((opt) => {
        return {
          name: opt.name,
          id: opt.id,
        };
      }),
    }));
    const order = {
      cart: c,
      note: note.current.value,
      name: name.current.value,
      address: address.current.value,
      email: email.current.value,
      phone: phone.current.value,
      paymentMethod,
      cafe,
      time: today.toLocaleTimeString(),
      date: today.toLocaleDateString(),
      paket: true,
    };
    localStorage.setItem("alka-paket-siparis-isim", name.current.value);
    localStorage.setItem("alka-paket-siparis-adres", address.current.value);
    localStorage.setItem("alka-paket-siparis-email", email.current.value);
    localStorage.setItem("alka-paket-siparis-telefon", phone.current.value);
    // const orderText =
    //   table +
    //   "\n" +
    //   cart.map((x) => x.quantity + " x " + x.meal.name + " \n") +
    //   "Toplam: " +
    //   calculateTotal() +
    //   "₺";
    // if (info?.wpNumber && info?.wpNumber.length > 0) {
    //   window.location.href = `https://wa.me/${
    //     info.wpNumber
    //   }?text=${encodeURIComponent(orderText)}`;
    // }
    const options = {
      method: "POST",
      body: JSON.stringify(order),
      headers: { "Content-Type": "applications/json" },
    };
    setLoaded(true);
    fetch(
      "https://us-central1-qr-p-94567.cloudfunctions.net/sendPaketOrder",
      options
    ).then(() => {
      clearCart(0);
      toast.success("Sipariş Verildi!", {
        position: "bottom-center",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      history.push("/paketmenu/" + cafe);
    });
  };

  const updateCart = (m) => {
    setCart(cart.filter((c) => c));
  };
  const removeFromCart = (m) => {
    setCart(cart.filter((c) => c !== m));
  };

  const [showPopup, setShowPopup] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [userDiscount, setUserDiscount] = useState();
  const [showPopup2, setShowPopup2] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("");

  return (
    <>
      {loaded ? (
        <div className="spinner-container">
          <Spinner animation="border" role="status" className="spinner">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <>
          <div
            className="cart container mate"
            style={{
              backgroundColor: info?.backgroundColor,
              color: info?.textColor,
              minHeight: "100vh",
            }}
          >
            <div
              className="back-cart mt-3"
              style={{ color: info?.backColor }}
              onClick={goBack}
            >
              <i className="bi bi-chevron-double-left"></i>
            </div>

            <div id="restaurant-menu">
              <Container>
                <div className="section-title text-center">
                  <h2 className="section-title" style={{ paddingTop: 30 }}>
                    {lang == "tr" ? "Sepet" : lang == "en" ? "Cart" : "العربة"}
                  </h2>
                </div>

                {cart?.map((m) => (
                  <div
                    className="row meal mx"
                    style={{
                      backgroundColor: info?.backgroundColor,
                      boxShadow: info?.boxShadow,
                    }}
                  >
                    <div className="col-5 mt-2 text">
                      <div className="header">
                        <p>
                          {m.portion
                            ? m.portion.name + " " + m.meal.name
                            : "" + lang == "tr"
                            ? m.meal.name
                            : lang == "en"
                            ? m.meal.nameEn
                            : m.meal.nameAr}
                        </p>
                      </div>

                      <div className="content">
                        {m.checkedOptions.length > 0 && (
                          <>
                            <p>Ekstralar:</p>
                            {m.checkedOptions.map((opt) => (
                              <p>{opt.name}</p>
                            ))}
                          </>
                        )}
                        {m.removedOptions.length > 0 && (
                          <>
                            Çıkarılacaklar:
                            {m.removedOptions.map((opt) => (
                              <p>{opt.name}</p>
                            ))}
                          </>
                        )}
                      </div>
                      {m.mealNote.trim() != "" && (
                        <div className="content">Not: {m.mealNote}</div>
                      )}
                      <div className="price">
                        {(m.total + m.optPrice) * m.quantity} ₺
                      </div>
                    </div>

                    <div className="col-2 text-center ">
                      <div
                        className="row cart-icon"
                        onClick={() => {
                          if (m.quantity > 0) {
                            m.quantity++;
                            updateCart();
                            setAmount(!amount);
                          }
                        }}
                      >
                        {" "}
                        <i className="bi bi-plus-circle"></i>
                      </div>
                      {m.quantity}
                      <div
                        className="row cart-icon"
                        onClick={() => {
                          if (m.quantity == 1) {
                            removeFromCart(m);
                          } else {
                            m.quantity--;
                            updateCart();
                            setAmount(!amount);
                          }
                        }}
                      >
                        {" "}
                        <i
                          className={
                            m.quantity > 1 ? "bi bi-dash-circle" : "bi bi-trash"
                          }
                        ></i>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="image cart">
                        <img src={m.meal.photo} />
                      </div>
                    </div>
                  </div>
                ))}
              </Container>
              <Container>
                <label>
                  {" "}
                  {lang == "tr"
                    ? "Toplam Tutar: "
                    : lang == "en"
                    ? "Total Price: "
                    : "السعر الكلي"}
                </label>{" "}
                <label>{calculateTotal()} ₺</label>
                {((info.discountRate &&
                  info.discountRate.trim() != "" &&
                  info.discountRate != 0) ||
                  (info.paketDiscount && info.paketDiscount.trim() != "")) && (
                  <div style={{ color: "red" }}>
                    <label>İndirimli Tutar</label>
                    <label>{calculateTotalWithDiscount()} ₺</label>
                  </div>
                )}
                {limit && !checkIfAboveLimit() && (
                  <div style={{ color: "red" }}>
                    <label>Minimum Sipariş Tutarının Altında</label>
                  </div>
                )}
                <p style={{ marginLeft: 40, marginTop: 15 }}>
                  {" "}
                  {lang == "tr"
                    ? "Ad Soyad"
                    : lang == "en"
                    ? "Name Surname"
                    : "اسم اللقب"}
                </p>
                <div className="input-group" id="name" name="name">
                  <input
                    type={"text"}
                    className="form-control"
                    ref={name}
                    style={{
                      borderRadius: 10,
                    }}
                  />
                </div>
                <p style={{ marginLeft: 40, marginTop: 15 }}>
                  {" "}
                  {lang == "tr"
                    ? "Telefon Numarası"
                    : lang == "en"
                    ? "Phone Number"
                    : "رقم التليفون"}
                </p>
                <div className="input-group" id="phone" name="phone">
                  <input
                    type={"number"}
                    className="form-control"
                    ref={phone}
                    style={{
                      borderRadius: 10,
                    }}
                  />
                </div>
                <p style={{ marginLeft: 40, marginTop: 15 }}>
                  {" "}
                  {lang == "tr"
                    ? "Email"
                    : lang == "en"
                    ? "Email"
                    : "البريد الإلكتروني"}
                </p>
                <div className="input-group" id="email" name="email">
                  <input
                    type={"text"}
                    className="form-control"
                    ref={email}
                    style={{
                      borderRadius: 10,
                    }}
                  />
                </div>
                <p style={{ marginLeft: 40, marginTop: 15 }}>
                  {" "}
                  {lang == "tr" ? "Adres" : lang == "en" ? "Address" : "تبوك"}
                </p>
                <div className="input-group" id="address" name="address">
                  <textarea
                    type={"number"}
                    className="form-control"
                    ref={address}
                    style={{
                      borderRadius: 10,
                      height: "12vh",
                    }}
                  ></textarea>
                </div>
                <p style={{ marginLeft: 40, marginTop: 15 }}>
                  {" "}
                  {lang == "tr"
                    ? "Not Ekle"
                    : lang == "en"
                    ? "Add a Note"
                    : "اضف ملاحظة"}
                </p>
                <div className="input-group" id="meal-note">
                  <textarea
                    className="form-control"
                    aria-label="With textarea"
                    ref={note}
                    style={{
                      borderRadius: 10,
                      height: "8vh",
                    }}
                  ></textarea>
                </div>
                <div className="input-group" id="paymentMethod">
                  <div className="options row mt-3" style={{ width: "100Vw" }}>
                    {info?.paymentMethods.map((method) => (
                      <div className="box col-6">
                        <input
                          id={method}
                          type="checkbox"
                          checked={paymentMethod == method}
                          onChange={() => {
                            setPaymentMethod(method);
                          }}
                        />
                        <span className="check"></span>
                        <label for={method}>{method}</label>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <button
                      className="btn menuButton"
                      onClick={(e) => {
                        giveOrder(e);
                      }}
                      style={{
                        backgroundColor: info?.color,
                        color: info?.textColor,
                      }}
                    >
                      {" "}
                      {lang == "tr"
                        ? "Onayla"
                        : lang == "en"
                        ? "Give Order"
                        : "اعطاء أوامر"}
                    </button>
                  </div>
                  <div className="col">
                    <button
                      className="btn menuButton"
                      onClick={clearCart}
                      style={{
                        backgroundColor: info?.color,
                        color: info?.textColor,
                      }}
                    >
                      {" "}
                      {lang == "tr"
                        ? "Temizle"
                        : lang == "en"
                        ? "Clear cart"
                        : "حذف الكل"}{" "}
                    </button>
                  </div>
                </div>
              </Container>
            </div>
            <div style={{ height: "10vh" }}></div>
          </div>

          <div className={showPopup ? "overlay visible" : "overlay"}>
            <div className="popup">
              <div
                className="close"
                onClick={(e) => {
                  setShowPopup(false);
                }}
              >
                &times;
              </div>
              <div className="content">
                <form>
                  {showPopup2 == false ? (
                    <>
                      <p style={{ textAlign: "center", fontWeight: "bold" }}>
                        {info?.campaignText}
                      </p>

                      {/*<div className="form-group">
                <label for="exampleFormControlInput1">Kullanıcı Adı</label>
                <input
                  type="text"
                  className="form-control"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
          </div>*/}

                      <div className="form-group">
                        <label for="exampleFormControlInput1">
                          Telefon Numarası
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          value={phoneNumber}
                          onChange={(e) => setPhoneNumber(e.target.value)}
                        />
                      </div>

                      <div className="row">
                        <div
                          className="btn w-50 mx-auto mt-5"
                          style={{
                            backgroundColor: info?.color,
                          }}
                          onClick={() => {
                            checkUserAndSubmit();
                          }}
                        >
                          Gönder
                        </div>
                      </div>
                      {/* <div className="row">
                    <div
                      className="btn w-50 mx-auto mt-5 btn-danger"
                      onClick={(e) => {
                        if (clicked) return;
                        setShowPopup(false);
                        giveOrder(e, 1);
                      }}
                    >
                      Kampanyasız Sipariş Ver
                    </div>
                  </div> */}
                    </>
                  ) : (
                    <>
                      <p style={{ textAlign: "center", fontWeight: "bold" }}>
                        {info?.campaignOrderCount}: {userDiscount.toFixed(2)}
                      </p>

                      <div className="form-group">
                        <label for="exampleFormControlInput1">
                          Kullanılacak Miktar
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Puanı Giriniz."
                          ref={discountAmountRef}
                        />
                      </div>

                      <div className="row">
                        <div
                          className="btn w-50 mx-auto mt-5"
                          style={{
                            backgroundColor: info?.color,
                          }}
                          onClick={() => {
                            submit();
                          }}
                        >
                          Gönder
                        </div>
                      </div>
                    </>
                  )}
                </form>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default PaketCart;
