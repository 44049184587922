import { Link } from "react-router-dom";
import "../css/menu.css";
import ReactDOM from "react-dom";
import { useParams, useHistory } from "react-router-dom";
import React, { createContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import firebase, { auth, firestore } from "../utils/firebase";
import Footer from "../components/footer";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../css/meal.css";
import { Spinner } from "react-bootstrap";
import "../css/dmenu.css";
import instaBadge from "../img/insta-badge.png";
import logo from "../img/logo.png";

const Menu1 = () => {
  const [meals, setMeals] = useState();
  const [category, setCategory] = useState();
  const [menu, setMenu] = useState(null);
  const { cafe, table } = useParams();
  const [loading, done] = useState(false);
  const [info, setInfo] = useState();
  const [filter, setFilter] = useState("");
  const [lang, setLanguage] = useState(
    localStorage.getItem("alka-menu-lang") || "tr"
  );

  useEffect(() => {
    localStorage.setItem("alka-menu-lang", lang);
  }, [lang]);

  useEffect(() => {
    firestore
      .collection(`Restaurants/${cafe}/info`)
      .doc("info")
      .get()
      .then((doc) => {
        setInfo(doc.data());
      });
  }, []);
  useEffect(() => {
    if (info?.logo && menu) done(true);
  }, [menu]);
  const history = useHistory();

  const goToCategory = (category) => {
    history.push({
      pathname: "/category",
      state: { category, info, cafe, table },
    });
  };

  useEffect(() => {
    firestore
      .collection("Restaurants")
      .doc(cafe.toString())
      .get()
      .then((doc) => {
        if (doc.exists) {
          let menu = doc.data().Menu;
          menu.sort((a, b) => {
            if (parseFloat(a.sortOrder) < parseFloat(b.sortOrder)) return -1;
            else return 1;
          });
          setMenu(menu.filter((m) => m.active == 1));
          let items = menu[0].items;
          items.sort((a, b) => {
            if (parseFloat(a.sortOrder) < parseFloat(b.sortOrder)) return -1;
            else return 1;
          });
          setMeals(items.filter((i) => i.active == 1));
          setCategory(
            lang == "tr"
              ? menu[0].category
              : lang == "en"
              ? menu[0].categoryEn
              : menu[0].categoryAr
          );
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      });
  }, []);

  useEffect(() => {
    if (menu == null) {
      return;
    }
    var list = menu.map((m) => m.items).flat();
    setMeals(
      list.filter((x) => x.name.toLowerCase().includes(filter.toLowerCase()))
    );
  }, [filter]);

  const [autoplay, setAutoPlay] = useState(true);
  const settings = {
    dots: true,
    infinite: true,
    speed: 200,
    slidesToShow: 3,
    slidesToScroll: 3,
  };
  const goToMeal = (meal) => {
    history.push({
      pathname: "/meal",
      state: { meal, info },
    });
  };
  const goBack = () => {
    history.push(`/menu/${cafe}/${table}`);
  };
  const [isPopupOpen, setIsPopupOpen] = useState(
    JSON.parse(localStorage.getItem("openPopup")) ?? true
  );

  const closePopup = () => {
    setIsPopupOpen(false);
  };
  const MainContext = createContext();
  return loading ? (
    <div
      style={{
        overflowX: "hidden",
        backgroundColor: "white",
        color: info?.textColor,
        minHeight: "100vh",
      }}
      className="mate"
    >
      <div
        className="sidepage-hero"
        style={{
          backgroundImage: `url(${info?.bgImage})`,
        }}
      ></div>
      {info?.instagram != null && (
        <div className="primary-announcement-container mt-3">
          <a className="row" href={info?.instagram} target="_blank">
            <img src={instaBadge} style={{ width: 150 }} />
            <div
              style={{ width: 90, marginRight: 20, textAlign: "center" }}
              className="btn-action"
            >
              TAKİP ET
            </div>
          </a>
        </div>
      )}
      {isPopupOpen && info?.popupScreen && (
        <div className="popup-overlay">
          <div className="popup-content">
            <span
              className="close-button"
              onClick={() => {
                localStorage.setItem("openPopup", false);
                closePopup();
              }}
            >
              &times;
            </span>
            <img src={info.popupScreen} alt="Popup" />
          </div>
        </div>
      )}
      <div className="button dropdown" style={{ boxShadow: info?.boxShadow }}>
        <select
          className="form__select"
          style={{ color: info?.textColor }}
          value={lang}
          onChange={(e) => {
            setLanguage(e.target.value);
          }}
        >
          <option value="tr" data-toggle="tab">
            Türkçe
          </option>
          <option value="en" data-toggle="tab">
            English
          </option>
          <option value="ar" data-toggle="tab">
            عربي
          </option>
        </select>
      </div>
      {/* <div className="search__container text-center">
        <input
          className="search__input"
          type="text"
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
          placeholder={
            lang == "tr" ? "Ürün Ara" : lang == "en" ? "Search" : "بحث"
          }
        />
      </div> */}

      <div className="sections-content">
        <div id="menu-content" className="menu-grid">
          {menu?.map((category, i) => (
            <div
              key={i}
              className="menu-grid-item text-center"
              onClick={() => goToCategory(category)}
              style={{
                backgroundImage: `url(${category.photo})`,
                gridColumn: `span ${category.width ?? 2}`,
                gridRow: `span ${category.height ?? 1}`,
              }}
            >
              <div className="black-box"></div>
              <span className="text-box">
                {lang == "tr"
                  ? category.category
                  : lang == "en"
                  ? category.categoryEn
                  : category.categoryAr}
              </span>
            </div>
          ))}
        </div>
      </div>
      <div className="footer-dmenu">
        <a href="https://dbateknoloji.com" target="_blank">
          <img src={logo} style={{ width: 60 }} />
        </a>
        <br />
        <small>DBA Teknoloji Yazılım &copy; {new Date().getFullYear()}</small>
      </div>
    </div>
  ) : (
    <div className="spinner-container">
      <Spinner animation="border" role="status" className="spinner">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  );
};

export default Menu1;
