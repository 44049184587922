import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import "firebase/analytics";

const config = {
  apiKey: "AIzaSyCDAsEdkorpgD4w13XOTsX_hpO6q7-oNeQ",
  authDomain: "qr-p-94567.firebaseapp.com",
  projectId: "qr-p-94567",
  storageBucket: "qr-p-94567.appspot.com",
  messagingSenderId: "1038586073617",
  appId: "1:1038586073617:web:435e7f4eda20f9543d97b0",
};
firebase.initializeApp(config);

export const auth = firebase.auth();
export const firestore = firebase.firestore();
firestore.enablePersistence();
export const storage = firebase.storage();
export default firebase;
export const analytics = firebase.analytics();