import React, { useState } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import "../css/login.css";
import firebase, { firestore } from "../utils/firebase";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const history = useHistory();
  const handleSignIn = (e) => {
    e.preventDefault();
    try {
      firestore
        .collection("Users")
        .doc(username)
        .get()
        .then((doc) => {
          firebase
            .auth()
            .signInWithEmailAndPassword(doc.data().email, password)
            .then((userCredential) => {
              // Signed in
              if (username == "admin@dbateknoloji.com")
                history.push("/adminpanel");
              else history.push("/admin");
            })
            .catch((error) => {
              var errorCode = error.code;
              var errorMessage = error.message;
              console.log(error);
            });
        });
    } catch (err) {
      alert("Hatalı Giriş");
    }
  };
  return (
    <div className="login-wrap">
      <div className="login-html">
        <input id="tab-1" type="radio" name="tab" className="sign-in" checked />
        <label for="tab-1" className="tab">
          Sign In
        </label>
        <input id="tab-2" type="radio" name="tab" className="sign-up" />
        <label for="tab-2" className="tab">
          Sign Up
        </label>

        <div className="login-form">
          <div className="sign-in-htm">
            <form onSubmit={(e) => handleSignIn(e)}>
              <div className="group">
                <label for="user" className="label">
                  Kullanıcı Adı
                </label>
                <input
                  id="user"
                  type="text"
                  className="input"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>
              <div className="group">
                <label for="pass" className="label">
                  Şifre
                </label>
                <input
                  id="pass"
                  type="password"
                  className="input"
                  data-type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>

              <div className="group">
                <input type="submit" className="button" value="Giriş Yap" />
              </div>
              <div className="hr"></div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;

/*
<div className="sign-up-htm">
            <div className="group">
              <label for="user" className="label">
                Username
              </label>
              <input id="user" type="text" className="input" />
            </div>
            <div className="group">
              <label for="pass" className="label">
                Password
              </label>
              <input
                id="pass"
                type="password"
                className="input"
                data-type="password"
              />
            </div>
            <div className="group">
              <label for="pass" className="label">
                Repeat Password
              </label>
              <input
                id="pass"
                type="password"
                className="input"
                data-type="password"
              />
            </div>
            <div className="group">
              <label for="pass" className="label">
                Email Address
              </label>
              <input id="pass" type="text" className="input" />
            </div>
            <div className="group">
              <input type="submit" className="button" value="Sign Up" />
            </div>
            <div className="hr"></div>
          </div>

*/
