import React, { useState } from "react";
import { firestore, storage } from "../../utils/firebase";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

const RestaurantSettings = () => {
  const cafe = localStorage.getItem("name");
  const info = JSON.parse(localStorage.getItem("info"));
  const history = useHistory();
  const create = () => {
    firestore
      .collection(`Restaurants/${cafe}/info`)
      .doc("info")

      .set({
        name, logo, color, welcomeText, discountRate, instagram, facebook,
        campaignInfo: campaignText, wpNumber: wpNumber, paketCampaignText, paketWelcomeText
      }, { merge: true })

      .then(() => {
        info.name = name;
        info.logo = logo;
        info.color = color;
        info.campaignInfo = campaignText;
        info.paketCampaignText = paketCampaignText;
        info.welcomeText = welcomeText;
        info.paketWelcomeText = paketWelcomeText;
        info.instagram = instagram;
        info.facebook = facebook;
        info.discountRate = discountRate;
        info.wpNumber = wpNumber;
        localStorage.setItem("info", JSON.stringify(info));

        toast.success("Başarıyla Güncellendi!", {
          position: "bottom-center",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  const uploadImage = () => {
    storage
      .ref(`${image.name}`)
      .put(image)
      .then((task) => {
        return task.ref.getDownloadURL();
      })
      .then((url) => {
        setLogo(url);
      });
  };
  const [welcomeText, setWelcomeText] = useState(info.welcomeText || "");
  const [paketWelcomeText, setPaketWelcomeText] = useState(info.paketWelcomeText || "");
  const [campaignText, setCampaignText] = useState(info.campaignInfo || "");
  const [paketCampaignText, setPaketCampaignText] = useState(info.paketCampaignText || "");
  const [wpNumber, setWpNumber] = useState(info.wpNumber || "");
  const [discountRate, setDiscountRate] = useState(info.discountRate);
  const [name, setName] = useState(info.name || "");
  const [logo, setLogo] = useState(info.logo || "");
  const [instagram, setInstagram] = useState(info.instagram || "");
  const [facebook, setFacebook] = useState(info.facebook || "");
  const [color, setColor] = useState(info.color || "");
  const [image, setImage] = useState("");
  const goBack = () => {
    history.push("/admin");
  };
  return (
    <div className="row">
      <div className="col-3">
        <div >
          <div className="btn btn-danger mt-3" onClick={goBack}>
            <i
              className="fa fa-arrow-left"
              aria-hidden="true"
              style={{ width: "2vw" }}
            ></i>
          </div>
        </div>
      </div>

      <div className="col-6" style={{ fontSize: "0.8vw" }}>
        <form>
          <div className="form-group w-50 mx-auto">
            <label for="exampleFormControlInput1">Restoran Adı</label>
            <input
              type="text"
              className="form-control"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </div>
          <div className="form-group w-50 row mx-auto">
            <div className="col">
              <label for="exampleFormControlInput1">Logo</label>
              <input
                type="file"
                onChange={(e) => {
                  setImage(e.target.files[0]);
                }}
              />
            </div>

            <div className="col btn btn-primary mt-3" onClick={uploadImage}>
              Yükle
            </div>
          </div>
          <div className="form-group w-50 mx-auto">
            <label for="exampleFormControlInput1">Logo</label>
            <input
              type="text"
              className="form-control"
              value={logo}
              onChange={(e) => {
                setLogo(e.target.value);
              }}
            />
          </div>
          <div className="form-group w-50 mx-auto">
            <label for="exampleFormControlInput1">Renk</label>
            <input
              type="text"
              className="form-control"
              value={color}
              placeholder="renk hex değeri"
              onChange={(e) => {
                setColor(e.target.value);
              }}
            />
          </div>
          <div className="form-group w-50 mx-auto">
            <label for="exampleFormControlInput1">Instagram</label>
            <input
              type="text"
              className="form-control"
              value={instagram}
              placeholder="Instagram adresi"
              onChange={(e) => {
                setInstagram(e.target.value);
              }}
            />
          </div>
          <div className="form-group w-50 mx-auto">
            <label for="exampleFormControlInput1">Facebook</label>
            <input
              type="text"
              className="form-control"
              value={facebook}
              placeholder="Facebook adresi"
              onChange={(e) => {
                setFacebook(e.target.value);
              }}
            />
          </div>
          <div className="form-group w-50 mx-auto">
            <label for="exampleFormControlInput1">Karşılama Mesajı</label>
            <input
              type="text"
              className="form-control"
              value={welcomeText}
              onChange={(e) => {
                setWelcomeText(e.target.value);
              }}
            />
          </div>
          <div className="form-group w-50 mx-auto">
            <label for="exampleFormControlInput1">Paket Sipariş Karşılama Mesajı</label>
            <input
              type="text"
              className="form-control"
              value={paketWelcomeText}
              onChange={(e) => {
                setPaketWelcomeText(e.target.value);
              }}
            />
          </div>
          <div className="form-group w-50 mx-auto">
            <label for="exampleFormControlInput1">Kampanya Mesajı</label>
            <textarea
              type="text"
              className="form-control"
              value={campaignText}
              onChange={(e) => {
                setCampaignText(e.target.value);
              }}
              style={{ height: '15vh' }}
            />
          </div>
          <div className="form-group w-50 mx-auto">
            <label for="exampleFormControlInput1">Paket Kampanya Mesajı</label>
            <textarea
              type="text"
              className="form-control"
              value={paketCampaignText}
              onChange={(e) => {
                setPaketCampaignText(e.target.value);
              }}
              style={{ height: '15vh' }}
            />
          </div>
          <div className="form-group w-50 mx-auto">
            <label for="exampleFormControlInput1">Whatsapp Sipariş için Telefon Numarası</label>
            <input
              type="text"
              className="form-control"
              value={wpNumber}
              onChange={(e) => {
                setWpNumber(e.target.value);
              }}
            />
          </div>
          <div className="form-group w-50 mx-auto">
            <label for="exampleFormControlInput1">Paket Siparişte İndirim Oranı (%)</label>
            <input
              type="text"
              className="form-control"
              value={discountRate}
              onChange={(e) => {
                setDiscountRate(e.target.value);
              }}
            />
          </div>

          <div className="row">
            <div
              className="btn btn-primary w-25 mx-auto mt-5"
              onClick={() => {
                create();
              }}
            >
              Kaydet
            </div>
          </div>
        </form>
      </div>
      <div className="col-3"></div>
    </div>
  );
};

export default RestaurantSettings;
