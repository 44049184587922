import { Link } from "react-router-dom";
import "../css/menu.css";
import ReactDOM from "react-dom";
import { useParams, useHistory } from "react-router-dom";
import React, { createContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import firebase, { auth, firestore } from "../utils/firebase";
import Footer from "../components/footer";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../css/meal.css";
import { Spinner } from "react-bootstrap";
import instaBadge from "../img/insta-badge.png";
import defaultPhoto from "../img/default.png";
import { getPaketPrice } from "../utils/items";

const Menu = () => {
  const [meals, setMeals] = useState();
  const [category, setCategory] = useState();
  const [menu, setMenu] = useState(null);
  const { cafe, table } = useParams();
  const [loading, done] = useState(false);
  const [info, setInfo] = useState();

  const [filter, setFilter] = useState("");
  const lang = localStorage.getItem("alka-menu-lang") || "tr";

  useEffect(() => {
    firestore
      .collection(`Restaurants/${cafe}/info`)
      .doc("info")
      .get()
      .then((doc) => {
        setInfo(doc.data());
        let info = doc.data();
        if (info?.menuType == 2) {
          history.push(`/menu1/${cafe}/${table}`);
        }
      });
  }, []);
  useEffect(() => {
    if (info?.logo && menu) done(true);
  }, [menu]);
  const history = useHistory();

  useEffect(() => {
    firestore
      .collection("Restaurants")
      .doc(cafe.toString())
      .get()
      .then((doc) => {
        if (doc.exists) {
          let menu = doc.data().Menu;
          menu.sort((a, b) => {
            if (parseFloat(a.sortOrder) < parseFloat(b.sortOrder)) return -1;
            else return 1;
          });
          setMenu(menu.filter((m) => m.active == 1));
          let items = menu[0].items;
          items.sort((a, b) => {
            if (parseFloat(a.sortOrder) < parseFloat(b.sortOrder)) return -1;
            else return 1;
          });
          setMeals(items.filter((i) => i.active == 1));
          setCategory(
            lang == "tr"
              ? menu[0].category
              : lang == "en"
              ? menu[0].categoryEn
              : menu[0].categoryAr
          );
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      });
  }, []);

  useEffect(() => {
    if (menu == null) {
      return;
    }
    var list = menu.map((m) => m.items).flat();
    setMeals(
      list.filter((x) => x.name.toLowerCase().includes(filter.toLowerCase()))
    );
  }, [filter]);

  const [autoplay, setAutoPlay] = useState(true);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    rows: 2,
  };
  const goToMeal = (meal) => {
    history.push({
      pathname: "/meal",
      state: { meal, info, cafe, paket: false },
    });
  };
  const goBack = () => {
    history.push(`/menu/${cafe}/${table}`);
  };
  const MainContext = createContext();
  return loading ? (
    <div
      style={{
        overflowX: "hidden",
        backgroundColor: info?.backgroundColor,
        color: info?.textColor,
        minHeight: "100vh",
      }}
      className="mate"
    >
      <div
        className="back-icon mt-3"
        onClick={goBack}
        style={{
          position: "absolute",
          left: 20,
          fontSize: 30,
          padding: 2,
          borderRadius: "20%",
          boxShadow: "rgb(0 0 0 / 15%) 2px 2px 10px",
        }}
      >
        <i className="bi bi-chevron-left"></i>
      </div>

      <div className="logo">
        <img src={info?.logo} alt="logo" />
      </div>
      <p
        className="text-center fs-2 fw-bold animate__animated animate__fadeInDown"
        style={{ color: "rgb(230,3,75)" }}
      >
        {info?.name}
      </p>

      <div
        style={{
          height: "auto",
          width: "90vw",
          margin: "0 auto",
        }}
        className="slider animate__animated animate__slideInRight"
      >
        <Slider {...settings}>
          {menu?.map(function (slide) {
            return (
              <div
                onClick={() => {
                  setCategory(
                    lang == "tr"
                      ? slide.category
                      : lang == "en"
                      ? slide.categoryEn
                      : slide.categoryAr
                  );
                  setMeals(slide.items.filter((i) => i.active == 1));
                }}
                onMouseDown={() => {
                  setAutoPlay(false);
                }}
                className="category"
              >
                <img
                  src={slide.photo}
                  style={{ width: "95%", height: 130, borderRadius: 10 }}
                />
                <h3
                  style={{
                    color: slide.category == category ? "rgb(230,3,75)" : "",
                  }}
                >
                  {lang == "tr"
                    ? slide.category
                    : lang == "en"
                    ? slide.categoryEn
                    : slide.categoryAr}
                </h3>
              </div>
            );
          })}
        </Slider>
      </div>

      <div class="search__container text-center mt-3">
        <input
          class="search__input"
          type="text"
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
          placeholder="Ürün Ara"
        />
      </div>
      {info?.instagram != null && (
        <div className="primary-announcement-container mt-5 mb-4">
          <a className="row" href={info?.instagram} target="_blank">
            <img src={instaBadge} style={{ width: 150 }} />
            <div
              style={{ width: 90, marginRight: 20, textAlign: "center" }}
              className="btn-action"
            >
              TAKİP ET
            </div>
          </a>
        </div>
      )}
      <div className="text-center" style={{ marginTop: 35 }}>
        <p style={{ fontSize: 20, fontWeight: "bold" }}>{category}</p>
      </div>

      <div style={{ width: "95vw" }} className="mx-auto">
        {meals &&
          meals.map((m) => (
            <div
              className="row meal animate__animated animate__slideInUp"
              onClick={() => goToMeal(m)}
              style={{
                backgroundColor: info?.backgroundColor,
                boxShadow: info?.boxShadow,
              }}
            >
              <div style={{ width: "50%", marginLeft: 0, padding: 0 }}>
                <div className="image ">
                  <img
                    alt="Yemek"
                    src={m.photo.trim() == "" ? defaultPhoto : m.photo}
                  />
                </div>
              </div>
              <div style={{ width: "50%" }}>
                <div className="header">
                  <p>
                    {lang == "en" ? m.nameEn : lang == "ar" ? m.nameAr : m.name}
                  </p>
                </div>
                {/* <div className="content">
                  {lang == "tr"
                    ? m.description
                    : lang == "en"
                    ? m.descriptionEn
                    : m.descriptionAr}
                </div> */}

                <div className="price" style={{ color: info?.priceColor }}>
                  <span>{m.price}</span>
                  <span>₺</span>
                </div>
                <div className="plus">
                  <i className="bi bi-plus-square-fill"></i>
                </div>
              </div>
            </div>
          ))}
      </div>
      <div style={{ height: "10vh" }}></div>
      <Footer cafe={cafe} table={table} active={1} menu={menu} info={info} />
    </div>
  ) : (
    <div className="spinner-container">
      <Spinner animation="border" role="status" className="spinner">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  );
};

export default Menu;

/*
<div className="main">
      <Link
        to={{
          pathname: "/cart",
          state: { cafe, table },
        }}
      >
        <ImCart
          size={45}
          color={"#d43031"}
          style={{ float: "right", margin: "20 20 0 0" }}
        />
      </Link>
      <div id="restaurant-menu">
        <Container>
          <div className="section-title text-center">
            <h2 className="section-title">menu</h2>
          </div>
          {menu &&
            menu?.map((cat) => {
              return (
                <Row>
                  <div className="col-xs-12 col-sm-12">
                    <div className="menu-section">
                      <h2 className="menu-section-title text-center">
                        {cat.category}
                      </h2>
                      {cat.items?.map((meal) => {
                        return (
                          <div className="menu-item">
                            <div className="menu-item-name">{meal.name}</div>
                            <div className="menu-item-price">
                              <span>&#8378;</span>
                              {meal.price}
                              <span
                                style={{
                                  borderRadius: 80,
                                  border: "1px solid red",
                                  marginLeft: 15,
                                  fontSize: 30,
                                }}
                                onClick={() => {
                                  setCart([...cart, meal]);
                                }}
                              >
                                +
                              </span>
                            </div>
                            <div className="menu-item-description">
                              {meal.description}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </Row>
              );
            })}
        </Container>
      </div>
      <Footer cafe={cafe} table={table} active={1} />
    </div>
 

*/
