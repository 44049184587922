import React, { useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { firestore } from "../../utils/firebase";

const Campaign = () => {
  const cafe = localStorage.getItem("name");
  const info = JSON.parse(localStorage.getItem("info"));
  const [discount, setDiscount] = useState();
  const history = useHistory();
  const ref = useRef();
  const checkUser = () => {
    if (!ref.current.value) return;
    firestore
      .collection(`Restaurants/${cafe}/users`)
      .doc(ref.current.value)
      .get()
      .then((doc) => {
        if (doc.exists) {
          setDiscount(doc.data().discount);
        }

        else
          setDiscount(0);
      });
  };
  const goBack = () => {
    history.push("/admin");
  }
  return (
    <>
      <div>

        <div className="btn btn-danger mt-3" onClick={goBack}>
          <i
            className="fa fa-arrow-left"
            aria-hidden="true"
            style={{ width: "2vw" }}
          ></i>

        </div>
      </div>
      <div style={{ padding: 50 }}>
        <div className="form-group">
          <label for="exampleFormControlInput1" >
            <p>Telefon Numarası</p>
          </label>
          <input
            type="text"
            className="form-control w-25"
            ref={ref}
          />
        </div>
        <div className="row">
          <div
            className="btn btn-secondary w-25 mt-5 mb-5"
            onClick={() => {
              checkUser();
            }}
          >
            Sorgula
          </div>
        </div>

        <div>
          {info?.campaignOrderCount}: {parseFloat(discount).toFixed(2)}
        </div>
      </div>
    </>
  )
}

export default Campaign;