import { useEffect, useRef, useState } from "react";
import "./order.css";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useReactToPrint } from "react-to-print";
import AutoPrint from "../../pages/AutoPrint";
import { firestore } from "../../utils/firebase";
import ReactDOMServer from "react-dom/server";
const qz = require("qz-tray");

const Order = ({ order, approveOrder, cancelOrder, deliverOrder, menuObj }) => {
  const [menu, setMenu] = useState(menuObj);

  useEffect(() => {
    if (menu == null) {
      firestore
        .collection(`Restaurants`)
        .doc(order.cafe)
        .onSnapshot((doc) => {
          let getMenu = doc.data().Menu;
          getMenu.sort((a, b) => {
            if (a.sortOrder < b.sortOrder) return -1;
            else return 1;
          });
          getMenu = getMenu.filter((m) => m.active == 1);
          setMenu(Object.assign({}, ...(getMenu.map(m => m.items.map(item => ({ [item.id]: item }))).flat())));
        });
    }
  }, [menu]);

  const info = JSON.parse(localStorage.getItem("info"));

  const printRef = useRef();
  const [orderToPrint, setOrderToPrint] = useState(null);
  const printData = useReactToPrint({
    content: () => printRef.current,
    onAfterPrint: () => setOrderToPrint(null),
  });

  useEffect(() => {
    if (orderToPrint && orderToPrint.adisyonPrint && info?.adisyon != null) {
      qz.websocket
        .connect({ host: info.host })
        .then(() => {
          var data = ReactDOMServer.renderToStaticMarkup(
            <AutoPrint
              ref={printRef}
              order={orderToPrint}
              menuObj={menu}
            />
          );
          let config = qz.configs.create(info?.adisyon);
          return qz.print(config, [
            {
              type: "pixel",
              format: "html",
              flavor: "plain",
              data,
            },
          ]);
        })
        .then(() => {
          return qz.websocket.disconnect();
        })
        .catch((err) => {
          console.error(err);
          return qz.websocket.disconnect();
        });
    }
    else if (orderToPrint && info?.autoPrint) printData();
  }, [orderToPrint]);

  const calculateTotal = () => {
    let total = 0.0;
    order?.cart.map((c) => {
      total += parseFloat(c.total);
    });
    return total.toFixed(2);
  };
  return menu != null ? (<div className="col-sm-6 col-lg-3" >

    <div style={{ display: "none" }}>
      <AutoPrint
        ref={printRef}
        order={orderToPrint}
        menuObj={menu}
      />
    </div>
    <div
      className="card text-center"
      style={{ minHeight: 300, color: "black" }}
    >
      {order?.arama ? "Telefon Siparişi" : "Paket Sipariş"}
      <div
        className="card-body"
        style={{ background: order?.status == "1" ? "#eeeeee" : "", textAlign: 'center' }}
      >
        <div>
          {order?.cart?.map((o) => {
            return (
              <>
                <h5 className="card-title" >
                  {o.quantity} x {menu[o.meal].name}
                  <span style={{ fontWeight: 'bold' }}> {o.quantity * (parseFloat(menu[o.meal].price) + parseFloat(o.optPrice || 0))} TL</span>
                </h5>
                {o.checkedOptions != null && o.checkedOptions.length > 0 && (
                  <p>Ekstralar: {o.checkedOptions?.map((opt) => opt.name)}</p>
                )}
                {o.removedOptions && o.removedOptions.length > 0 && (
                  <p>
                    Çıkarılacaklar: {o.removedOptions?.map((opt) => opt.name)}
                  </p>
                )}

                {o.mealNote && (
                  <h5 className="card-title"> {o.mealNote} </h5>
                )}
              </>
            );
          })}
          <p className="card-text">Sipariş Tarihi:
            <span style={{ fontWeight: "bold" }}>
              {" " + order.date.toDate().toLocaleTimeString()}
              <br />{order.date.toDate().toLocaleDateString()}
            </span>
          </p>
          {(order.totalWithoutDiscount || order.total) && (
            <>Toplam Tutar:
              <p className="card-text ">
                <span style={{ fontWeight: "bold" }}>
                  {order.totalWithoutDiscount || order.total} TL</span> </p>
            </>
          )}
          {order.totalWithoutDiscount != null && order.total != order.totalWithoutDiscount && "İndirimli Toplam Tutar: "}
          {order.totalWithoutDiscount != null && order.total != order.totalWithoutDiscount && (<p className="card-text ">
            <span style={{ fontWeight: "bold" }}>
              {order.total} TL</span> </p>)}

        </div>
        <div style={{ fontWeight: 600 }}>
          <p className="card-text">
            {order?.name}
          </p>
          <p className="card-text"><span style={{ fontWeight: 'bold', color: 'red' }}>Telefon: </span>{order?.phone}</p>
          <p className="card-text"><span style={{ fontWeight: 'bold', color: 'red' }}>Adres: </span>{order?.address}</p>
          {order?.email && (<p className="card-text"><span style={{ fontWeight: 'bold', color: 'red' }}>Email: </span>{order.email}</p>)}
          {order?.note && (<p className="card-text"><span style={{ fontWeight: 'bold', color: 'red' }}>Not: </span>{order?.note}</p>)}
          <p className="card-text"><span style={{ fontWeight: 'bold', color: 'red' }}>Ödeme Yöntemi: </span>{order?.paymentMethod}</p>
        </div>
        <div className="row mt-3">
          <div className="col">
            <a
              href="#"
              className="btn btn-dark"
              onClick={() => {
                setOrderToPrint(order);
              }}
            >
              Yazdır
            </a>
          </div>
          <div className="col">
            <a
              href="#"
              className="btn btn-danger"
              onClick={() => {
                order.adisyonPrint = true;
                setOrderToPrint(order);
              }}
            >
              Adisyon
            </a>
          </div>
          {order?.status == 0 && (
            <>
              <div className="col">
                <a
                  href="#"
                  className="btn btn-dark"
                  onClick={() => {
                    confirmAlert({
                      message: "Onaylıyor musunuz?",
                      buttons: [
                        {
                          label: "Evet",
                          onClick: () => {
                            approveOrder(order.id);
                          },
                        },
                        {
                          label: "Hayır",
                        },
                      ],
                    });
                  }}
                >
                  Onayla
                </a>
              </div>
            </>
          )}
        </div>
        {order.status == 0 ? (
          <div className="row w-50 mx-auto mt-2">
            <div
              className="btn btn-danger"
              onClick={() => {
                confirmAlert({
                  message: "Onaylıyor musunuz?",
                  buttons: [
                    {
                      label: "Evet",
                      onClick: () => {
                        cancelOrder(order.id);
                      },
                    },
                    {
                      label: "Hayır",
                    },
                  ],
                });
              }}
            >
              İptal Et
            </div>
          </div>
        ) : order.deliveryType == "delivery" ? (
          <div className="row w-50 mx-auto mt-2">
            <div
              className="btn btn-secondary"
              onClick={() => {
                confirmAlert({
                  message: "Onaylıyor musunuz?",
                  buttons: [
                    {
                      label: "Evet",
                      onClick: () => {
                        deliverOrder(order.id);
                      },
                    },
                    {
                      label: "Hayır",
                    },
                  ],
                });
              }}
            >
              Yola Çıkar
            </div>
          </div>
        ) : (<div className="row w-50 mx-auto mt-2">
          <div
            className="btn btn-secondary"
            onClick={() => {
              confirmAlert({
                message: "Onaylıyor musunuz?",
                buttons: [
                  {
                    label: "Evet",
                    onClick: () => {
                      deliverOrder(order.id, order.deliveryType);
                    },
                  },
                  {
                    label: "Hayır",
                  },
                ],
              });
            }}
          >
            Teslim Edildi
          </div>
        </div>)}
      </div>
    </div>
  </div>) : (<div></div>);
};

export default Order;
