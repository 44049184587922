import React, { useContext, useEffect, useState } from "react";
import { Table, Pagination, Form, Row, Col, Button } from "react-bootstrap";
import { firestore } from "../../utils/firebase";
import { Link, useHistory } from "react-router-dom";

const SonAramalar = () => {
    const [page, setPage] = useState(1);
    const [startDate, setStartDate] = useState(
        new Date().toISOString().slice(0, 10)
    );
    const history = useHistory();
    const [endDate, setEndDate] = useState();
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [orderType, setOrderType] = useState("orders");
    const cafe = localStorage.getItem("name");

    const goBack = () => {
        history.push("/admin");
    };
    useEffect(() => {
        setEndDate(new Date().toISOString().slice(0, 10));
    }, [])

    const calculateToplam = (item) => {
        let total = 0.0;
        item.cart?.map((c) => {
            total += parseFloat(c.total);
        });

        return total.toFixed(2) + " TL";
    };
    const calculateTableTotal = () => {
        let total = 0.0;
        items.map((order) => {
            order.cart?.map((c) => {
                total += parseFloat(c.total);
            });
        });
        return total.toFixed(2) + " TL";
    };

    useEffect(() => {
        try {
            firestore
                .collection(`Restaurants/${cafe}/aramalar`)
                .where("tarih", ">=", new Date(new Date(startDate).setHours(0, 0, 0, 0)))
                .where(
                    "tarih",
                    "<=",
                    new Date(new Date(endDate).setHours(23, 59, 59, 999))
                )
                .orderBy("tarih")
                .startAt((page - 1) * 20)
                .limit(20)
                .get().then(
                    (snapshot) => {
                        const items = snapshot.docs.map((doc) => doc.data());
                        setItems(items);
                        setLoading(false);
                    },
                    (error) => {
                        console.log(error);
                        setLoading(false);
                    }
                );
        } catch (err) {
            console.log(err);
            return;
        }
    }, [page, startDate, endDate, orderType]);
    const itemCount = items.length;
    const pageCount = itemCount > 0 ? Math.ceil(itemCount / 20) : 1;

    if (loading) {
        return (
            <p
                style={{
                    textAlign: "center",
                    position: "absolute",
                    top: "50%",
                    marginLeft: "50%",
                }}
            >
                Loading...
            </p>
        );
    }

    if (error) {
        return <p>Error: {error.message}</p>;
    }
    const endDateOnChange = (value) => {
        try {
            var date = new Date(value);
            setEndDate(date.toISOString().slice(0, 10));
        } catch (error) {
            setEndDate(value);
        }
    };
    const startDateOnChange = (value) => {
        try {
            var date = new Date(value);
            setStartDate(date.toISOString().slice(0, 10));
        } catch (error) {
            setStartDate(value);
        }
    };
    return (
        <div style={{ width: "80%", marginTop: 40 }} className="mx-auto">
            <div>
                <div className="btn btn-danger mt-3 mb-3" onClick={goBack}>
                    <i
                        className="fa fa-arrow-left"
                        aria-hidden="true"
                        style={{ width: "2vw" }}
                    ></i>
                </div>
            </div>
            <Form>
                <Form.Group as={Row} controlId="dateRange">
                    <Form.Label column sm={2}>
                        Tarih Aralığı
                    </Form.Label>
                    <Col sm={4}>
                        <Form.Control
                            type="date"
                            value={startDate}
                            onChange={(e) => startDateOnChange(e.target.value)}
                        />
                    </Col>
                    <Col sm={1} className="text-center" style={{ lineHeight: "2.5" }}>
                        -
                    </Col>
                    <Col sm={4}>
                        <Form.Control
                            type="date"
                            value={endDate}
                            onChange={(e) => endDateOnChange(e.target.value)}
                        />
                    </Col>
                </Form.Group>
            </Form>
            <Table striped bordered hover style={{ marginTop: 20 }}>
                <thead>
                    <tr>
                        <th>Zaman</th>
                        <th>Numara</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {items?.map((item) => (
                        <tr key={item.id}>
                            <td>{item.tarih.toDate().toLocaleString()}</td>
                            <td>{item.numara}</td>
                            <td>
                                <div className="text-center">
                                    <Link as={Button} to={`/admin/arama-tekrar/${item.numara}`}>Sipariş Al</Link>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <Pagination>
                <Pagination.First onClick={() => setPage(1)} disabled={page === 1} />
                <Pagination.Prev
                    onClick={() => setPage(page - 1)}
                    disabled={page === 1}
                />
                {[...Array(pageCount)].map((_, i) => (
                    <Pagination.Item
                        key={i + 1}
                        active={i + 1 === page}
                        onClick={() => setPage(i + 1)}
                    >
                        {i + 1}
                    </Pagination.Item>
                ))}
                <Pagination.Next
                    onClick={() => setPage(page + 1)}
                    disabled={page === pageCount}
                />
                <Pagination.Last
                    onClick={() => setPage(pageCount)}
                    disabled={page === pageCount}
                />
            </Pagination>
        </div>
    );
}

export default SonAramalar;