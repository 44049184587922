import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import MainFooter from "./MainFooter";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const Kralkumru = ({ info }) => {
  const [lang, setLanguage] = useState(
    localStorage.getItem("alka-menu-lang") || "tr"
  );

  useEffect(() => {
    localStorage.setItem("alka-menu-lang", lang);
  }, [lang]);
  const history = useHistory();

  const routeChange = (cafe) => {
    history.push({ pathname: `/paketmenu/${cafe}` });
  };
  return (
    <>
      <div
        className="button dropdown"
        style={{ boxShadow: "rgb(0 0 0 / 25%) 5px 5px 25px", marginTop: "20%" }}
      >
        <select
          className="form__select"
          style={{ color: info?.textColor }}
          value={lang}
          onChange={(e) => {
            setLanguage(e.target.value);
          }}
        >
          <option value="tr" data-toggle="tab">
            Türkçe
          </option>
          <option value="en" data-toggle="tab">
            English
          </option>
          <option value="ar" data-toggle="tab">
            عربي
          </option>
        </select>
      </div>

      <div
        className="multipleRestaurants mx-auto"
        style={{
          width: "90vw",
          marginTop: "20%",
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          gap: 20,
          justifyContent: "start",
        }}
      >
        <div className="row meal animate__animated animate__slideInUp">
          <div style={{ width: "50%", marginLeft: 0, padding: 0 }}>
            <div className="image">
              <img
                alt="Meal"
                src="https://images.deliveryhero.io/image/fd-tr/LH/ftcc-hero.jpg?width=560&height=240&quality=45"
              />
            </div>
          </div>
          <div
            style={{ width: "50%" }}
            onClick={() => routeChange("kralkumru")}
          >
            <div className="header">
              <p>Kral Kumru</p>
            </div>
            <div className="price" style={{ color: info?.priceColor }}></div>
            <div className="plus">
              <i className="bi bi-bag-fill">
                {" "}
                {lang == "tr"
                  ? "Sipariş Ver"
                  : lang == "en"
                  ? "Give Order"
                  : "قائمة الطعام"}
              </i>
            </div>
          </div>
        </div>

        <div
          className="row meal animate__animated animate__slideInUp"
          style={{
            backgroundColor: info?.backgroundColor,
            boxShadow: info?.boxShadow,
          }}
        >
          <div style={{ width: "50%", marginLeft: 0, padding: 0 }}>
            <div className="image ">
              <img
                alt="Meal"
                src={
                  "https://images.deliveryhero.io/image/fd-tr/LH/pjfm-hero.jpg?width=560&height=240&quality=45"
                }
              />
            </div>
          </div>
          <div
            style={{ width: "50%" }}
            onClick={() => routeChange("hamburgerman")}
          >
            <div className="header">
              <p>Hamburgerman</p>
            </div>
            {/* <div className="content">
                  {lang == "tr"
                    ? m.description
                    : lang == "en"
                    ? m.descriptionEn
                    : m.descriptionAr}
                </div> */}

            <div className="price" style={{ color: info?.priceColor }}></div>
            <div className="plus">
              <i className="bi bi-bag-fill">
                {" "}
                {lang == "tr"
                  ? "Sipariş Ver"
                  : lang == "en"
                  ? "Give Order"
                  : "قائمة الطعام"}
              </i>
            </div>
          </div>
        </div>

        <div
          className="row meal animate__animated animate__slideInUp"
          style={{
            backgroundColor: info?.backgroundColor,
            boxShadow: info?.boxShadow,
          }}
        >
          <div style={{ width: "50%", marginLeft: 0, padding: 0 }}>
            <div className="image">
              <img
                alt="Meal"
                src={
                  "https://images.deliveryhero.io/image/fd-tr/LH/cxm5-hero.jpg?width=560&height=240&quality=45"
                }
              />
            </div>
          </div>
          <div
            style={{ width: "50%" }}
            onClick={() => routeChange("cihangirpilavcisi")}
          >
            <div className="header">
              <p>Cihangir Pilavcısı</p>
            </div>
            {/* <div className="content">
                  {lang == "tr"
                    ? m.description
                    : lang == "en"
                    ? m.descriptionEn
                    : m.descriptionAr}
                </div> */}

            <div className="price" style={{ color: info?.priceColor }}></div>
            <div className="plus">
              <i className="bi bi-bag-fill">
                {" "}
                {lang == "tr"
                  ? "Sipariş Ver"
                  : lang == "en"
                  ? "Give Order"
                  : "قائمة الطعام"}
              </i>
            </div>
          </div>
        </div>
      </div>
      <MainFooter info={info} />
    </>
  );
};

export default Kralkumru;
