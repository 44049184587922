import React, { useRef } from "react";
import { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import logo from "../img/logo.png";

const Category = () => {
  const { category, info, cafe, table } = useLocation().state;
  const history = useHistory();
  const [image, setImage] = useState(null);
  const lang = localStorage.getItem("alka-menu-lang") || "tr";
  const modalRef = useRef();
  const goBack = () => {
    history.push(`/m/${cafe}/${table}`);
  };
  window.addEventListener("mousedown", (e) => {
    if (image != null && modalRef.current.contains(e.target)) {
      setImage(null);
    }
  });
  return (
    <>
      <div id="sticky-header" className="sticky-header not-showing">
        <div className="sticky-header__content">
          <div className="sticky-header__content__side">
            <div className="sticky-header__content__nav-btn">Menu</div>
          </div>
        </div>
      </div>
      {image && (
        <div
          id="myModal"
          ref={modalRef}
          className="modal"
          style={{ display: image != null ? "block" : "none" }}
        >
          {/* <span
            className="close"
            onClick={() => {
              setImage(null);
            }}
          >
            &times;
          </span> */}
          <img className="modal-content" id="img01" src={image} />
          {/* <div id="caption"></div> */}
        </div>
      )}
      <div
        className="sidepage-hero"
        style={{
          backgroundImage: `url(${category?.photo})`,
        }}
      >
        <div className="dmu_dark-overlay"></div>
        <h1 className="sidepage-hero__title">
          {lang == "tr"
            ? category.category
            : lang == "en"
            ? category.categoryEn
            : category.categoryAr}
        </h1>
        <a className="sidepage-hero__btn" onClick={goBack}>
          Menu
        </a>
      </div>
      {category?.items.map((item) => (
        <div
          className="items-container"
          onClick={() => {
            setImage(item.photo);
          }}
        >
          <div className="item-box  tem-box-image">
            <img
              className="item-image swiper-thumb"
              src={item.photo}
              data-title="Breakfast Tray"
              data-desc={
                lang == "tr"
                  ? item.description
                  : lang == "en"
                  ? item.descriptionEn
                  : item.descriptionAr
              }
              data-image={item.photo}
            />
            <div className="item-content">
              <div className="primary-content">
                <div className="primary-head">
                  <div className="primary-headtext">
                    <h4 className="primary-title w-image">
                      {lang == "tr"
                        ? item.name
                        : lang == "en"
                        ? item.nameEn
                        : item.nameAr}
                    </h4>
                  </div>
                  {item.price != null && item.price != "" && (
                    <div className="price-box">
                      <div className="price-content --item">
                        <div className="price">{item.price}</div>
                        <div className="currency">₺</div>
                      </div>
                    </div>
                  )}
                </div>
                {item.description != null && item.description.trim() != "" && (
                  <p style={{ width: "90%" }}>
                    ({" "}
                    {lang == "tr"
                      ? item.description
                      : lang == "en"
                      ? item.descriptionEn
                      : item.descriptionAr}{" "}
                    )
                  </p>
                )}
              </div>
              <div className="secondary-content">
                {item.addOptions
                  ?.sort((a, b) => {
                    if (parseFloat(a.sortOrder) < parseFloat(b.sortOrder))
                      return -1;
                    else return 1;
                  })
                  .map((opt, i) => (
                    <div className="variation-item">
                      <div className="variation-name">
                        {lang == "tr"
                          ? opt.name
                          : lang == "en"
                          ? opt.nameEn
                          : opt.nameAr}
                      </div>
                      {opt.price != null && opt.price != "" && (
                        <div className="price-box">
                          <div className="price-content --variation">
                            <div className="price">{opt.price}</div>
                            <div className="currency">₺</div>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      ))}
      <div className="footer-dmenu">
        <a href="https://dbateknoloji.com" target="_blank">
          <img src={logo} style={{ width: 60 }} />
        </a>
        <br />
        <small>DBA Teknoloji Yazılım &copy; {new Date().getFullYear()}</small>
      </div>
    </>
  );
};

export default Category;
