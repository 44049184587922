import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle";
import 'react-day-picker/dist/style.css';

import { ToastContainer } from "react-toastify";

ReactDOM.render(
  <React.StrictMode>
    <ToastContainer
      toastStyle={{
        backgroundColor: "#121212",
        width: "95vw",
        color: "white",
      }}
      toastClassName="mx-auto mb-5"
      position="bottom-center"
      autoClose={2000}
      hideProgressBar
      newestOnTop={true}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />

    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
