import Table from "../../components/components/Table/Table";
import React, { useEffect, useState } from "react";
import { auth, firestore } from "../../utils/firebase.js";
import "../../css/dashboard.css";
import { useHistory } from "react-router";
import { BsBarChartLine, BsPower } from "react-icons/bs";
import { BiBarChartAlt2 } from "react-icons/bi";
import { FiSettings } from "react-icons/fi";
import { BiFoodMenu, BiMoney } from "react-icons/bi";
import { RiStickyNoteLine, RiEBike2Line } from "react-icons/ri";
import { AiFillPhone } from "react-icons/ai";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
export default function Dashboard() {
  const history = useHistory();
  const info = JSON.parse(localStorage.getItem("info"));

  const cafe = localStorage.getItem("name");
  const [active, setActive] = useState(info?.active || false);

  const setActiveStatus = () => {
    info.active = !(info.active || false);
    localStorage.setItem("info", [JSON.stringify(info)]);
    firestore.collection(`Restaurants/${cafe}/info`)
      .doc("info")
      .set(info)
      .then(() => {
        toast.success((info.active ? "Aktif" : "Pasif") + " Duruma Getirildi.", {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setActive(!active);
      })
  }

  const logout = () => {
    confirmAlert({
      title: "Çıkış Yap",
      message: "Çıkış Yapılacak Emin Misiniz?",
      buttons: [
        {
          label: "Evet",
          onClick: () => {
            auth.signOut();
          },
        },
        {
          label: "Hayır",
        },
      ],
    });
  };

  return (
    <div className="wrapper">
      <div className="wrap">
        <div
          className="btn-big purple"
          onClick={() => history.push("/admin/masalar")}
        >
          <i className="fa fa-table"></i>{" "}
          <span className="label bottom">Masalar</span>{" "}
        </div>
        <div
          className="btn-big purple"
          onClick={() => history.push("/admin/siparisler")}
        >
          <RiEBike2Line color={"white"} size={50} />
          <span className="label bottom">Paket Sipariş</span>{" "}
        </div>
        <div
          className="btn-big blue"
          onClick={() => history.push("/admin/rapor")}
        >
          <i>
            <BiBarChartAlt2 color={"white"} />
          </i>{" "}
          <span className="label bottom">Raporlar</span>{" "}
        </div>
        <div
          className="btn-big red-light"
          onClick={() => history.push("/admin/stok")}
        >
          <i>
            <RiStickyNoteLine color={"white"} />
          </i>{" "}
          <span className="label bottom">Stok</span>{" "}
        </div>
        <div
          className="btn-big"
          style={{ backgroundColor: '#028090' }}
          onClick={() => history.push("/admin/muhasebe")}
        >
          <i>
            <RiStickyNoteLine color={"white"} />
          </i>{" "}
          <span className="label bottom">Gelir/Gider</span>{" "}
        </div>
        <div
          className="btn-small green-bright"
          onClick={() => history.push("/admin/menu")}
        >
          <i>
            <BiFoodMenu color={"white"} />
          </i>{" "}
          <span className="label bottom">Menü</span>{" "}
        </div>
        <div
          className="btn-small red-light"
          onClick={() => history.push("/admin/ayarlar/masa")}
        >
          <i>
            <FiSettings color={"white"} />
          </i>{" "}
          <span className="label bottom">Masa Ayarları</span>{" "}
        </div>

        {info?.hasUserSystem && (<div

          className="btn-small"
          style={{ backgroundColor: '#12a12e' }}
          onClick={() => history.push("/admin/ayarlar/kampanya")}
        >
          <i>

            <BiMoney color={"white"} />

          </i>{" "}
          <span className="label bottom">Kampanya Sorgula</span>{" "}
        </div>)}
        <div
          className="btn-big"
          style={{ backgroundColor: '#4D3834' }}
          onClick={() => history.push("/admin/ayarlar")}
        >
          <i>
            <FiSettings color={"white"} />
          </i>{" "}
          <span className="label bottom">Genel Ayarlar</span>{" "}
        </div>
        <div
          className="btn-small"
          style={{ backgroundColor: '#4da5ff8e' }}
          onClick={() => history.push("/admin/siparis-listesi")}
        >
          <i>
            <BiBarChartAlt2 color={"white"} />
          </i>{" "}
          <span className="label bottom">Geçmiş Siparişler</span>{" "}
        </div>
        <div
          className="btn-small"
          style={{ backgroundColor: 'black' }}
          onClick={() => history.push("/admin/son-aramalar")}
        >
          <i>
            <AiFillPhone color={"white"} />
          </i>{" "}
          <span className="label bottom">Son Aramalar</span>{" "}
        </div>
        <div
          className={`btn-small ${info.active ? "green-bright" : "redish"}`}
          onClick={() => setActiveStatus()}
        >
          <b style={{ color: 'white', fontSize: 20 }}>{active ? "Aktif" : "Kapalı"}</b>
        </div>
        {/*<div className="btn-big blue">
          {" "}
          <i className="fa fa-calendar "></i>{" "}
          <span className="label bottom">Calendar</span>{" "}
        </div>
        <div className="btn-small orange">
          {" "}
          <i className="fa fa-windows "></i>{" "}
          <span className="label bottom">Store</span>{" "}
        </div>
      
        <div className="btn-small green last">
          {" "}
          <i className="fa fa-umbrella "></i>{" "}
          <span className="label bottom">Application</span>{" "}
        </div>
        <div className="btn-small red-light">
          {" "}
          <i className="fa fa-wrench "></i>{" "}
          <span className="label bottom">Ayarlar</span>{" "}
        </div>

        <div className="btn-big purple">
          {" "}
          <i className="fa fa-video-camera "></i>{" "}
          <span className="label bottom">Videos</span>{" "}
        </div>
        <div className="btn-big gray">
          {" "}
          <i className="fa fa-music "></i>{" "}
          <span className="label bottom">Musics</span>{" "}
        </div>
        <div className="btn-small green-bright last">
          {" "}
          <i className="fa fa-gamepad "></i>{" "}
          <span className="label bottom">Games</span>{" "}
        </div>
        <div className="btn-small blue">
          {" "}
          <i className="fa fa-twitter "></i>{" "}
          <span className="label bottom">twitter</span>{" "}
        </div>
        <div className="btn-small red-light">
          {" "}
          <i className="fa fa-google-plus "></i>{" "}
          <span className="label bottom">Google+</span>{" "}
        </div>
        <div className="btn-small blue-nav">
          {" "}
          <i className="fa fa-facebook "></i>{" "}
          <span className="label bottom">Facebook</span>{" "}
        </div>
  */}
        <div className="btn-big redish" onClick={logout}>
          <i>
            <BsPower color={"white"} />
          </i>
        </div>
      </div>
      <i className="fa fa-sign-out-alt" style={{ position: "absolute" }}></i>
    </div>
  );
}
